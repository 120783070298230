import React from 'react';
import { extractRepeaterField } from '../../util/blocks';

import { ClientLinks } from '../../components/enfusion/ClientLinks';

function ACFClientLinks({ meta, data = [], className, anchor, pageProps }) {
    const { themeAssets } = pageProps;
    let lists = extractRepeaterField(data, 'card');

    lists = lists.map((repeaterItem) => ({
        ...repeaterItem,
        icon: themeAssets[repeaterItem.icon] || {},
        cta_links: extractRepeaterField(repeaterItem, 'cta_links').map(
            (item) => ({
                ...item,
                icon: themeAssets[item.icon] || {}
            })
        )
    }));

    return <ClientLinks className={className} anchor={anchor} lists={lists} />;
}

export default ACFClientLinks;
