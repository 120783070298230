import React from 'react';
import { extractRepeaterField } from '../../util/blocks';

import { ButtonPopup } from '../../components/enfusion/ButtonPopup';

function ACFButtonPopup({ meta, data = [], className, anchor, pageProps }) {
    const { button_style, large_popup, link, simple_link } = data;

    return (
        <ButtonPopup
            className={className}
            anchor={anchor}
            button_style={button_style}
            link={link}
            isFullScreen={large_popup}
            simple_link={simple_link}
        />
    );
}

export default ACFButtonPopup;
