import React from 'react';

import { TwoColCTA } from '../../components/enfusion/TwoColCTA';

function ACFTwoColCTA({ meta, data, className, anchor }) {
    const {
        header_text,
        second_header,
        content,
        cta,
        pardot_link,
        desktop_image,
        mobile_image,
        style_options_flip_col,
        style_options_wide_image
    } = data;

    let desktopImage, mobileImage;
    if (desktop_image) {
        desktopImage = meta?.images[desktop_image];
    }
    if (mobile_image) {
        mobileImage = meta?.images[mobile_image];
    }

    return (
        <TwoColCTA
            className={className}
            anchor={anchor}
            headerText={header_text}
            secondHeader={second_header}
            content={content}
            cta={cta}
            pardot_link={pardot_link}
            desktopImage={desktopImage}
            mobileImage={mobileImage}
            flip={style_options_flip_col === '1' ? true : false}
            wide={style_options_wide_image === '1' ? true : false}
        />
    );
}

export default ACFTwoColCTA;
