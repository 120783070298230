import React, { useState } from 'react';
import SwiperCore, { Pagination, Navigation, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { getAbsoluteUrl } from '../../../lib/helpers';
import NextNavLink from '../../util/NextNavLink';
SwiperCore.use([Pagination, Navigation, Autoplay]);

export const UpcomingEventsCarousel = ({
    className = '',
    anchor = '',
    top_heading,
    main_heading,
    cta,
    items = []
}) => {
    return (
        <div
            className={`md:py-32 py-16 overflow-hidden bg-cover ${className}`}
            id={anchor}
        >
            <div className="container">
                <div className="items-end mb-12 md:mb-16 md:flex">
                    <div className="flex-1 wow fadeInUp">
                        {top_heading && (
                            <p className="mb-4 font-medium md:mb-6 label text-purple">
                                {top_heading}
                            </p>
                        )}
                        {main_heading && (
                            <h4 className="md:h3 h2">{main_heading}</h4>
                        )}
                    </div>
                    <div className="hidden flex-0 md:block">
                        {cta && (
                            <a
                                target={cta.target}
                                href={getAbsoluteUrl(cta.url)}
                                className="inline-flex items-center mb-4 font-medium text-purple-midnight lead icon-circle"
                            >
                                {cta.title}
                                <span className="relative flex items-center h-4 ml-4">
                                    <i className="inline-block en-long-arrow text-32 leading-18 relative z-[1]"></i>

                                    <svg
                                        width="32"
                                        height="32"
                                        className="absolute -right-3 -top-2 icon-circle__circle"
                                    >
                                        <circle
                                            cx="16"
                                            cy="16"
                                            r="15"
                                            className="icon-circle__base"
                                        ></circle>
                                        <circle
                                            cx="16"
                                            cy="16"
                                            r="15"
                                            className="icon-circle__fill icon-circle__fill--dark"
                                        ></circle>
                                    </svg>
                                </span>
                            </a>
                        )}
                    </div>
                </div>
                <div className="swiper-auto-width ">
                    <div className="full-screen-right">
                        <Swiper
                            autoplay={{
                                delay: 5000
                            }}
                            spaceBetween={32}
                            slidesPerView="auto"
                            navigation={{
                                prevEl: '.insight-prev',
                                nextEl: '.insight-next'
                            }}
                            pagination={{
                                clickable: true,
                                el: '.insight-custom-pagination'
                            }}
                        >
                            {items &&
                                items.map((item, i) => (
                                    <SwiperSlide key={i}>
                                        <p className="mb-4 md:mb-6 label label--small">
                                            {item?.categories[0]?.name ||
                                                'Upcoming Event'}
                                            <span className="hidden px-2 opacity-50 md:inline-block">
                                                /
                                            </span>
                                            <br className="md:hidden" />
                                            <span className="opacity-50">
                                                {getDate(item?.date)}
                                            </span>
                                        </p>
                                        <div className="relative py-6 px-7 md:w-[352px] md:h-[352px] w-[240px] h-[240px]">
                                            <div className="relative z-[1] flex items-end h-full">
                                                <div>
                                                    <h5
                                                        className="text-white lead md:lead--large"
                                                        dangerouslySetInnerHTML={{
                                                            __html: item?.title
                                                        }}
                                                    />
                                                    <NextNavLink
                                                        href={item?.permalink}
                                                    >
                                                        <a className="inline-flex items-center mt-4 lead text-purple-periwinkle hover:text-purple-light">
                                                            Learn More{' '}
                                                            <i className="ml-2 en-long-arrow text-32"></i>
                                                        </a>
                                                    </NextNavLink>
                                                </div>
                                            </div>

                                            <div
                                                className={`absolute top-0 left-0 z-0 w-full h-full bg-gradient-${Math.floor(
                                                    Math.random() * (12 - 7) + 7
                                                )}`}
                                                style={{
                                                    backgroundImage: `url(${item?.thumbnail}), linear-gradient(0deg, #101820 40.72%, rgba(30, 26, 52, 0) 100%)`,
                                                    backgroundBlendMode:
                                                        'multiply',
                                                    backgroundSize: 'cover'
                                                }}
                                            />
                                        </div>
                                    </SwiperSlide>
                                ))}
                        </Swiper>
                    </div>
                    <div className="flex items-center justify-between md:mt-14 mt-11">
                        <div className="cursor-pointer insight-prev">
                            <i className="inline-block transform rotate-180 en-long-arrow text-32 text-purple-midnight"></i>
                        </div>
                        <div className="insight-custom-pagination"></div>
                        <div className="cursor-pointer insight-next">
                            <i className="en-long-arrow text-32 text-purple-midnight"></i>
                        </div>
                    </div>
                </div>
                <div className="mt-11 md:hidden">
                    {cta && (
                        <a
                            target={cta.target}
                            href={getAbsoluteUrl(cta.url)}
                            className="inline-flex items-center font-medium text-purple-midnight lead icon-circle"
                        >
                            {cta.title}
                            <span className="relative flex items-center h-4 ml-4">
                                <i className="inline-block en-long-arrow text-32 leading-18 relative z-[1]"></i>

                                <svg
                                    width="32"
                                    height="32"
                                    className="absolute -right-3 -top-2 icon-circle__circle"
                                >
                                    <circle
                                        cx="16"
                                        cy="16"
                                        r="15"
                                        className="icon-circle__base"
                                    ></circle>
                                    <circle
                                        cx="16"
                                        cy="16"
                                        r="15"
                                        className="icon-circle__fill icon-circle__fill--dark"
                                    ></circle>
                                </svg>
                            </span>
                        </a>
                    )}
                </div>
            </div>
        </div>
    );
};
function getDate(date) {
    let d = new Date(date);
    var month = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
    ];
    let getYear = d.getFullYear();
    let getMonth = d.getMonth();
    getMonth = month[getMonth];
    let getDay = d.getDate();
    return `${getMonth}.${getDay}.${getYear}`;
}
export default UpcomingEventsCarousel;
