import React, { useState, useEffect } from 'react';

export const TextHeading = ({
    className = '',
    anchor = '',
    top_heading,
    main_heading,
    flip = '0',
    description
}) => {
    return (
        <div
            className={`py-16 relative wow fadeInUp overflow-hidden ${className} `}
            id={anchor}
        >
            <div className="container relative z-[1]">
                <div
                    className={`mb-16 lg:grid  ${
                        flip === '1' ? '' : 'grid-cols-12 gap-8'
                    }`}
                >
                    <div
                        className={`${
                            flip === '1' ? '' : 'col-span-5 '
                        } wow fadeInUp`}
                    >
                        {/* added a spacer in the event there is no heading, columns will still align */}
                        {top_heading ? (
                            <p
                                className="mb-4 font-medium label text-purple md:mb-6"
                                dangerouslySetInnerHTML={{
                                    __html: top_heading
                                }}
                            />
                        ) : (
                            <div data-name="spacer" className="min-h-[3rem]" />
                        )}
                        {main_heading && (
                            <h3
                                className=""
                                dangerouslySetInnerHTML={{
                                    __html: main_heading
                                }}
                            />
                        )}
                    </div>
                    <div
                        className={`${
                            flip === '1' ? '' : 'col-span-7 '
                        } wow fadeInUp`}
                    >
                        <div
                            className={`justify-center mt-4 lg:pt-2  lg:flex ${
                                flip === '1' ? '' : 'lg:mt-12'
                            }`}
                        >
                            {description && (
                                <div
                                    className={` lead lead--large opacity-70 ${
                                        flip === '1' ? '' : 'lg:max-w-md '
                                    }`}
                                    dangerouslySetInnerHTML={{
                                        __html: description
                                    }}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default TextHeading;
