import React from 'react';
import { extractRepeaterField } from '../../util/blocks';

import { QA } from '../../components/enfusion/QA';

function ACFQA({ meta, data, className, anchor }) {
    const lists = extractRepeaterField(data, 'description_list');
    return <QA className={className} anchor={anchor} lists={lists} />;
}

export default ACFQA;
