import React from 'react';
import { extractRepeaterField } from '../../util/blocks';

import { EventHero } from '../../components/enfusion/EventHero';

function ACFEventHero({ meta, data = [], className, anchor, pageProps }) {
    // console.log(pageProps);
    const { top_header, main_header } = data;

    return (
        <EventHero
            className={className}
            anchor={anchor}
            title={main_header}
            breadcrumbs={top_header}
        />
    );
}

export default ACFEventHero;
