import React, { useState } from 'react';

import NextNavLink from '../../util/NextNavLink';
export const CTAModule = ({
    className = '',
    anchor = '',
    heading,
    cta,
    fluid = '1',
    light_dark,
    background = ''
}) => {
    return (
        <div
            className={`overflow-hidden  relative  wow fadeInUp ${className}`}
            id={anchor}
        >
            <div
                className={`${fluid === '1' ? 'bg-cover py-4' : 'container'}`}
                style={
                    background && fluid === '1'
                        ? {
                              backgroundImage: `url(${
                                  background?.src || background?.mediaItemUrl
                              })`
                          }
                        : {}
                }
            >
                <div
                    className={`lg:flex items-center justify-between  bg-cover md:py-28 py-10 lg:text-left text-center ${
                        fluid !== '1' ? 'lg:px-24 px-8' : 'container'
                    }`}
                    style={
                        background && fluid !== '1'
                            ? {
                                  backgroundImage: `url(${
                                      background?.src ||
                                      background?.mediaItemUrl
                                  })`
                              }
                            : {}
                    }
                >
                    {heading && (
                        <h3
                            className={`lg:mb-0 mb-8 wow fadeInUp md:h3 h4 ${
                                light_dark === 'Light'
                                    ? 'text-white'
                                    : 'text-purple-midnight'
                            }`}
                            dangerouslySetInnerHTML={{ __html: heading }}
                        />
                    )}
                    {cta && (
                        <NextNavLink href={cta.url} target={cta.target}>
                            <a
                                className={`${
                                    light_dark === 'Light'
                                        ? 'btn--white'
                                        : 'btn--midnight'
                                } btn md:btn--lg wow fadeInUp`}
                            >
                                {cta.title}
                            </a>
                        </NextNavLink>
                    )}
                </div>
            </div>
        </div>
    );
};
export default CTAModule;
