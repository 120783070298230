import { Transition } from '@headlessui/react';

function PardotLink({ item, modalState, setModalState }) {
    let { pardot_link, cta } = item;
    if (pardot_link === 'Normal popup') {
        pardot_link = 'np';
    }
    return (
        <Transition
            className={`absolute z-[100] ${
                pardot_link === 'np'
                    ? 'lg:p-10 p-6 rounded-10 overflow-hidden'
                    : ''
            }`}
            show={modalState}
            enter="duration-200 ease-out"
            enterFrom="opacity-0"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
        >
            <div
                className={` z-[99] ${
                    pardot_link === 'np'
                        ? 'fixed md:max-w-xl md:max-h-[650px] w-full h-screen top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 md:p-10 p-4 bg-white'
                        : 'fixed top-0 left-0 w-full h-full min-h-screen'
                }`}
            >
                <div className="absolute top-0 right-0 z-[1] p-4">
                    <a
                        href="#"
                        onClick={(e) => {
                            e.preventDefault();
                            setModalState(false);
                        }}
                        className="flex items-center font-medium lead text-purple-midnight"
                    >
                        <span className="min-w-[42px]">Close</span>
                        <span
                            className={`icon-expaded relative h-6 w-6 flex-col justify-between items-center flex ml-3`}
                        >
                            <span
                                className={`absolute inline-block w-1 h-1 rounded-10 bg-purple-midnight`}
                            ></span>
                            <span
                                className={`relative top-0 inline-block w-1 h-1 rounded-10 bg-purple-midnight`}
                            ></span>
                            <span
                                className={`inline-block w-1 h-1 rounded-10 bg-purple-midnight`}
                            ></span>
                            <span
                                className={`relative bottom-0 inline-block w-1 h-1 rounded-10 bg-purple-midnight`}
                            ></span>
                            <span
                                className={`absolute bottom-0 inline-block w-1 h-1 rounded-10 bg-purple-midnight`}
                            ></span>
                        </span>
                    </a>
                </div>
                <iframe
                    src={cta?.url}
                    className="w-full h-full bg-white"
                ></iframe>
            </div>
            {pardot_link === 'np' ? (
                <div className="fixed top-0 left-0 w-full h-screen bg-purple-midnight bg-opacity-60 z-[1]"></div>
            ) : (
                ''
            )}
        </Transition>
    );
}
export default PardotLink;
