import React from 'react';

export const ClientQuote = ({
    className,
    anchor,
    client_image,
    client_name,
    client_title,
    business_name,
    quote,
    region
}) => {
    return (
        <section className="bg-white">
            <div className="container py-[62px]">
                <div className="" />
                <figure className="flex md:flex-row flex-col">
                    {!client_image.id ? null : (
                        <div className="flex md:flex-col flex-row md:w-1/4 w-full md:items-baseline items-end mt-6 sm:justify-start justify-between gap-[19px]">
                            <div className="md:w-full w-[160px]">
                                <img
                                    className=""
                                    src={client_image?.src}
                                    alt="Client logo"
                                />
                            </div>
                            <figcaption className="mt-4 w-[45%] md:w-full md:pl-0">
                                <div className="font-semibold text-purple md:text-[14px] text-[15px] leading-[22px] md:mb-4 mb-0">
                                    {client_name}
                                </div>
                                <div className="md:mt-1 mt-0 text-gray-500 text-[15px] font-normal client-info-text leading-[22px]">
                                    {client_title}
                                </div>
                                <div className="md:mt-1 mt-0 text-gray-500 text-[15px] font-normal client-info-text leading-[22px]">
                                    {business_name}
                                </div>
                                <div className="md:mt-1 mt-0 text-gray-500 text-[15px] font-normal client-info-text leading-[22px]">
                                    {region}
                                </div>
                            </figcaption>
                        </div>
                    )}
                    <div
                        className={
                            !client_image.id
                                ? 'flex w-full'
                                : 'flex md:w-3/4 w-full md:ml-6 ml-0 pr-0'
                        }
                    >
                        <blockquote className="md:text-32 text-20 font-normal md:leading-[40px] leading-28 text-purple client-quote">
                            <p>{quote}”</p>
                        </blockquote>
                    </div>
                </figure>
            </div>
        </section>
    );
};

export default ClientQuote;
