import React from 'react';
import { extractRepeaterField } from '../../util/blocks';

import { TwoColAccordianList2 } from '../../components/enfusion/TwoColAccordianList2';

function ACFTwoColAccordianList2({ meta, data, className, anchor, pageProps }) {
    const { themeAssets } = pageProps;

    const {
        cta,
        header_left_col_heading,
        header_left_col_icon,
        header_right_col_heading,
        header_right_col_icon,
        icon,
        top_heading
    } = data;

    let lists = extractRepeaterField(data, 'accordions');

    let mainIcon = themeAssets[icon];
    let leftIcon = themeAssets[header_left_col_icon];
    let rightIcon = themeAssets[header_right_col_icon];

    lists = lists.map((repeaterItem) => ({
        ...repeaterItem,
        image_icon: themeAssets[repeaterItem.image_icon] || {}
    }));
    return (
        <TwoColAccordianList2
            className={className}
            anchor={anchor}
            lists={lists}
            leftHeading={header_left_col_heading}
            rightHeading={header_right_col_heading}
            leftIcon={leftIcon}
            rightIcon={rightIcon}
            icon={mainIcon}
            top_heading={top_heading}
            cta={cta}
        />
    );
}

export default ACFTwoColAccordianList2;
