import React from 'react';
import { extractRepeaterField } from '../../util/blocks';

import { TwoColTextImage } from '../../components/enfusion/TwoColTextImage';

function ACFTwoColTextImage({ meta, data, className, anchor }) {
    const {
        header_text,
        second_header,
        content,
        desktop_image,
        mobile_image,
        background_image,
        style_options_flip_col,
        style_options_wide_image,
        style_options_link_is_button,
        cta
    } = data;

    let desktopImage, mobileImage, backgroundImage;
    if (desktop_image) {
        desktopImage = meta?.images[desktop_image];
    }
    if (mobile_image) {
        mobileImage = meta?.images[mobile_image];
    }

    if(background_image)
    backgroundImage = meta?.images[background_image];

    return (
        <TwoColTextImage
            className={className}
            anchor={anchor}
            headerText={header_text}
            secondHeader={second_header}
            content={content}
            desktopImage={desktopImage}
            mobileImage={mobileImage}
            backgroundImage={backgroundImage}
            flip={style_options_flip_col === '1' ? true : false}
            wide={style_options_wide_image === '1' ? true : false}
            button={style_options_link_is_button === '1' ? true : false}
            cta={cta}
        />
    );
}

export default ACFTwoColTextImage;
