import React, { useState } from 'react';
import SwiperCore, { Pagination, Navigation, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Transition } from '@headlessui/react';
import { getAbsoluteUrl } from '../../../lib/helpers';
import NextNavLink from '../../util/NextNavLink';
import ImageSlider from '../ImageSlider/ImageSlider';
SwiperCore.use([Pagination, Navigation, Autoplay]);

export const CareersCarousel = ({
    className = '',
    anchor = '',
    top_heading,
    main_heading,
    content,
    items
}) => {
    const [modalState, setModalState] = useState(false);
    const [modalImages, setModalImages] = useState([]);
    const [modalIndex, setModalIndex] = useState(0);

    const handleImageClick = (images, index) => {
        setModalImages(images);
        setModalIndex(index);
        setModalState(true);
    };

    return (
        <div
            className={`md:py-32 py-16 overflow-hidden bg-cover ${className}`}
            id={anchor}
        >
            <div className="container">
                <div className="items-end mb-12 md:mb-16 md:flex">
                    <div className="flex-1 wow fadeInUp">
                        {top_heading && (
                            <p className="mb-4 font-medium md:mb-6 label text-purple text-center">
                                {top_heading}
                            </p>
                        )}
                        {main_heading && (
                            <h4 className="md:h3 h2 text-center">{main_heading}</h4>
                        )}
                        {content && (
                            <h6 className="h6 text-center careers-carousel-h6">{content}</h6>
                        )}
                    </div>
                </div>
                <div className="swiper-auto-width">
                    <div className="full-screen-right">
                        <Swiper

                            spaceBetween={32}
                            slidesPerView="auto"
                            navigation={items.length > 3 ? {
                                prevEl: '.insight-prev',
                                nextEl: '.insight-next'
                            } : false}
                            pagination={{
                                clickable: true,
                                el: '.insight-custom-pagination'
                            }}
                        >
                            {items.map((item, i) => (
                                <SwiperSlide key={i}>
                                    <div className="rounded-lg md:w-[352px] w-[320px] h-[280px] career-carousel-item">
                                        <div className="card-inner">
                                            <div className="card-front w-auto h-[190px]">
                                                {item?.src && (
                                                    <img
                                                        src={item?.src}
                                                        className="object-fill cursor-pointer"
                                                        onClick={() => handleImageClick(item?.sliderImages, 0)}
                                                    />
                                                )}
                                                <div className='mt-5 px-7'>
                                                    <h5
                                                        className="text-purple-midnight lead md:lead--large"
                                                        dangerouslySetInnerHTML={{
                                                            __html: item?.title
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className={`card-back w-auto h-[190px] ${item?.sliderImages.length > 0 ? '' : 'px-8 py-4 overflow-auto'} flex items-center justify-center`}>
                                                <div>
                                                    {
                                                        item?.sliderImages.length > 0 ?
                                                            <ImageSlider images={item?.sliderImages} onImageClick={(src, index) => handleImageClick(item?.sliderImages, index)} />
                                                            :
                                                            <div
                                                                className="text-purple-midnight p"
                                                                dangerouslySetInnerHTML={{
                                                                    __html: item?.content
                                                                }}
                                                            />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                    {items.length > 3 && (
                        <div className="flex items-center justify-between md:mt-14 mt-11">
                            <div className="cursor-pointer insight-prev">
                                <i className="inline-block transform rotate-180 en-long-arrow text-32 text-purple-midnight"></i>
                            </div>
                            <div className="insight-custom-pagination"></div>
                            <div className="cursor-pointer insight-next">
                                <i className="en-long-arrow text-32 text-purple-midnight"></i>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <Transition
    className="relative z-[100]"
    show={modalState}
    enter="duration-200 ease-out"
    enterFrom="opacity-0"
    enterTo="opacity-100 scale-100"
    leave="duration-100 ease-in"
    leaveFrom="opacity-100 scale-100"
    leaveTo="opacity-0 scale-95"
>
    <div 
        className="fixed inset-0 w-full h-full bg-white bg-opacity-75 z-[99] flex items-center justify-center"
        onClick={() => setModalState(false)}  // Close modal on overlay click
    >
        <div 
            className="relative w-full max-w-3xl h-auto shadow-lg rounded-lg p-4 flex items-center justify-center"
            onClick={(e) => e.stopPropagation()}  // Prevent closing when clicking inside the slider container
        >
            <ImageSlider images={modalImages} initialIndex={modalIndex} autoplay={false} isPopup={true} />
            </div>

            <div className="absolute top-0 right-0 z-[1] p-4">
                <a
                    href="#"
                    onClick={(e) => {
                        e.preventDefault();
                        setModalState(false);
                    }}
                    className="flex items-center justify-center font-medium text-purple-midnight"
                >
                    <span className="min-w-[42px]">Close</span>
                    <span className="icon-expanded relative h-6 w-6 flex-col justify-between items-center flex ml-3">
                        <span className="absolute inline-block w-1 h-1 rounded-10 bg-purple-midnight"></span>
                        <span className="relative top-0 inline-block w-1 h-1 rounded-10 bg-purple-midnight"></span>
                        <span className="inline-block w-1 h-1 rounded-10 bg-purple-midnight"></span>
                        <span className="relative bottom-0 inline-block w-1 h-1 rounded-10 bg-purple-midnight"></span>
                        <span className="absolute bottom-0 inline-block w-1 h-1 rounded-10 bg-purple-midnight"></span>
                    </span>
                </a>
            </div>
    </div>
</Transition>







        </div>
    );
};

export default CareersCarousel;
