import { useEffect, useState } from 'react';

export default function useBetterMediaQuery(mediaQueryString) {
  const [matches, setMatches] = useState(null);

  useEffect(() => {
    const mediaQueryList = window.matchMedia(mediaQueryString);
    const listener = () => setMatches(!!mediaQueryList.matches);

    listener();
    mediaQueryList.addEventListener('change', listener); // Using addEventListener with 'change' event

    return () => {
      mediaQueryList.removeEventListener('change', listener); // Using removeEventListener with 'change' event
    };
  }, [mediaQueryString]);

  return matches;
}
