import React, { useState, useEffect } from 'react';
// import Swiper core and required modules
import SwiperCore, { Pagination } from 'swiper';
import NextNavLink from '../../util/NextNavLink';
import { Transition } from '@headlessui/react';

import { useAppContext } from '../../../util/context';
import { getAbsoluteUrl } from '../../../lib/helpers'


export const FormHero = ({
    className = '',
    anchor = '', 
    top_heading,
    main_heading_1,
    main_heading_2,
    description,
    background_image,
    background_pattern,
    style_dark_light,
    headingIcon,
    cta,
    wide = '0',
    scrollto = '#',
    form_url,
    short_header,
    scroll_arrow
}) => {
    const context = useAppContext();
   
    context.toggleDarkMode(style_dark_light !== '1' ? false : true);
    context.toggleHeaderTheme('');
   

    return (
        <div
            className={`${className} relative`}
            id={anchor}
        >
            <div
                className={`flex items-center bg-cover w-full relative md:bg-center bg-right md:pb-0 pb-28 ${short_header === '1' ? 'md:min-h-[600px] md:mb-32' : 'md:min-h-[800px] overflow-hidden' } ${
                    background_image?.class ? background_image?.class : 'bg-gray-19'
                }`}
            >
                <div className="container relative z-[1] md:h-auto h-full md:mt-6">
                    <div className="grid grid-cols-1 gap-10 md:grid-cols-2 lg:gap-32 md:py-0 pt-28">
                        <div className={`${headingIcon?.class ? '' : 'lg:pr-[4%]'} wow fadeInLeft`}>
                            <div className="h-full ">
                                <div className={`${short_header === '1' ? 'md:pt-32' : '' }`}>
                                    
                                    {headingIcon && <i className={`${headingIcon?.class} text-40 text-purple block mb-7`}></i>}
                                    {top_heading && (
                                        <p
                                            className={`flex items-center mb-5 font-medium ${headingIcon?.class ? 'md:mb-7' : 'md:mb-8'} label ${
                                                style_dark_light === '1'
                                                    ? 'text-white'
                                                    : 'text-purple-midnight'
                                            }`}
                                        >
                                            {!headingIcon?.class && 
                                                <i className="inline-block w-3 h-3 mr-3 border-2 rounded-10 border-purple"></i>
                                            }
                                            {top_heading}
                                        </p>
                                    )}
                                
                                    <h1 className={`mb-5 md:h3 h1 ${headingIcon?.class || wide !== '1' ? 'md:mb-7' : 'md:mb-8 lg:h2'} `}>
                                        {main_heading_1 && (
                                            <span
                                                className={`${
                                                    style_dark_light === '1'
                                                        ? 'text-white'
                                                        : 'text-purple-midnight'
                                                    }`}
                                                dangerouslySetInnerHTML={{ __html: main_heading_1}}
                                        />
                                        )}

                                        {main_heading_2 && (
                                            <>
                                            <br />
                                            <span
                                                className={`${
                                                    style_dark_light === '1'
                                                        ? 'text-purple'
                                                        : 'text-purple'
                                                    }`}
                                                    dangerouslySetInnerHTML={{ __html: main_heading_2}}
                                        />
                                        </>
                                        )}
                                    </h1>
                                    <div
                                        className={`lead lead--large opacity-70  ${
                                            style_dark_light === '1'
                                                ? 'text-white'
                                                : 'text-purple-midnight'
                                        }`}
                                        dangerouslySetInnerHTML={{
                                            __html: description
                                        }}
                                    />
                                    {cta?.url && (
                                        <NextNavLink href={cta?.url} target={cta?.target}><a className="hidden mt-10 btn btn--primary md:inline-block" dangerouslySetInnerHTML={{ __html:cta?.title}} ></a></NextNavLink>)
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={ `lg:pl-16 hidden ${short_header === '1' ? 'md:block' : 'md:flex items-center'}`}>
                            <div className={ `w-full p-8 bg-white ${short_header === '1' ? 'md:top-36 shadow-card relative' : ''}`}>
                                {form_url && <iframe height="100%" width="100%" allowtransparency="true" type="text/html" sandbox="allow-downloads allow-same-origin allow-scripts allow-popups allow-forms allow-top-navigation-by-user-activation allow-same-origin" src={form_url} id="pardot-iframe" style={{minHeight:`450px`}}></iframe>}
                            </div>
                        </div>
                    </div>
                </div>
                {background_pattern?.class && (
                    <div
                        className={`${background_pattern?.class} max-w-[1500px] w-full h-full md:max-h-[670px] max-h-[570px] bg-cover -bottom-1/3 md:-right-1/3 -right-12 absolute z-0`}
                    />
                )}
            </div>
        <div className={ `lg:pl-16 container md:hidden -mt-16 relative`}>
            <div className={ `w-full p-8 bg-white lg:top-36 shadow-card relative`}>
                {form_url && <iframe height="100%" width="100%" allowtransparency="true" type="text/html" sandbox="allow-downloads allow-same-origin allow-scripts allow-popups allow-forms allow-top-navigation-by-user-activation allow-same-origin" src={form_url} id="pardot-iframe" style={{minHeight:`470px`}}></iframe>}
            </div>
        </div>
        {short_header !== '1' ?
            <div className="sticky bottom-0 left-0 z-10 items-center justify-between hidden w-full lg:flex ">
                <div className="absolute z-[2] transform rotate-90 -translate-x-1/2 swipte-down-arrow left-1/2 bottom-12">
                    <a
                        href={getAbsoluteUrl(scrollto)}
                        className="flex items-center font-medium lead icon-circle"
                    >
                        {(scroll_arrow === '1' || typeof scroll_arrow === 'undefined') && (
                            <span className="relative flex items-center h-4 ml-4">
                                <i
                                className={`inline-block en-long-arrow text-32 leading-18 relative z-[1] transition ${
                                    context?.dark ? 'text-white' : 'text-purple-midnight'
                                }`}
                                ></i>

                                <svg
                                width="32"
                                height="32"
                                className="absolute -right-3 -top-2 icon-circle__circle"
                                >
                                <circle
                                    cx="16"
                                    cy="16"
                                    r="15"
                                    className="icon-circle__base"
                                ></circle>
                                <circle
                                    cx="16"
                                    cy="16"
                                    r="15"
                                    style={{
                                    stroke: context?.dark ? '#fff' : '#1E1A34'
                                    }}
                                    className="icon-circle__fill"
                                ></circle>
                                </svg>
                            </span>
                            )}
                    </a>
                </div>
            </div>
            : ''}
        </div>
    );
};
export default FormHero;
