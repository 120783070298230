import React, { useState } from 'react';
import Breadcrumb from '../Header/Breadcrumb';

import { useAppContext } from '../../../util/context';
import NextNavLink from '../../util/NextNavLink';
import ReactPlayer from 'react-player';

export const CareersHero = ({
    className = '',
    anchor = '',
    darkLight,
    content,
    breadcrumbs,
    main_header,
    second_main_header,
    background,
    links
}) => {
    const context = useAppContext();
    context.toggleDarkMode(darkLight !== '1' ? false : true);
    context.toggleHeaderTheme(darkLight !== '1' ? 'bg-white bg-opacity-0' : '');


    return (
        <>
        <div
            className={`md:pt-[122px] pt-28 relative ${className}`}
            id={anchor}
        >
            <div className="container relative z-[1]">
                <div className="flex items-center mb-5 md:mb-8 wow fadeInUp">
                    <i className="inline-block w-2.5 min-w-[0.66rem] h-2.5 mr-3 border-2 rounded-10 border-purple"></i>
                      <h2
                        className="font-medium label"
                        dangerouslySetInnerHTML={{ __html: breadcrumbs }}
                    />
                </div>
                <div className="grid-cols-12 gap-8 lg:grid ">
                    <div className="lg:col-span-7 wow fadeInUp">
                        {main_header && (
                            <h1 className="md:h1 h1">
                                 <span  dangerouslySetInnerHTML={{ __html:main_header }} />
                                <br />
                                <span className="text-purple" dangerouslySetInnerHTML={{ __html:second_main_header }} />
                            </h1>
                        )}
                        {content && (
                            <div
                                className="flex-1 mt-5 md:flex lead lead--large opacity-70 md:mt-8 lg:max-w-lg pb-28 lg:pb-0"
                                dangerouslySetInnerHTML={{
                                    __html: content
                                }}
                            />
                        )}
                    </div>
                    <div className="hidden lg:col-span-5 lg:block">
                       {hiringComponent(links) }
                    </div>
                </div>
            </div>
            <div style={{transform: `matrix(-1, 0, 0, 1, 0, 0)`}} className={ `absolute w-full h-full top-0 left-0 z-0 opacity-50 bg-left-bottom ${background?.class}`}></div>
            </div>
             <div  className={ `absolute w-full h-80 top-0 left-0 bg-gradient-to-b from-white `}></div>
     
            <div className="container block lg:hidden">
                <div className="">
                 {hiringComponent(links) }
                </div>
            </div>
        </>
    );
};


function hiringComponent(links) {
    
    return (
         <div className="px-8 py-6 -mt-14 transform bg-white md:px-16 md:py-12 shadow-card lg:-mb-32 lg:mt-0 relative z-[1]">
            <h4 className="mb-4 md:mb-8 wow fadeInUp">We’re hiring.</h4>
            <div>
                {links && links.map((item, index) =>
                    <NextNavLink href={getAbsoluteUrl(item?.link?.url)}>
                        <a data-wow-delay={`${index / 7}s`}  className="flex items-center justify-between py-4 border-t leading-20 wow fadeInUp border-purple-midnight last:border-b lead border-opacity-10 text-purple-midnight group hover:text-purple" key={index} >
                            <span dangerouslySetInnerHTML={{ __html: item?.link?.title }} />
                            <i className="transition en-long-arrow text-20 opacity-20 group-hover:text-purple group-hover:opacity-100"></i>
                        </a>
                    </NextNavLink>
                )}
            </div>
            <a href='/open-positions'  className="flex items-center justify-between mt-6 font-medium md:mt-11 text-purple-midnight lead icon-circle">
                See All Open Positions
                <span className="relative flex items-center h-4 ml-4">
                    <i className="inline-block en-long-arrow text-32 leading-18 relative z-[1]"></i>

                    <svg
                        width="32"
                        height="32"
                        className="absolute -right-3 -top-2 icon-circle__circle"
                    >
                        <circle
                            cx="16"
                            cy="16"
                            r="15"
                            className="icon-circle__base"
                        ></circle>
                        <circle
                            cx="16"
                            cy="16"
                            r="15"
                            className="icon-circle__fill icon-circle__fill--dark"
                        ></circle>
                    </svg>
                </span>
            </a>
        </div>
    )
}
export default CareersHero;
