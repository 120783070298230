import React, { useState } from 'react';

import { Transition } from '@headlessui/react';
import { getAbsoluteUrl } from '../../../lib/helpers';

export const TwoColOpenPosition = ({
    className = '',
    anchor = '',
    col2TopHeading,
    col2MainHeading,
    col1TopHeading,
    col1MainHeading,
    col1,
    col2,
    cta
}) => {
    const [disclosureState, setDisclosureState] = useState(false);

    const toggle = (index) => {
        if (disclosureState === index) {
            return setDisclosureState(null);
        }
        setDisclosureState(index);
    };

    return (
        <div className={`relative wow fadeInUp ${className}`} id={anchor}>
            <div className="lg:pt-32 pt-0 relative z-[2]">
                <div className="lg:container">
                    <div className="grid gap-0 lg:grid-cols-2 lg:gap-56">
                        <div className="px-6 py-16 lg:bg-none bg-gradient-12 lg:py-0 lg:px-0">
                            {col1TopHeading && (
                                <p
                                    className="mb-4 font-medium md:mb-6 label text-purple-periwinkle wow fadeInUp"
                                    dangerouslySetInnerHTML={{
                                        __html: col1TopHeading
                                    }}
                                />
                            )}
                            {col1MainHeading && (
                                <h3
                                    className="mb-12 text-white md:mb-16 md:h4 h2 wow fadeInUp"
                                    dangerouslySetInnerHTML={{
                                        __html: col1MainHeading
                                    }}
                                />
                            )}

                            {col1 &&
                                col1.map((item, index) => (
                                    <div
                                        className="border-white last:border-b border-opacity-10 wow fadeInUp"
                                        key={index}
                                    >
                                        <div
                                            className="flex items-center py-6 text-white border-t border-white cursor-pointer lead md:lead--large border-opacity-10 "
                                            onClick={() => {
                                                toggle('col1' + index);
                                            }}
                                        >
                                            <i className="mr-6 lg:mr-8 en-circle-check text-24 text-purple-periwinkle"></i>
                                            <span
                                                className="flex flex-1 lead lead--large"
                                                dangerouslySetInnerHTML={{
                                                    __html: item?.title
                                                }}
                                            />
                                            <i
                                                className={`en-caret-down opacity-30 inline-block transform text-14 ${
                                                    disclosureState ===
                                                    'col1' + index
                                                        ? 'rotate-180'
                                                        : ''
                                                }`}
                                            ></i>
                                        </div>
                                        <Transition
                                            show={
                                                disclosureState ===
                                                'col1' + index
                                            }
                                            className="pb-6 pl-12 lg:pl-14"
                                        >
                                            <div
                                                className="text-white lead opacity-70"
                                                dangerouslySetInnerHTML={{
                                                    __html: item?.description
                                                }}
                                            />
                                        </Transition>
                                    </div>
                                ))}
                        </div>
                        <div className="px-6 py-16 lg:bg-none bg-gradient-13 lg:py-0 lg:px-0 ">
                            {col2TopHeading && (
                                <p
                                    className="mb-4 font-medium text-white md:mb-6 label wow fadeInUp"
                                    dangerouslySetInnerHTML={{
                                        __html: col2TopHeading
                                    }}
                                />
                            )}
                            {col2MainHeading && (
                                <h3
                                    className="mb-12 text-purple-midnight md:mb-16 md:h4 h2 wow fadeInUp"
                                    dangerouslySetInnerHTML={{
                                        __html: col2MainHeading
                                    }}
                                />
                            )}
                            <div>
                                {col2 &&
                                    col2.map((item, index) => (
                                        <div
                                            className="border-purple-midnight last:border-b border-opacity-10 wow fadeInUp"
                                            key={index}
                                        >
                                            <div
                                                className="flex items-center py-6 border-t cursor-pointer border-purple-midnight text-purple-midnight lead md:lead--large border-opacity-10"
                                                onClick={() => {
                                                    toggle('col2' + index);
                                                }}
                                            >
                                                <i className="mr-6 text-white lg:mr-8 en-circle-check text-24"></i>
                                                <span
                                                    className="flex flex-1 lead lead--large"
                                                    dangerouslySetInnerHTML={{
                                                        __html: item?.title
                                                    }}
                                                />
                                                <i
                                                    className={`en-caret-down opacity-30 inline-block transform text-purple-midnight text-14 ${
                                                        disclosureState ===
                                                        'col2' + index
                                                            ? 'rotate-180'
                                                            : ''
                                                    }`}
                                                ></i>
                                            </div>
                                            <Transition
                                                show={
                                                    disclosureState ===
                                                    'col2' + index
                                                }
                                                className="pb-6 pl-12 lg:pl-14"
                                            >
                                                <div
                                                    className="text-purple-midnight lead opacity-70"
                                                    dangerouslySetInnerHTML={{
                                                        __html:
                                                            item?.description
                                                    }}
                                                />
                                            </Transition>
                                        </div>
                                    ))}
                            </div>
                            <div className="text-center lg:hidden wow fadeInUp">
                                {cta && (
                                    <a
                                        className="mt-12 btn btn--white"
                                        target={cta?.target}
                                        href={getAbsoluteUrl(cta.url)}
                                        dangerouslySetInnerHTML={{
                                            __html: cta?.title
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="hidden pb-16 text-center lg:block wow fadeInUp">
                        {cta && (
                            <a
                                className="lg:mt-16 btn btn--white btn--lg"
                                target={cta?.target}
                                href={getAbsoluteUrl(cta.url)}
                                dangerouslySetInnerHTML={{ __html: cta?.title }}
                            />
                        )}
                    </div>
                </div>
            </div>
            <div className="absolute top-0 left-0 hidden w-full h-full lg:flex">
                <div className="relative flex flex-1 h-full min-h-full bg-center bg-gradient-12-flip"></div>
                <div className="relative flex flex-1 h-full bg-center bg-gradient-13"></div>
            </div>
        </div>
    );
};
export default TwoColOpenPosition;
