import { InvestmentStrategies } from '../../components/enfusion/InvestmentStrategies';

function ACFInvestmentStrategies({ meta, data, className, anchor }) {
  const { heading } = data || {};
    return (
        <InvestmentStrategies
            className={className}
            anchor={anchor}
            heading={heading}
            data={data}
        />
    );
}

export default ACFInvestmentStrategies;