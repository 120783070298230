import React, { useEffect } from 'react';
import { extractRepeaterField } from '../../util/blocks';

import { Slider } from '../../components/enfusion/Slider';
import { useAppContext } from '../../util/context';

function CustomSlider({ meta, data, className, anchor, pageProps }) {
    const context = useAppContext();

    const { themeAssets } = pageProps;

    let slides = extractRepeaterField(data, 'slide');
    
    slides = slides.map((repeaterItem) => ({
        ...repeaterItem,
        heading_prefix: themeAssets[repeaterItem.heading_prefix] || {},
        main_image: meta?.images[repeaterItem.main_image] || {}
    }));
    // console.log(slides)
    return <Slider className={className} anchor={anchor} slides={slides} />;
}

export default CustomSlider;
