import React from 'react';

function CoreGroup({ children, className, anchor }) {
    return (
        <div id={anchor} className={className}>
            {children}
        </div>
    );
}

export default CoreGroup;
