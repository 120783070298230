import React from 'react';
import { extractRepeaterField } from '../../util/blocks';

import { Speakers } from '../../components/enfusion/Speakers';

function ACFSpeakers({ meta, data = [], className, anchor }) {
    const { speaker_list_block_section_title } = data;
    let lists = extractRepeaterField(data, 'speaker_list_block_speakers');

    lists = lists.map((repeaterItem) => ({
        ...repeaterItem,
        speaker_image: meta?.images[repeaterItem.speaker_image] || {}
    }));

    return (
        <Speakers
            className={className}
            anchor={anchor}
            lists={lists}
            title={speaker_list_block_section_title}
        />
    );
}

export default ACFSpeakers;
