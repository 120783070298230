import React from 'react';
import { extractRepeaterField } from '../../util/blocks';

import { TwoColOpenPosition } from '../../components/enfusion/TwoColOpenPosition';

function ACFTwoColOpenPosition({ meta, data = [], className, anchor }) {
    const {
        col_2_main_heading,
        col_2_top_heading,
        col_1_main_heading,
        col_1_top_heading,
        cta_link
    } = data;

    let col1 = extractRepeaterField(data, 'col_1_accordion');
    let col2 = extractRepeaterField(data, 'col_2_accordion');
    return (
        <TwoColOpenPosition
            className={className}
            anchor={anchor}
            col2TopHeading={col_2_top_heading}
            col2MainHeading={col_2_main_heading}
            col1TopHeading={col_1_top_heading}
            col1MainHeading={col_1_main_heading}
            col1={col1}
            col2={col2}
            cta={cta_link}
        />
    );
}

export default ACFTwoColOpenPosition;
