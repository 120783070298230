import React from 'react';
import { extractRepeaterField } from '../../util/blocks';

import { BulletList } from '../../components/enfusion/BulletList';

function ACFBulletList({ meta, data, className, anchor }) {
    const lists = extractRepeaterField(data, 'bullet_list');
    return <BulletList className={className} anchor={anchor} lists={lists} />;
}

export default ACFBulletList;
