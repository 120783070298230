import React from 'react';
import { extractRepeaterField } from '../../util/blocks';

import { UpcomingEventsCarousel } from '../../components/enfusion/UpcomingEventsCarousel';

function ACFUpcomingEventsCarousel({
    meta,
    data,
    className,
    anchor,
    pageProps
}) {
    const { top_heading, main_heading, cta, select_posts } = data;
    let customEvents;
    let result = [];

    if (select_posts?.length > 0) {
        customEvents = meta?.posts.filter((item, index) => {
            if (select_posts.includes(String(item.id))) {
                return true;
            }
            return false;
        });

        select_posts.forEach((item, index) => {
            customEvents.forEach((event, i) => {
                if (item === String(event.id)) {
                    result[index] = event;
                }
            });
        });
        customEvents = result;
    } else {
        customEvents = meta?.posts;
    }

    return (
        <UpcomingEventsCarousel
            className={className}
            anchor={anchor}
            top_heading={top_heading}
            main_heading={main_heading}
            cta={cta}
            items={customEvents}
        />
    );
}

export default ACFUpcomingEventsCarousel;
