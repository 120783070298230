import React from 'react';

export const EyebrowBlock = ({ heading, className, anchor }) => {
    return (
        <div
            id={anchor}
            className={`${className}`}
        >
            <div className="container text-center pt-[40px]">
                {heading && (
                    <h4
                        className="mb-4 text-lg lg:mb-6 label text-purple wow fadeInUp"
                        dangerouslySetInnerHTML={{
                            __html: heading
                        }}
                    />
                )}
            </div>
        </div>
    );
};

export default EyebrowBlock;
