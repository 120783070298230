import React from 'react';
import { extractRepeaterField } from '../../util/blocks';

import { LogoCards } from '../../components/enfusion/LogoCards';

function ACFLogoCards({ meta, data, className, anchor }) {
    let lists = extractRepeaterField(data, 'logos');
    lists = lists.map((repeaterItem) => ({
        ...repeaterItem,
        logo_image: meta?.images[repeaterItem.logo_image] || {}
    }));

    return <LogoCards className={className} anchor={anchor} lists={lists} />;
}

export default ACFLogoCards;
