import React, { useState, useEffect } from 'react';
// import Swiper core and required modules
import SwiperCore, { Pagination, Autoplay } from 'swiper';
import NextNavLink from '../../util/NextNavLink';
import { Transition } from '@headlessui/react';

import { useAppContext } from '../../../util/context';
import { useWindowDimensions } from '../../../util/hooks';

import { Swiper, SwiperSlide } from 'swiper/react';
SwiperCore.use([Pagination, Autoplay]);
import ReactPlayer from 'react-player';
import { getAbsoluteUrl } from '../../../lib/helpers';

export const Slider = ({ className = '', anchor = '', slides }) => {
    const context = useAppContext();

    const [modalState, setModalState] = useState({ open: false, details: {} });
    const [showMobile, setShowMobile] = useState(false);

    useEffect(() => {
        function handleResize() {
            setShowMobile(useWindowDimensions().width > 1024 ? false : true);
        }
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [useWindowDimensions().width]);
    // 
    return (
        <div
            className={`${className} pt-12 md:h-2/5 md:min-h-[400px] relative mb-2`}
            id={anchor}
        >
            <Swiper
                autoplay={{
                    delay: 8000
                }}
                spaceBetween={0}
                slidesPerView={1}
                pagination={{
                    el: '.swiper-custom-pagination',
                    clickable: true
                }}
                onSlideChange={(e) => {
                    context.toggleDarkMode(
                        slides[e.activeIndex].style_dark_light === 'light'
                            ? false
                            : true
                    );

                    let target = document.getElementById(
                        'swipte-pagination-number-curr'
                    );
                    if(target)
                        target.innerHTML = e.activeIndex + 1;
                }}
                onSwiper={(swiper) => {
                    let total = document.getElementById(
                        'swipte-pagination-number-total'
                    );
                    context.toggleDarkMode(true);
                    context.toggleHeaderTheme('');
                    let length = swiper.slides.length;
                    if(total)
                        total.innerHTML = length;
                }}
                className={`${context?.dark ? '' : 'swiper-light'} h-full`}
            >
                {slides.map((slide, index) => (
                    <SwiperSlide className="h-auto-important" key={index}>
                        <h2 className="container text-2xl mb-3 flex w-full text-purple podcastheading uppercase">
                            {slide.heading_headline}
                        </h2>
                        <div className={`flex items-center mb-3 h-full bg-cover overflow-hidden relative bg-center`}>
                            <div className="container relative z-[1] md:h-auto h-full">
                                <div className="grid grid-cols-1 md:grid-cols-12 md:gap-0 md:py-0 py-28">
                                    <div className="md:col-span-12">
                                        <div
                                            className={`relative flex items-center justify-center min-h-full md:left-auto md:w-auto md:static ${
                                                slide?.main_image?.src
                                                    ? 'w-screen'
                                                    : ''
                                            }`}
                                        >
                                             {/* className="md:absolute w-screen transform md:max-w-[50vw] md:translate-x-[8.33vw] md:pr-[8.33vw] md:pl-6 md:right-auto relative" */}
                                            {/* md:left-1/2 */}
                                            {slide?.main_image?.src && (
                                                <div>
                                                    <img
                                                        src={
                                                            slide?.main_image
                                                                ?.src
                                                        }
                                                        alt={
                                                            slide?.main_image
                                                                ?.title ||
                                                            'Enfusion'
                                                        }
                                                        className="w-full is-animated"
                                                    />
                                                </div>
                                            )}
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
            <div className="container sticky bottom-0 left-0 z-10 flex items-center justify-between w-full sticky-mobile">
                <div className="absolute swiper-custom-pagination"></div>
            <div
                    id="swipte-pagination-number"
                    className={`absolute z-[2] flex items-center font-medium text-center bottom-7 right-6 label transition ${
                        context?.dark ? 'text-white' : 'text-purple-midnight'
                    }`}
                >
                    <span
                        id="swipte-pagination-number-curr"
                        className="inline-block w-2.5"
                    >
                        1
                    </span>
                    <span className="inline-block w-2.5">/</span>

                    <span
                        id="swipte-pagination-number-total"
                        className="inline-block w-2.5"
                    >
                        1
                    </span>
                </div></div>
            <Transition
                className="relative z-[100]"
                show={modalState.open}
                enter="duration-200 ease-out"
                enterFrom="opacity-0"
                enterTo="opacity-100 scale-100"
                leave="duration-100 ease-in"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
            >
                <div className="fixed top-0 left-0 flex flex-col w-full h-full min-h-screen bg-gradient-11 lg:pb-36">
                    <div className="flex items-center justify-between px-6 pt-4 pb-6 lg:pb-16 lg:pt-9 flex-0">
                        <h5 className="text-white">Video --- Meet Enfusion</h5>
                        <a
                            href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                setModalState({
                                    open: false,
                                    details: {}
                                });
                            }}
                            className={`flex items-center font-medium  lead opacity-80 hover:opacity-100 ${
                                !modalState.open
                                    ? 'text-purple-midnight'
                                    : 'text-white'
                            }`}
                        >
                            <span className="min-w-[42px]">
                                {!modalState.open ? `Menu` : 'Close'}
                            </span>
                            <span
                                className={`${
                                    modalState.open
                                        ? 'icon-expaded'
                                        : 'icon-collapse'
                                } relative h-6 w-6 flex-col justify-between items-center flex ml-3`}
                            >
                                <span
                                    className={`absolute inline-block w-1 h-1 rounded-10 ${
                                        !modalState.open
                                            ? 'bg-purple-midnight'
                                            : 'bg-white'
                                    }`}
                                ></span>
                                <span
                                    className={`relative top-0 inline-block w-1 h-1 rounded-10 ${
                                        !modalState.open
                                            ? 'bg-purple-midnight'
                                            : 'bg-white'
                                    }`}
                                ></span>
                                <span
                                    className={`inline-block w-1 h-1 rounded-10 ${
                                        !modalState.open
                                            ? 'bg-purple-midnight'
                                            : 'bg-white'
                                    }`}
                                ></span>
                                <span
                                    className={`relative bottom-0 inline-block w-1 h-1 rounded-10 ${
                                        !modalState.open
                                            ? 'bg-purple-midnight'
                                            : 'bg-white'
                                    }`}
                                ></span>
                                <span
                                    className={`absolute bottom-0 inline-block w-1 h-1 rounded-10 ${
                                        !modalState.open
                                            ? 'bg-purple-midnight'
                                            : 'bg-white'
                                    }`}
                                ></span>
                            </span>
                        </a>
                    </div>
                    <div className="flex-1 px-6 lg:px-40 ">
                        <ReactPlayer
                            width="100%"
                            height="100%"
                            className=""
                            url={modalState?.details?.cta_link?.url}
                            controls={true}
                        />
                    </div>
                </div>
            </Transition>
        </div>
    );
};
export default Slider;
