import React, { useState, useEffect } from 'react';
import { getAbsoluteUrl } from '../../../lib/helpers';
import NextNavLink from '../../util/NextNavLink';

export const InsightSource = ({
    className = '',
    anchor = '',
    source_name,
    author_link,
    author_name,
    source_logo,
    author_logo,
    company
}) => {
    return (
        <div
            className={`relative wow fadeInUp overflow-hidden pb-16 ${className} `}
            id={anchor}
        >
            <div className="container relative z-[1]">
                <div className="pb-4 border-b md:pb-4 border-purple-midnight border-opacity-20">
                    <div className="flex items-center space-x-6 md:space-x-8">
                        <div className="md:pr-8 md:border-r border-purple-midnight border-opacity-20">
                            <div
                                className="md:w-40 md:h-40 w-28 h-28 rounded-[200px] bg-cover bg-center "
                                style={{
                                    backgroundImage: `url(${
                                        author_logo?.medium_url ||
                                        author_logo?.src
                                    })`
                                }}
                            />
                        </div>
                        <div className="">
                            {author_name && (
                                <h6 className="mb-2 md:h5 lead lead--large">
                                    {author_name}
                                </h6>
                            )}

                            {source_name && (
                                <p className="mb-2 md:lead lead--small text-purple">
                                    {source_name}
                                </p>
                            )}

                            {company && (
                                <p className="mb-2 label md:label--small label--xs">
                                    {company}
                                </p>
                            )}
                            {source_logo?.src && (
                                <a
                                    href={getAbsoluteUrl(author_link)}
                                    target="_blank"
                                >
                                    <img
                                        src={source_logo?.src}
                                        alt={author_link}
                                    />
                                </a>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default InsightSource;
