import React from 'react';

import { FormHero } from '../../components/enfusion/FormHero';

function ACFFormHero({ meta, data, className, anchor, pageProps }) {
    const { themeAssets } = pageProps;

    const {
        description,
        main_heading_1,
        main_heading_2,
        top_heading,
        style_options_background_image,
        style_options_background_pattern,
        style_options_dark,
        style_options_wide_image,
        style_options_top_heading_icon = [],
        cta = [],
        style_options_scroll_anchor,
        style_options_image_is_background,
        form_url,
        style_options_short_header,
        style_options_scroll_arrow
    } = data;

    let background_pattern = themeAssets[style_options_background_pattern];
    let background_image = themeAssets[style_options_background_image];
    let headingIcon = themeAssets[style_options_top_heading_icon[0]];

    return (
        <FormHero
            className={className}
            anchor={anchor}
            top_heading={top_heading}
            main_heading_1={main_heading_1}
            main_heading_2={main_heading_2}
            description={description}
            background_image={background_image}
            background_pattern={background_pattern}
            style_dark_light={style_options_dark}
            headingIcon={headingIcon}
            cta={cta}
            wide={style_options_wide_image}
            scrollto={style_options_scroll_anchor}
            image_is_background={style_options_image_is_background}
            form_url={form_url}
            short_header={style_options_short_header}
            scroll_arrow={style_options_scroll_arrow}
        />
    );
}

export default ACFFormHero;
