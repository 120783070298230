import React, { useState } from 'react';

export const LogoCards = ({ className = '', anchor = '', lists }) => {
    return (
        <>
            <div
                className={`wow fadeInUp md:py-20 py-16 bg-gray-19 ${className}`}
                id={anchor}
            >
                <div className="container">
                    <div className="grid grid-cols-2 sm:gap-16 gap-14 md:grid-cols-4 lg:gap-24">
                        {lists &&
                            lists.map((logo, index) => (
                                <div
                                    className="flex items-center justify-center wow fadeInUp"
                                    data-wow-delay={`${index / 7}s`}
                                    key={index}
                                >
                                    <a href={logo.link} target="_blank">
                                        <img src={logo.logo_image.src} alt="Logo" />
                                    </a>
                                </div>
                            ))}
                    </div>
                </div>
            </div>
        </>
    );
};
export default LogoCards;
