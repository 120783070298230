import React from 'react';
import { extractRepeaterField } from '../../util/blocks';

import { TwoColPartnersNumbers } from '../../components/enfusion/TwoColPartnersNumbers';

function ACFTwoColPartnersNumbers({ meta, data, className, anchor }) {
    const { partners_title, numbers_title, numbers_grid } = data;
    let logoIdList = extractRepeaterField(data, 'partners_logos');
    let numbers = extractRepeaterField(data, 'numbers_grid');

    const logos = logoIdList
        .map(({ logo }) => meta.partners_logos[logo] ?? null)
        .filter(Boolean);
    return (
        <TwoColPartnersNumbers
            className={className}
            anchor={anchor}
            partnersTitle={partners_title}
            partnersLogos={logos}
            numbersGrid={numbers}
            numbersTitleGrid={numbers_title}
        />
    );
}

export default ACFTwoColPartnersNumbers;
