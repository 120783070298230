import React, { useState, useEffect } from 'react';
import NextNavLink from '../../util/NextNavLink';
import ReactPlayer from 'react-player';

export const FeaturesGrid = ({
    className = '',
    anchor = '',
    main_heading,
    top_heading,
    text_area,
    grid
}) => {
    return (
        <div
            className={`py-16 relative wow fadeInUp overflow-hidden ${className} `}
            id={anchor}
        >
            <div className="container relative z-[1]">
                <div className="grid lg:grid-cols-2">
                    <div className="wow fadeInUp">
                        {top_heading && (
                            <p
                                className={`sm:mb-6 mb-4 label font-medium text-purple`}
                                dangerouslySetInnerHTML={{
                                    __html: top_heading
                                }}
                            />
                        )}
                        {main_heading && (
                            <h3
                                className="md:h3 h2 whitespace-pre-line"
                                dangerouslySetInnerHTML={{
                                    __html: main_heading
                                }}
                            />
                        )}
                        {text_area && (
                            <div className="lead opacity-70 mt-4" 
                            dangerouslySetInnerHTML={{ __html: text_area }} />
                        )}
                    </div>
                </div>
            </div>
                {grid &&
                    grid.map((item, index) => (
                        <div className="relative pb-12 lg:pb-16 wow fadeInUp" id={item?.top_heading?.replace(/([~!@#$%^&*()_+=`{}\[\]\|\\:;'<>,.\/? ])+/g, '-').replace(/^(-)+|(-)+$/g,'').toLowerCase()} key={index}>
                            <div className="container ">
                                <div className={ `flex items-center flex-col ${index % 2 !== 0 ? 'md:flex-row-reverse': 'md:flex-row'}`}>
                                    <div className={ `md:w-[40%] relative z-[1] ${index % 2 !== 0 ? 'lg:pl-20 wow fadeInRight': 'lg:pr-20 wow fadeInLeft'}`}>
                                        <i className={`${item?.icon?.class} text-40 text-purple`} />
                                        <h5 className="my-4" dangerouslySetInnerHTML={{ __html:item?.top_heading }} />
                                        <div className="mb-4 lead opacity-70" dangerouslySetInnerHTML={{ __html: item?.description }} />
                                        {item?.cta?.url && 
                                            <NextNavLink href={item?.cta?.url} target={item?.cta?.target} rel="noopener noreferrer">
                                                <a className="inline-flex items-center font-medium text-purple hover:text-purple-midnight"><span dangerouslySetInnerHTML={{ __html: item?.cta.title }}></span> <i className="ml-3 en-long-arrow text-32"></i> </a>
                                            </NextNavLink>
                                        }
                                    </div>
                                    <div className={`md:w-[60%] w-full ${index % 2 !== 0 ? 'wow fadeInRight' : 'wow fadeInLeft'}`}>
                                        <div className="relative z-[1]">
                                            {!item?.video  ?
                                                <>
                                                    {item?.desktop_image?.src &&
                                                        <img src={item?.desktop_image?.src} alt={item?.image_caption} />
                                                    }
                                                    {item?.image_caption &&
                                                        <div className={`flex md:mt-12 mt-8 ${index % 2 !== 0 ? 'justify-end' : 'justify-start'}`}>
                                                            <div className="h-0.5 w-12  border-purple bg-purple mr-4 mt-3"></div>
                                                            <div className="lead md:max-w-[290px] max-w-[200px] md:lead--normal lead--small" dangerouslySetInnerHTML={{ __html: item?.image_caption }} />
                                                        </div>
                                                    }
                                                </>
                                                :
                                                  <ReactPlayer
                                                        width="100%"
                                                        height="100%"
                                                        className="w-full aspect-w-1 aspect-h-1"
                                                        autoplay={true}
                                                        playing={true}
                                                        playsinline={true}
                                                        loop={true}
                                                        muted={true}
                                                        controls={false}
                                                        url={item?.video}
                                                        config={{ vimeo: { playerOptions: { playsinline: 1, muted: true  } } }} 
                                                    //     config={{
                                                    //         file: {
                                                    //             attributes: {
                                                    //             preload: "auto",
                                                    //         },
                                                    //     },
                                                    // }}
                                                />
                                            }
                                        </div>

                                        <div className={ `lg:block hidden absolute w-[40vw] ${index % 2 !== 0 ? '-right-32' : '-left-32'}  top-1/3 h-full bg-no-repeat bg-center transform -translate-y-1/2 max-h-[290px] bg-cover bg-pattern-3`}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
        </div>
    );
};
export default FeaturesGrid;
