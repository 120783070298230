import React, { useState } from 'react';
import StorySlide from './slide';
import Dropdown from './dropdown';

export const NewAudioPodcastsCarousel = ({
    newAudioPodcasts,
    heading,
    hideFilters,
    className = '',
    anchor = ''
}) => {
    const [selectedCategory, setSelectedCategory] = useState('');
    const groupedPodcasts = {};
    const clearSelection = () => {
        setSelectedCategory('');
    };

    function sortPodcastsByCategoryAndPodcastNumber(podcasts) {
        // return podcasts.sort((a, b) => {
        //     // First, sort by category
        //     if (a.category < b.category) return -1;
        //     if (a.category > b.category) return 1;
    
        //     // If categories are equal, sort by podcast number
        //     return parseInt(a.podcast_number) - parseInt(b.podcast_number);
        // });
        return podcasts.sort((a, b) => {
            // Extract the season number from the category string
            const seasonA = parseInt(a.category.split(' ')[1]);
            const seasonB = parseInt(b.category.split(' ')[1]);
    
            // Sort by season number first
            if (seasonA < seasonB) return 1;  // Sort in descending order
            if (seasonA > seasonB) return -1; // Sort in descending order
        
            // If seasons are equal, sort by podcast number
            return parseInt(a.podcast_number) - parseInt(b.podcast_number);
        });
    }

    function groupPodcastsByCategory(sortedPodcasts) {
        sortedPodcasts.forEach(podcast => {
            if (!groupedPodcasts[podcast.category]) {
                groupedPodcasts[podcast.category] = [];
            }
            groupedPodcasts[podcast.category].push(podcast);
        });
        //return groupedPodcasts;
    }

    const parentCategoryWise = (podcasts) => {
        const sortedPodcasts = sortPodcastsByCategoryAndPodcastNumber(podcasts);
        groupPodcastsByCategory(sortedPodcasts);
    }
    parentCategoryWise(newAudioPodcasts);
    return (
        <div
            className={`relative wow fadeInUp overflow-hidden ${className} `}
            id={anchor}
        >
            {/* {hideFilters == 0 ? (
                <div className="bg-gray-stories shadow-stories">
                    <div className="container flex flex-row  py-[18px]">
                        <Dropdown
                            options={groups.subcategories}
                            selected={selectedCategory}
                            onChange={(value) => {
                                if (value === 'all') {
                                    return setSelectedCategory(null);
                                }
                                return setSelectedCategory(value);
                            }}
                        />

                        <button
                            onClick={clearSelection}
                            className="ml-8 text-18 font-normal text-purple"
                        >
                            Clear All
                        </button>
                    </div>
                </div>
            ) : null} */}
            {Array.isArray(newAudioPodcasts) &&
                Object.keys(groupedPodcasts).map((key,i) => (
                    <div className="container relative mt-1 mb-16" key={i}>
                        <h2 className="text-2xl mb-4 flex w-1/2 carouselheading">
                            {key}
                        </h2>
                        {Array.isArray(groupedPodcasts[key]) && groupedPodcasts[key].map((stories, idx) => (
                            <StorySlide key={idx} item={stories} index={idx} />
                        ))}
                    </div>
                ))   
            }
        </div>
    );
};

export default NewAudioPodcastsCarousel;
