import React from 'react';
import { Separator } from '../../components/enfusion/Separator';

function ACFSeparator({ data, className, anchor }) {
    const { size, color } = data;

    return (
        <>
            <Separator
                className={className}
                anchor={anchor}
                size={size}
                color={color}
            />
        </>
    );
}

export default ACFSeparator;
