import React from 'react';
import { extractRepeaterField } from '../../util/blocks';

import { Pagination } from '../../components/enfusion/Pagination';

function ACFPagination({ meta, data = [], className, anchor, pageProps }) {
    const { title } = pageProps;

    return <Pagination className={className} anchor={anchor} title={title} />;
}

export default ACFPagination;
