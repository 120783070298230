import React, { useState, useEffect, useRef } from 'react';
import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton
} from 'react-share';

export const SocialShare = ({ className = '', anchor = '', title, link }) => {
    const [copyState, setCopyState] = useState('Copy');
    const [pageURL, setPageURL] = useState(0);
    useEffect(() => {
        setPageURL(window.location.href);
    });
    function copy(e) {
        e.preventDefault();
        var dummy = document.createElement('input'),
            text = window.location.href;

        document.body.appendChild(dummy);
        dummy.value = text;
        dummy.select();
        document.execCommand('copy');
        document.body.removeChild(dummy);
        setCopyState('Copied!');
        setTimeout(() => {
            setCopyState('Copy');
        }, 2000);
    }
    return (
        <>
            <div
                className={`wow fadeInUp ${
                    className.search('pb') === -1 ? 'md:py-16 py-6' : className
                }`}
                id={anchor}
            >
                <div className="container">
                    <div className="flex items-center">
                        <p className="py-2.5 md:pr-8 md:mr-8 pr-5 mr-5 font-medium border-r label label--small border-purple-midnight border-opacity-20 wow fadeInUp">
                            Share
                        </p>
                        <div className="flex items-center justify-between wow fadeInUp">
                            <div className="flex space-x-6 md:space-x-8">
                                <div className="relative flex items-center group ">
                                    <div className="absolute left-1/2 transform -translate-x-1/2 bg-purple-midnight rounded-[3px] text-12 text-white leading-[12px] p-1 px-2 -top-6 group-hover:opacity-100 opacity-0 transition-all">
                                        {copyState}
                                    </div>
                                    <i
                                        className="transition-all cursor-pointer en-link-bold text-20 text-purple-midnight opacity-40 hover:opacity-100"
                                        onClick={copy}
                                    ></i>
                                </div>

                                <EmailShareButton
                                    className="h-5"
                                    openShareDialogOnClick
                                    onClick={() => {}}
                                    title={title}
                                    url={pageURL}
                                >
                                    <i className="transition-all en-mail text-20 text-purple-midnight opacity-40 hover:opacity-100"></i>
                                </EmailShareButton>
                                <LinkedinShareButton
                                    className="h-5"
                                    title={title}
                                    url={pageURL}
                                    source="Enfusion"
                                >
                                    <i className="transition-all en-linkedin text-20 text-purple-midnight opacity-40 hover:opacity-100"></i>
                                </LinkedinShareButton>
                                <TwitterShareButton
                                    className="h-5"
                                    title={title}
                                    url={pageURL}
                                >
                                    <i className="transition-all en-twitter text-20 text-purple-midnight opacity-40 hover:opacity-100"></i>
                                </TwitterShareButton>
                                <FacebookShareButton
                                    className="h-5"
                                    title={title}
                                    url={pageURL}
                                >
                                    <i className="transition-all en-facebook text-20 text-purple-midnight opacity-40 hover:opacity-100"></i>
                                </FacebookShareButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default SocialShare;
