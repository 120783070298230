import React, { useState } from 'react';

import NextNavLink from '../../util/NextNavLink';
export const QA = ({ className = '', anchor = '', lists }) => {
    return (
        <div className={`my-6 wow fadeInUp ${className}`} id={anchor}>
            {lists &&
                lists.map((item, index) => (
                    <div key={index} className="mb-6">
                        <div
                            key={index}
                            className="flex items-start mb-5 text-purple-midnight h5"
                        >
                            <span class="inline-block mr-5 font-medium lead lead--large mt-1">
                                Q
                            </span>
                            {item.title}
                        </div>
                        <div className="flex items-start">
                            <span class="inline-block mr-6 text-purple font-medium">
                                A
                            </span>
                            <div
                                className="opacity-70 lead lead--large"
                                dangerouslySetInnerHTML={{
                                    __html: item.description
                                }}
                            />
                        </div>
                    </div>
                ))}
        </div>
    );
};
export default QA;
