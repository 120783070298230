import React from "react";

import { ClientQuote } from '../../components/enfusion/ClientQuote'

function ACFClientQuote({ meta, data, className, anchor }) {

    const { client_image } = meta;
    const { business_name, client_name, client_title, quote, region } = data;

    return (
        <ClientQuote
            className={className}
            anchor={anchor}
            client_image={client_image}
            business_name={business_name}
            client_name={client_name}
            client_title={client_title}
            quote={quote}
            region={region}
        />
    );
}

export default ACFClientQuote;