import React, { useState } from 'react';
import NextNavLink from '../../util/NextNavLink';
export const TwoColTextList = ({
    className = '',
    anchor = '',
    top_header,
    main_heading,
    main_content,
    left_cta,
    left_list_title,
    leftlists,
    list_heading,
    list_content,
    right_list_title,
    cta,
    lists
}) => {
    return (
        <div
            className={`lg:py-32 py-16 overflow-hidden bg-gray-19 relative wow fadeInUp ${className}`}
            id={anchor}
        >
            <div className="container relative z-[1]">
                {!main_heading && !main_content ? (
                    <div className="flex justify-center">
                        {/* Center the content */}
                        <div className="max-w-[630px]">
                            {top_header && (
                                <p
                                    className="mb-4 font-medium md:mb-6 label text-purple wow fadeInUp"
                                    dangerouslySetInnerHTML={{
                                        __html: top_header
                                    }}
                                />
                            )}
                            {list_heading && (
                                <h3
                                    className="mt-8 mb-6 md:mt-11 h2 md:h3 wow fadeInUp"
                                    dangerouslySetInnerHTML={{
                                        __html: list_heading
                                    }}
                                />
                            )}
                            {list_content && (
                                <div
                                    className="lead lead--large opacity-70 wow fadeInUp pb-6"
                                    dangerouslySetInnerHTML={{
                                        __html: list_content
                                    }}
                                />
                            )}
                            {cta && cta.url && cta.title && (
                                <NextNavLink
                                    href={cta.url}
                                    target={cta.target ? cta.target : '_self'}
                                >
                                    <a
                                        className={`btn--midnight btn md:btn--md wow fadeInUp my-4`}
                                    >
                                        {cta.title}
                                    </a>
                                </NextNavLink>
                            )}
                            {right_list_title ? (
                                <div
                                    className="lead lead--large font-semibold wow fadeInUp mt-8 mb-5"
                                    dangerouslySetInnerHTML={{
                                        __html: right_list_title
                                    }}
                                />
                            ) : (
                                ' '
                            )}
                            <div className="border-t border-purple-midnight border-opacity-30 pt-6">
                                {lists &&
                                    lists?.map((item, i) => {
                                        if (
                                            item?.link?.title ||
                                            item?.plain_text
                                        ) {
                                            return (
                                                <div
                                                    key={i}
                                                    className={`flex items-center pb-5 mb-5 ${
                                                        item.link ||
                                                        item.plain_text
                                                            ? 'border-b border-purple-midnight border-opacity-30'
                                                            : ''
                                                    } wow fadeInUp last:mb-0`}
                                                >
                                                    {item?.link ? (
                                                        <>
                                                            <NextNavLink
                                                                key={i}
                                                                href={
                                                                    item.link
                                                                        ?.url ??
                                                                    '#'
                                                                }
                                                                target={
                                                                    item.link
                                                                        .target
                                                                        ? item
                                                                              .link
                                                                              .target
                                                                        : '_self'
                                                                }
                                                            >
                                                                <a
                                                                    data-wow-delay={`${
                                                                        i / 7
                                                                    }s`}
                                                                    className="flex items-center wow fadeInUp border-purple-midnight border-opacity-30 hover:text-purple text-purple-midnight"
                                                                >
                                                                    <i
                                                                        className={`w-3 h-3 mr-4 md:mr-12 border-2 ${
                                                                            item.link ||
                                                                            item.plain_text
                                                                                ? 'border-purple rounded-10'
                                                                                : ''
                                                                        }`}
                                                                    ></i>
                                                                    <p
                                                                        className="flex flex-1 pr-6 lead md:lead--large"
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: item
                                                                                ?.link
                                                                                ?.title
                                                                                ? item
                                                                                      .link
                                                                                      .title
                                                                                : item
                                                                                      .link
                                                                                      .url
                                                                        }}
                                                                    />
                                                                    <i
                                                                        className={`${
                                                                            item.link ||
                                                                            item.plain_text
                                                                                ? 'en-long-arrow'
                                                                                : ''
                                                                        } md:text-26 text-20 text-purple-midnight`}
                                                                    ></i>
                                                                </a>
                                                            </NextNavLink>
                                                        </>
                                                    ) : (
                                                        <>
                                                            {item.plain_text && (
                                                                <div className="flex items-center wow fadeInUp border-purple-midnight border-opacity-30 text-purple-midnight">
                                                                    <i
                                                                        className={`w-3 h-3 mr-4 md:mr-12 border-2 ${
                                                                            item.link ||
                                                                            item.plain_text
                                                                                ? 'border-purple rounded-10'
                                                                                : ''
                                                                        }`}
                                                                    ></i>
                                                                    <p
                                                                        className="flex flex-1 pr-6 lead md:lead--large"
                                                                        dangerouslySetInnerHTML={{
                                                                            __html:
                                                                                item?.plain_text
                                                                        }}
                                                                    />
                                                                    <div
                                                                        className={`md:text-26 text-20`}
                                                                    ></div>
                                                                </div>
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                            );
                                        }
                                    })}
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="grid lg:grid-cols-2 gap-4">
                        <div className="md:pr-20">
                            {top_header && (
                                <p
                                    className="mb-4 font-medium md:mb-6 label text-purple wow fadeInUp"
                                    dangerouslySetInnerHTML={{
                                        __html: top_header
                                    }}
                                />
                            )}
                            {main_heading && (
                                <h3
                                    className="mb-4 md:mb-6 h2 md:h3 wow fadeInUp"
                                    dangerouslySetInnerHTML={{
                                        __html: main_heading
                                    }}
                                />
                            )}
                            {main_content && (
                                <div
                                    className="lead lead--large opacity-70 wow fadeInUp pb-6"
                                    dangerouslySetInnerHTML={{
                                        __html: main_content
                                    }}
                                />
                            )}
                            {left_cta && left_cta.url && left_cta.title && (
                                <NextNavLink
                                    href={left_cta.url}
                                    target={
                                        left_cta.target
                                            ? left_cta.target
                                            : '_self'
                                    }
                                >
                                    <a
                                        className={`btn--midnight btn md:btn--md wow fadeInUp my-4`}
                                    >
                                        {left_cta.title}
                                    </a>
                                </NextNavLink>
                            )}
                            {left_list_title && (
                                <div
                                    className="lead lead--large font-semibold wow fadeInUp mt-8 mb-5"
                                    dangerouslySetInnerHTML={{
                                        __html: left_list_title
                                    }}
                                />
                            )}

                            <div className="border-t border-purple-midnight border-opacity-30 pt-6">
                                {leftlists &&
                                    leftlists?.map((item, i) => {
                                        if (
                                            item?.left_list_link?.title ||
                                            item.left_plain_text
                                        ) {
                                            return (
                                                <div
                                                    key={i}
                                                    className={`flex items-center pb-5 mb-5 ${
                                                        item.left_list_link ||
                                                        item.left_plain_text
                                                            ? 'border-b border-purple-midnight border-opacity-30'
                                                            : ''
                                                    } wow fadeInUp last:mb-0 border-purple-midnight border-opacity-30  text-purple-midnight`}
                                                >
                                                    {item?.left_list_link ? (
                                                        <>
                                                            <NextNavLink
                                                                key={i}
                                                                href={
                                                                    item
                                                                        .left_list_link
                                                                        ?.url ??
                                                                    '#'
                                                                }
                                                                target={
                                                                    item
                                                                        .left_list_link
                                                                        .target
                                                                        ? item
                                                                              .left_list_link
                                                                              .target
                                                                        : '_self'
                                                                }
                                                            >
                                                                <a
                                                                    data-wow-delay={`${
                                                                        i / 7
                                                                    }s`}
                                                                    className="flex items-center wow fadeInUp border-purple-midnight border-opacity-30 hover:text-purple text-purple-midnight"
                                                                >
                                                                    <i
                                                                        className={`w-3 h-3 mr-4 md:mr-12 border-2 ${
                                                                            item.left_list_link ||
                                                                            item.left_plain_text
                                                                                ? 'border-purple rounded-10'
                                                                                : ''
                                                                        }`}
                                                                    ></i>
                                                                    <p
                                                                        className="flex flex-1 pr-6 lead md:lead--large"
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: item
                                                                                ?.left_list_link
                                                                                ?.title
                                                                                ? item
                                                                                      .left_list_link
                                                                                      .title
                                                                                : item
                                                                                      .left_list_link
                                                                                      .url
                                                                        }}
                                                                    />
                                                                    <i
                                                                        className={`${
                                                                            item.left_list_link ||
                                                                            item.left_plain_text
                                                                                ? 'en-long-arrow'
                                                                                : ''
                                                                        } md:text-26 text-20 text-purple-midnight`}
                                                                    ></i>
                                                                </a>
                                                            </NextNavLink>
                                                        </>
                                                    ) : (
                                                        <>
                                                            {item.left_plain_text && (
                                                                <div className="flex items-center wow fadeInUp border-purple-midnight border-opacity-30 text-purple-midnight">
                                                                    <i
                                                                        className={`w-3 h-3 mr-4 md:mr-12 border-2 ${
                                                                            item.left_list_link ||
                                                                            item.left_plain_text
                                                                                ? 'border-purple rounded-10'
                                                                                : ''
                                                                        }`}
                                                                    ></i>
                                                                    <p
                                                                        className="flex flex-1 pr-6 lead md:lead--large"
                                                                        dangerouslySetInnerHTML={{
                                                                            __html:
                                                                                item?.left_plain_text
                                                                        }}
                                                                    />
                                                                    <div
                                                                        className={`md:text-26 text-20`}
                                                                    ></div>
                                                                </div>
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                            );
                                        }
                                    })}
                            </div>
                        </div>
                        {list_heading ||
                        list_content ||
                        cta ||
                        right_list_title ||
                        lists.length ? (
                            <div className="md:pr-20">
                                {list_heading && (
                                    <h3
                                        className={`mb-6 ${
                                            !top_header
                                                ? 'mt-0'
                                                : 'md:mt-[2.875rem]'
                                        } h2 md:h3 wow fadeInUp`}
                                        dangerouslySetInnerHTML={{
                                            __html: list_heading
                                        }}
                                    />
                                )}
                                {list_content && (
                                    <div
                                        className="lead lead--large opacity-70 wow fadeInUp pb-6"
                                        dangerouslySetInnerHTML={{
                                            __html: list_content
                                        }}
                                    />
                                )}
                                {cta && cta.url && cta.title && (
                                    <NextNavLink
                                        href={cta.url}
                                        target={
                                            cta.target ? cta.target : '_self'
                                        }
                                    >
                                        <a
                                            className={`btn--midnight btn md:btn--md wow fadeInUp my-4`}
                                        >
                                            {cta.title}
                                        </a>
                                    </NextNavLink>
                                )}
                                {right_list_title && (
                                    <div
                                        className={`lead lead--large font-semibold wow fadeInUp ${
                                            !list_heading && list_content
                                                ? 'mt-0'
                                                : !list_heading && !list_content
                                                ? 'mt-14'
                                                : 'mt-8'
                                        } mb-5`}
                                        dangerouslySetInnerHTML={{
                                            __html: right_list_title
                                        }}
                                    />
                                )}
                                {lists.length ? (
                                    <div className="border-t border-purple-midnight border-opacity-30 pt-6">
                                        {lists &&
                                            lists?.map((item, i) => {
                                                if (
                                                    item?.link?.title ||
                                                    item?.plain_text
                                                ) {
                                                    return (
                                                        <div
                                                            key={i}
                                                            className={`flex items-center pb-5 mb-5 ${
                                                                item.link ||
                                                                item.plain_text
                                                                    ? 'border-b border-purple-midnight border-opacity-30'
                                                                    : ''
                                                            } wow fadeInUp last:mb-0`}
                                                        >
                                                            {item?.link ? (
                                                                <>
                                                                    <NextNavLink
                                                                        key={i}
                                                                        href={
                                                                            item
                                                                                .link
                                                                                ?.url ??
                                                                            '#'
                                                                        }
                                                                        target={
                                                                            item
                                                                                .link
                                                                                .target
                                                                                ? item
                                                                                      .link
                                                                                      .target
                                                                                : '_self'
                                                                        }
                                                                    >
                                                                        <a
                                                                            data-wow-delay={`${
                                                                                i /
                                                                                7
                                                                            }s`}
                                                                            className="flex items-center wow fadeInUp border-purple-midnight border-opacity-30 hover:text-purple text-purple-midnight"
                                                                        >
                                                                            <i
                                                                                className={`w-3 h-3 mr-4 md:mr-12 border-2 ${
                                                                                    item.link ||
                                                                                    item.plain_text
                                                                                        ? 'border-purple rounded-10'
                                                                                        : ''
                                                                                }`}
                                                                            ></i>
                                                                            <p
                                                                                className="flex flex-1 pr-6 lead md:lead--large"
                                                                                dangerouslySetInnerHTML={{
                                                                                    __html: item
                                                                                        ?.link
                                                                                        ?.title
                                                                                        ? item
                                                                                              .link
                                                                                              .title
                                                                                        : item
                                                                                              .link
                                                                                              .url
                                                                                }}
                                                                            />
                                                                            <i
                                                                                className={`${
                                                                                    item.link ||
                                                                                    item.plain_text
                                                                                        ? 'en-long-arrow'
                                                                                        : ''
                                                                                } md:text-26 text-20 text-purple-midnight`}
                                                                            ></i>
                                                                        </a>
                                                                    </NextNavLink>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {item.plain_text && (
                                                                        <div className="flex items-center wow fadeInUp border-purple-midnight border-opacity-30 text-purple-midnight">
                                                                            <i
                                                                                className={`w-3 h-3 mr-4 md:mr-12 border-2 ${
                                                                                    item.link ||
                                                                                    item.plain_text
                                                                                        ? 'border-purple rounded-10'
                                                                                        : ''
                                                                                }`}
                                                                            ></i>
                                                                            <p
                                                                                className="flex flex-1 pr-6 lead md:lead--large"
                                                                                dangerouslySetInnerHTML={{
                                                                                    __html:
                                                                                        item?.plain_text
                                                                                }}
                                                                            />
                                                                            <div
                                                                                className={`md:text-26 text-20`}
                                                                            ></div>
                                                                        </div>
                                                                    )}
                                                                </>
                                                            )}
                                                        </div>
                                                    );
                                                }
                                            })}
                                    </div>
                                ) : null}
                            </div>
                        ) : null}
                    </div>
                )}
            </div>
            <div
                className={`bg-pattern-4 max-w-[630px] w-full h-full max-h-[290px] bg-no-repeat -bottom-2 left-0 absolute z-0 bg-right lg:block hidden`}
            ></div>
        </div>
    );
};
export default TwoColTextList;
