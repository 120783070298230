import React from 'react';

import { SocialShare } from '../../components/enfusion/SocialShare';

function ACSocialShare({ className, anchor, pageProps }) {
    const { link } = pageProps;
    return <SocialShare className={className} anchor={anchor} link={link} />;
}

export default ACSocialShare;
