import React, { useState, useEffect, useRef } from 'react';
import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton
} from 'react-share';

export const SocialFollow = ({ className = '', anchor = '', title, link }) => {
    const [copyState, setCopyState] = useState('Copy');
    const [pageURL, setPageURL] = useState(0);
    useEffect(() => {
        setPageURL(window.location.href);
    });
    function copy(e) {
        e.preventDefault();
        var dummy = document.createElement('input'),
            text = window.location.href;

        document.body.appendChild(dummy);
        dummy.value = text;
        dummy.select();
        document.execCommand('copy');
        document.body.removeChild(dummy);
        setCopyState('Copied!');
        setTimeout(() => {
            setCopyState('Copy');
        }, 2000);
    }
    return (
        <>
            <div
                className={`wow fadeInUp ${
                    className.search('pb') === -1 ? 'md:py-20 py-6' : className
                }`}
                id={anchor}
            >
                <div className="container">
                    <div className="flex items-center">
                    <p className="py-2.5 md:pr-8 md:mr-8 pr-5 mr-5 font-medium border-r label label--small border-purple-midnight border-opacity-20 wow fadeInUp" style={{ fontSize: '18px', fontWeight: '500', textTransform:'inherit', color: 'black', letterSpacing: 'normal' }}>
                    Follow Life @ Enfusion on:
                    </p>



                        <div className="flex items-center justify-between wow fadeInUp">
                            <div className="flex space-x-6 md:space-x-8">
                                {/* <div className="relative flex items-center group ">
                                    <div className="absolute left-1/2 transform -translate-x-1/2 bg-purple-midnight rounded-[3px] text-12 text-white leading-[12px] p-1 px-2 -top-6 group-hover:opacity-100 opacity-0 transition-all">
                                        {copyState}
                                    </div>
                                    <i
                                        className="transition-all cursor-pointer en-link-bold text-20 text-purple-midnight opacity-40 hover:opacity-100"
                                        onClick={copy}
                                    ></i>
                                </div> */}

                                {/* <EmailShareButton
                                    className="h-5"
                                    openShareDialogOnClick
                                    onClick={() => {}}
                                    title={title}
                                    url={pageURL}
                                >
                                    <i className="transition-all en-mail text-20 text-purple-midnight opacity-40 hover:opacity-100"></i>
                                </EmailShareButton> */}
                                <a href='https://www.glassdoor.com/Overview/Working-at-Enfusion-EI_IE290123.11,19.htm' target='_blank' className='px-2'>

      <svg width="26" height="36" viewBox="0 0 26 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M20.4987 30.5029H0.497089C0.497089 33.2644 2.73272 35.5 5.49422 35.5H20.4926C23.2541 35.5 25.4897 33.2644 25.4897 30.5029V9.9675C25.4897 9.86513 25.4075 9.78287 25.3051 9.78287H20.6702C20.5678 9.78287 20.4856 9.86513 20.4856 9.9675V30.5029H20.4996H20.4987ZM20.4987 0.5C23.2602 0.5 25.4958 2.73562 25.4958 5.49712H5.50034V26.0325C5.50034 26.1349 5.41809 26.2171 5.31571 26.2171H0.68084C0.578465 26.2171 0.496216 26.1349 0.496216 26.0325V5.49712C0.496216 2.73562 2.73184 0.5 5.49334 0.5H20.4978H20.4987Z" fill="black"/>
</svg>    
</a>

<a href='https://www.linkedin.com/company/451825/' target='_blank' className='px-2'>

<svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M30.3592 0.00348761H2.82588C2.47147 -0.0132387 2.11601 0.0400764 1.78251 0.161342C1.44796 0.281563 1.14164 0.467643 0.879233 0.707039C0.616822 0.946434 0.404594 1.23496 0.253002 1.55695C0.10141 1.87788 0.0156819 2.226 0 2.58143V30.1746C0.0209092 30.9169 0.325139 31.6236 0.851006 32.1494C1.37687 32.6752 2.08361 32.9794 2.82588 33.0004H30.3592C31.0816 32.9679 31.7622 32.6543 32.2556 32.1264C32.7491 31.5985 33.0157 30.8981 32.999 30.1757V2.58143C33.0052 2.23645 32.9404 1.89252 32.8087 1.57367C32.677 1.25378 32.4825 0.965251 32.2347 0.723765C31.988 0.482279 31.6942 0.294108 31.3712 0.170751C31.0481 0.0473942 30.7042 -0.00905713 30.3592 0.00453301V0.00348761ZM10.3124 27.5141H5.50749V12.8106H10.3124V27.5152V27.5141ZM8.02287 10.5829C7.68728 10.5944 7.35378 10.5369 7.04118 10.4135C6.72964 10.2902 6.44632 10.1031 6.21004 9.8647C5.97377 9.62635 5.78977 9.342 5.66849 9.02943C5.54722 8.71686 5.49286 8.38233 5.50645 8.04676C5.49181 7.70805 5.54827 7.36934 5.67163 7.05363C5.79499 6.73792 5.98318 6.45149 6.22468 6.21209C6.46514 5.97374 6.75368 5.78766 7.07046 5.66639C7.38723 5.54513 7.72596 5.49181 8.06469 5.50959C8.40028 5.49809 8.73379 5.55558 9.04638 5.67894C9.35793 5.8023 9.64125 5.98942 9.87752 6.22777C10.1138 6.46612 10.2978 6.75047 10.4191 7.06304C10.5403 7.37562 10.5947 7.71014 10.5811 8.04571C10.5958 8.38442 10.5393 8.72313 10.4159 9.03884C10.2926 9.35455 10.1044 9.64099 9.86289 9.88038C9.62243 10.1187 9.33388 10.3048 9.01711 10.4261C8.70033 10.5473 8.3616 10.6007 8.02287 10.5829ZM27.5124 27.5141H22.6865V19.4708C22.6865 17.5525 22.0059 16.2333 20.2945 16.2333C19.7624 16.2374 19.2449 16.4068 18.8131 16.7173C18.3813 17.0278 18.0551 17.4637 17.8816 17.9655C17.7478 18.3418 17.6913 18.7422 17.7164 19.1405V27.5131H12.9731V12.8106H17.7164V14.8732C18.1377 14.0986 18.766 13.4556 19.5303 13.0145C20.2945 12.5744 21.1654 12.3538 22.0477 12.3778C25.1622 12.3778 27.5134 14.4404 27.5134 18.8331V27.5152L27.5124 27.5141Z" fill="black"/>
</svg>
</a>

<a href='https://x.com/enfusion' target='_blank' className='px-2'>
<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M19.0475 13.5514L30.9621 0H28.1397L17.7899 11.7641L9.5296 0H0L12.4939 17.791L0 32H2.8224L13.7451 19.5741L22.4704 32H32M3.84107 2.08253H8.17707L28.1376 30.0198H23.8005" fill="black"/>
</svg>
</a>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default SocialFollow;
