import React, { useState, useEffect } from 'react';
import NextNavLink from '../../util/NextNavLink';
import SwiperCore, { Pagination, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
SwiperCore.use([Pagination, Navigation]);
import ReactPlayer from 'react-player';

export const TwoColCarousel = ({
    className = '',
    anchor = '',
    pattern,
    grid
}) => { 
    return (
        <div
            className={`relative wow fadeInUp overflow-hidden py-12 lg:py-16  ${className} `}
            id={anchor}
        >
            <div className="container relative z-[1]">
                <Swiper
                    spaceBetween={0}
                    slidesPerView={1}
                    loop={true}
                    navigation={{
                        prevEl: '.employees-prev',
                        nextEl: '.employees-next'
                    }}
                    pagination={{
                        clickable: true,
                        el: '.employees-custom-pagination'
                    }}
                >
                    {grid &&
                        grid.map((item, index) => (
                            <SwiperSlide key={index}>
                                <div
                                    className={`grid md:grid-cols-2 gap-12 flex-col`}
                                >
                                    <div className="lg:pr-20">
                                        {item?.top_heading && (
                                            <p
                                                className="mb-4 font-medium md:mb-6 text-purple label"
                                                dangerouslySetInnerHTML={{
                                                    __html: item?.top_heading
                                                }}
                                            />
                                        )}
                                        {item?.main_heading && (
                                            <h3
                                                className="mb-4 md:mb-6 md:h3 h2"
                                                dangerouslySetInnerHTML={{
                                                    __html: item?.main_heading
                                                }}
                                            />
                                        )}
                                        <div
                                            className="lead opacity-70 lead--large"
                                            dangerouslySetInnerHTML={{
                                                __html: item?.description
                                            }}
                                        />
                                    </div>
                                    <div className="">
                                        {item?.video &&
                                             <ReactPlayer
                                                width="100%"
                                                height="100%"
                                                className="w-full aspect-w-1 aspect-h-1"
                                                autoplay={true}
                                                playing={true}
                                                playsinline={true}
                                                loop={true}
                                                muted={true}
                                                controls={false} 
                                                url={item?.video}
                                                config={{ vimeo: { playerOptions: { playsinline: 1, muted: true  } } }} 
                                                // config={{
                                                //     file: {
                                                //         attributes: {
                                                //         preload: "auto",
                                                //     },
                                                // },
                                                // }}
                                            />
                                        }
                                        
                                        {item?.image?.src &&
                                            <img
                                                src={item?.image?.src}
                                                alt={item?.image_caption || item?.main_heading}
                                            />
                                        }
                                        {item?.image_caption && (
                                            <div
                                                className={`flex md:mt-12 mt-8 justify-end`}
                                            >
                                                <div className="h-0.5 w-12  border-purple bg-purple mr-4 mt-3"></div>
                                                <div
                                                    className="lead md:max-w-[290px] max-w-[200px] md:lead--normal lead--small"
                                                    dangerouslySetInnerHTML={{
                                                        __html:
                                                            item?.image_caption
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                </Swiper>{' '}
                <div className="flex items-center justify-between mt-10 md:mt-24">
                    <div className="cursor-pointer employees-prev">
                        <i className="inline-block transform rotate-180 en-long-arrow text-32 text-purple-midnight"></i>
                    </div>
                    <div className="employees-custom-pagination"></div>
                    <div className="cursor-pointer employees-next">
                        <i className="en-long-arrow text-32 text-purple-midnight"></i>
                    </div>
                </div>
            </div>
            <div className={ `lg:block hidden absolute w-[40vw] -right-32 top-1/3 h-full bg-no-repeat bg-center transform -translate-y-1/2 max-h-[290px] bg-cover  ${pattern?.class}`}></div>
        </div>
    );
};
export default TwoColCarousel;
