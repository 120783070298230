import React, { useState, useEffect } from 'react';

import SwiperCore, { Pagination, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
SwiperCore.use([Pagination, Navigation]);

export const StackLayout = ({
    className = '',
    anchor = '',
    top_heading,
    main_heading,
    items = []
}) => {
    return (
        <div
            className={`relative md:py-16 py-12 wow fadeInUp  overflow-hidden text-purple-midnight ${className} `}
            id={anchor}
        >
            <div className="container relative z-[1]">
                {top_heading && (
                    <p
                        className="mb-4 font-medium md:mb-6 label text-purple wow fadeInUp"
                        dangerouslySetInnerHTML={{
                            __html: top_heading
                        }}
                    />
                )}
                {main_heading && (
                    <h3
                        className="mb-12 lg:mb-16 md:h3 h2 wow fadeInUp"
                        dangerouslySetInnerHTML={{
                            __html: main_heading
                        }}
                    />
                )}
                <div className="space-y-12 md:space-y-16">
                    {items &&
                        items.map((item, index) => (
                            <div
                                key={index}
                                className="w-full pb-12 border-b md:pb-16 border-purple-midnight border-opacity-20"
                            >
                                <div className="pt-6 mb-2 h1 text-[72px] text-purple leading-28">
                                    “
                                </div>
                                {item?.content && (
                                    <div
                                        className="h4"
                                        dangerouslySetInnerHTML={{
                                            __html: item?.content
                                        }}
                                    ></div>
                                )}
                                <div className="mt-9 md:mt-14">
                                    <div className="flex items-center space-x-8">
                                        <div
                                            className="md:w-32 md:h-32 h-[88px] w-[88px] rounded-[100px] flex items-center justify-center p-8 text-center bg-cover bg-center bg-gray-17"
                                            style={
                                                item?.image?.medium_url && {
                                                    backgroundImage: `url(${
                                                        item?.image
                                                            ?.medium_url ||
                                                        item?.image?.src
                                                    })`
                                                }
                                            }
                                        >
                                            {!item?.image?.medium_url ||
                                            !item?.image?.src ? (
                                                <p className="text-12">
                                                    Image not found
                                                </p>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                        <div>
                                            {item?.name && (
                                                <h5 className="mb-1 md:mb-2 md:h5 lead">
                                                    {item?.name}
                                                </h5>
                                            )}
                                            {item?.position && (
                                                <p
                                                    className="mb-2 lead md:lead-normal lead--small text-purple"
                                                    dangerouslySetInnerHTML={{
                                                        __html: item?.position
                                                    }}
                                                ></p>
                                            )}
                                            {item?.location && (
                                                <p className="opacity-50 label md:label--small label--xs">
                                                    {item?.location}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
};
export default StackLayout;
