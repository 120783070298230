import React from 'react';
import ReactPlayer from 'react-player';

function CoreButton({ url, type, anchor = '', className = '' }) {
    return (
        <>
            <div className={`container  wow fadeInUp ${className}`} id={anchor}>
                {url && (
                    <ReactPlayer
                        className="aspect-h-9 aspect-w-16 video-player"
                        url={url}
                        controls={true}
                    />
                )}
            </div>
        </>
    );
}

export default CoreButton;
