import React from 'react';
import { extractRepeaterField } from '../../util/blocks';

import { InsightSource } from '../../components/enfusion/InsightSource';

function ACFInsightSource({ meta, data = [], className, anchor }) {
    const {
        author_name,
        author_link,
        company,
        source_name,
        author_logo_image,
        source_logo_image
    } = data;

    let author_logo = meta?.images[author_logo_image];
    let source_logo = meta?.images[source_logo_image];

    return (
        <InsightSource
            className={className}
            anchor={anchor}
            company={company}
            source_name={source_name}
            author_link={author_link}
            author_name={author_name}
            author_logo={author_logo}
            source_logo={source_logo}
        />
    );
}

export default ACFInsightSource;
