import React from 'react';
import { extractRepeaterField } from '../../util/blocks';

import { TwoColAccordianList } from '../../components/enfusion/TwoColAccordianList';

function ACFTwoColAccordianList({ meta, data, className, anchor, pageProps }) {
    const { themeAssets } = pageProps;

    const { main_heading, top_heading, description } = data;
    let lists = extractRepeaterField(data, 'accordion_list');

    lists = lists.map((repeaterItem) => ({
        ...repeaterItem,
        image_icon: themeAssets[repeaterItem.image_icon] || {}
    }));
    return (
        <TwoColAccordianList
            className={className}
            anchor={anchor}
            lists={lists}
            main_heading={main_heading}
            top_heading={top_heading}
            description={description}
        />
    );
}

export default ACFTwoColAccordianList;
