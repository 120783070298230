import React, { useState } from 'react';
import NextNavLink from '../../util/NextNavLink';
import Breadcrumb from '../Header/Breadcrumb';

export const ClientLinks = ({ className = '', anchor = '', lists }) => {
    return (
        <div className={`pb-10 ${className}`} id={anchor}>
            <div className="container relative grid grid-cols-1 gap-8 lg:grid-cols-3 sm:grid-cols-2">
                {lists.map((item, index) => (
                    <div key={index} className="flex-col md:flex md:p-8 p-7 bg-gray-19 wow fadeInUp">
                        <div className="flex-col flex-1 md:flex">
                            <i
                                className={`${item.icon.class} text-[36px] md:mb-7 mb-5 inline-block`}
                            />
                            {item?.top_heading && (
                                <p className="mb-4 font-medium md:mb-6 label text-purple">
                                    {item?.top_heading}
                                </p>
                            )}
                            {item?.main_heading && <h4 className="mb-4 md:mb-6">{item?.main_heading}</h4>}
                            {item?.cta_links.length > 0 &&
                            <div className="flex flex-col space-y-4 md:space-y-6">
                                {item?.cta_links.map(
                                        (item, index) => 
                                        <NextNavLink target={item.link.target} key={index} href={item.link.url || '#'} rel="noopener noreferrer">
                                            <a
                                                
                                                className="inline-flex items-center lead lead--large text-purple-midnight hover:text-purple"
                                            >
                                                <i className={`${item?.icon?.class} text-purple mr-4`} />
                                                {item.link.title}
                                            </a>
                                        </NextNavLink>
                                    )}
                                    
                            </div>}
                        </div>
                        <div className="md:flex flex-0">
                            {item.cta && <NextNavLink target={item.cta.target} href={item.cta.url} rel="noopener noreferrer"><a  className="w-full btn btn--primary md:btn--lg">{item.cta.title}</a></NextNavLink>}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};
export default ClientLinks;
