import React, { useState } from 'react';
import Breadcrumb from '../Header/Breadcrumb';

import { useAppContext } from '../../../util/context';

export const PageHero = ({
    className = '',
    anchor = '',
    darkLight,
    content,
    title,
    breadcrumbs,
    isBottom = false,
    noPadding = false,
    bid
}) => {
    const context = useAppContext();

    if (bid === 0) {
        context.toggleDarkMode(darkLight !== '1' ? false : true);
        context.toggleHeaderTheme('');
    }
    const styles = {
        container: 'items-center md:space-x-10 md:flex wow fadeInUp',
        content: 'flex-1 mt-5 lead lead--large opacity-70 md:mt-4',
        title: 'flex-1 md:flex lg:max-w-4xl'
    };

    if (isBottom) {
        styles.container = 'flex flex-col wow fadeInUp items-start';
        styles.content =
            'mt-5 md:flex flex-col lead lead--large opacity-70 md:mt-4 two-col--description';
        styles.title = 'flex-1 md:flex lg:max-w-4xl';
    }
    const padding = noPadding
        ? 'md:pt-36 pt-28  pb-0'
        : `md:pt-36  pb-10 pt-28 ${
              className.search('md:pb') === -1 ? 'md:pb-32' : ''
          }`;

    return (
        <div className={`${padding} ${className}`} id={anchor}>
            <div className="container relative">
                <div className="flex items-center mb-5 md:mb-8 wow fadeInUp">
                    {bid === 0 && (
                        <i className="inline-block w-2.5 min-w-[0.66rem] h-2.5 mr-3 border-2 rounded-10 border-purple"></i>
                    )}
                    <h2
                        className="font-medium label"
                        dangerouslySetInnerHTML={{ __html: breadcrumbs }}
                    />
                </div>
                <div className={styles.container}>
                    <div className={styles.title}>
                        {title && (
                            <h1
                                className="md:h2 h1"
                                dangerouslySetInnerHTML={{ __html: title }}
                            />
                        )}
                    </div>
                    {content && (
                        <div
                            className={styles.content}
                            dangerouslySetInnerHTML={{ __html: content }}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};
export default PageHero;
