import React from 'react';
import { extractRepeaterField } from '../../util/blocks';

import { TextHeading } from '../../components/enfusion/TextHeading';

function ACFTextHeading({ meta, data = [], className, anchor, pageProps }) {
    const { themeAssets } = pageProps;

    const {
        top_heading,
        main_heading,
        description,
        style_options_flip_block
    } = data;
    return (
        <TextHeading
            className={className}
            anchor={anchor}
            flip={style_options_flip_block}
            main_heading={main_heading}
            top_heading={top_heading}
            description={description}
        />
    );
}

export default ACFTextHeading;
