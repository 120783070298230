import React, { useState } from 'react';
import NextNavLink from '../../util/NextNavLink';

export const TwoColContact = ({
    className = '',
    anchor = '',
    lists,
    main_heading,
    top_heading,
    icons,
    description
}) => {
    return (
        <div className={`lg:pt-14 pt-8 pb-10 ${className}`} id={anchor}>
            <div className="container relative grid grid-cols-1 gap-8 md:grid-cols-2">
                <div className="pt-5 border-t border-purple-midnight border-opacity-20 md:pt-7">
                    {icons && icons.map((icon, index) =>
                      <i key={index} className={`${icon?.class} md:text-[40px] text-32 mb-4 md:mb-6 inline-block mr-8`}/>    
                    )}
                  
                    {top_heading && 
                        <p className="mb-4 font-medium md:mb-6 label text-purple"  dangerouslySetInnerHTML={{ __html: top_heading }} />
                    }
                    {main_heading && 
                        <h3 className="h2 md:h3" dangerouslySetInnerHTML={{ __html: main_heading }} />
                    }
                    {description && 
                        <p className="mt-4" dangerouslySetInnerHTML={{ __html: description }} />
                    }
                </div>

                 <div className="pt-5 border-t md:pt-6 border-purple-midnight border-opacity-20">
                    {lists && lists.map((item, index) =>
                        <div key={index} className="pb-4 mb-4 border-b md:pb-6 md:mb-6 last:border-0 border-opacity-20 border-purple-midnight">
                            {item?.title && <p className="mb-2 font-medium label text-purple">{item.title}</p>}
                            {item?.description &&  <div className="text-purple-midnight inslide-links-purple-midnight lead lead--large" dangerouslySetInnerHTML={{__html:item?.description}} />}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
export default TwoColContact;
