import React from 'react';

import { OpenPositions } from '../../components/enfusion/OpenPositions';

function ACFOpenPositions({ className, anchor, pageProps }) {
    const { defaults } = pageProps;
    return (
        <OpenPositions
            className={className}
            anchor={anchor}
        />
    );
}

export default ACFOpenPositions;
