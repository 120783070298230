import React, { useState } from 'react';

import NextNavLink from '../../util/NextNavLink';

import SwiperCore, { Navigation, EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
SwiperCore.use([Navigation, EffectFade]);

import { Transition } from '@headlessui/react';
import { getAbsoluteUrl } from '../../../lib/helpers';

export const OurLeaders = ({
    className = '',
    anchor = '',
    top_heading,
    main_heading,
    has_linkedin_links,
    popup,
    lists
}) => {
    const [modalState, setModalState] = useState({
        open: false,
        index: ''
    });
    let lastRow = lists.length % 3;

    return (
        <>
            <div
                className={`overflow-hidden bg-gray-19 relative wow fadeInUp md:py-28 py-16 ${className}`}
                id={anchor}
            >
                <div className="container">
                    {top_heading && (
                        <p className="mb-4 font-medium md:mb-6 label text-purple wow fadeInUp">
                            {top_heading}
                        </p>
                    )}

                    {main_heading && (
                        <h3 className="mb-12 md:h3 h2 md:mb-28 wow fadeInUp">
                            {main_heading}
                        </h3>
                    )}
                    <div className="grid grid-cols-12 gap-8 pt-3">
                        {lists &&
                            lists.map((item, index) => (
                                <>
                                    {index + 1 === lists.length &&
                                    lastRow === 1 ? (
                                        <div className="hidden lg:block lg:col-span-4"></div>
                                    ) : (
                                        ''
                                    )}
                                    {index === lists.length - 2 &&
                                    lastRow === 2 ? (
                                        <div className="hidden lg:block lg:col-span-2"></div>
                                    ) : (
                                        ''
                                    )}

                                    <a
                                        href=""
                                        onClick={(e) => {
                                            e.preventDefault();
                                            if (popup === '1') {
                                                setModalState({
                                                    open: true,
                                                    index: index
                                                });
                                            } else {
                                                setModalState({
                                                    open: false,
                                                    index: ''
                                                });
                                            }
                                        }}
                                        className={`${
                                            popup === '1'
                                                ? 'cursor-pointer hover:text-purple'
                                                : 'cursor-default hover:text-purple-midnight'
                                        }  block lg:mb-4 col-span-6 text-center border-b lg:col-span-4 border-purple-midnight text-purple-midnight  border-opacity-20 group wow fadeInUp`}
                                        key={index}
                                    >
                                        <div
                                            className="md:w-40 md:h-40 w-28 h-28 rounded-[100px] mx-auto bg-cover bg-center md:mb-6 mb-5"
                                            style={{
                                                backgroundImage: `url(${item.leader?.image?.src})`
                                            }}
                                        />

                                        <h5
                                            className="mb-2"
                                            dangerouslySetInnerHTML={{
                                                __html: item?.leader?.name
                                            }}
                                        />
                                        <p
                                            className="mb-1 lead text-purple"
                                            dangerouslySetInnerHTML={{
                                                __html: item?.leader?.title
                                            }}
                                        />
                                        {item?.leader?.email ? (
                                            <object className="">
                                                <a
                                                    href={`mailto:${item?.leader?.email}`}
                                                    target="_blank"
                                                >
                                                    {item?.leader?.email}
                                                </a>
                                            </object>
                                        ) : (
                                            ''
                                        )}
                                        {has_linkedin_links === '1' && (
                                            <object className="w-8 h-8 mx-auto mb-4 mt-3">
                                                <a
                                                    href={getAbsoluteUrl(
                                                        item?.leader
                                                            ?.linked_in_link
                                                            ?.url
                                                    )}
                                                    target="_blank"
                                                    className="mb-6"
                                                >
                                                    {item?.leader
                                                        ?.linked_in_link
                                                        ?.url && (
                                                        <img
                                                            src="/icons/LinkedIn-icon.jpg"
                                                            alt="LinkedIn"
                                                            width={40}
                                                            className="linkedin-icon"
                                                        />
                                                    )}
                                                </a>
                                            </object>
                                        )}
                                    </a>
                                </>
                            ))}
                    </div>
                </div>
            </div>
            {popup === '1' && (
                <Transition
                    className="relative z-[100]"
                    show={modalState.open}
                    enter="duration-200 ease-out"
                    enterFrom="opacity-0"
                    enterTo="opacity-100 scale-100"
                    leave="duration-100 ease-in"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <div className="fixed top-0 left-0 flex flex-col w-full h-full min-h-screen bg-white">
                        <div className="absolute top-0 left-0 flex items-center justify-between w-full px-6 py-4 flex-0 z-[4]">
                            <div>
                                <span className="sr-only">Enfusion</span>
                                <img
                                    className="w-100% lg:max-w-[139px] max-w-[116px]"
                                    src="/images/enfusion-logo-white.svg"
                                    alt="Enfusion"
                                />
                            </div>
                            <a
                                href="#"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setModalState({
                                        open: false,
                                        slide: ''
                                    });
                                }}
                                className="flex items-center font-medium text-white lead lg:text-purple-midnight"
                            >
                                <span className="min-w-[42px]">Close</span>
                                <span
                                    className={`icon-expaded relative h-6 w-6 flex-col justify-between items-center flex ml-3`}
                                >
                                    <span
                                        className={`absolute inline-block w-1 h-1 rounded-10 lg:bg-purple-midnight bg-white`}
                                    ></span>
                                    <span
                                        className={`relative top-0 inline-block w-1 h-1 rounded-10 lg:bg-purple-midnight bg-white`}
                                    ></span>
                                    <span
                                        className={`inline-block w-1 h-1 rounded-10 lg:bg-purple-midnight bg-white`}
                                    ></span>
                                    <span
                                        className={`relative bottom-0 inline-block w-1 h-1 rounded-10 lg:bg-purple-midnight bg-white`}
                                    ></span>
                                    <span
                                        className={`absolute bottom-0 inline-block w-1 h-1 rounded-10 lg:bg-purple-midnight bg-white`}
                                    ></span>
                                </span>
                            </a>
                        </div>
                        <div className="fixed top-0 left-0 flex items-center justify-between w-full p-6 pt-20 lg:hidden bg-gradient-to-b from-black  to-black-0 z-[2]"></div>
                        <div className="w-full h-screen overflow-auto">
                            <Swiper
                                effect="fade"
                                className="h-full"
                                spaceBetween={0}
                                slidesPerView={1}
                                navigation={{
                                    nextEl: '.swiper-custom-next',
                                    prevEl: '.swiper-custom-prev'
                                }}
                                initialSlide={modalState?.index}
                                key={modalState?.index}
                            >
                                {lists &&
                                    lists.map((item, index) => (
                                        <SwiperSlide
                                            className="h-screen overflow-auto lg:flex"
                                            key={modalState?.index}
                                        >
                                            <div className="flex items-center justify-center flex-1 bg-gradient-12 lg:pt-0 pt-28">
                                                <div className="px-8 text-center text-white pb-8">
                                                    <div
                                                        className="lg:w-56 lg:h-56 w-40 h-40 rounded-[100px] mx-auto bg-cover bg-center mb-6"
                                                        style={{
                                                            backgroundImage: `url(${item.leader?.image?.src})`
                                                        }}
                                                    />

                                                    <h5
                                                        className="mb-2 lg:h5 h3"
                                                        dangerouslySetInnerHTML={{
                                                            __html:
                                                                item?.leader
                                                                    ?.name
                                                        }}
                                                    />
                                                    <p
                                                        className="mb-10 lead opacity-70"
                                                        dangerouslySetInnerHTML={{
                                                            __html:
                                                                item?.leader
                                                                    ?.title
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="flex items-center justify-center flex-1 px-6 py-16 overflow-auto bg-white lg:h-screen lg:px-0 lg:py-10">
                                                <div className="max-w-md max-h-full p-0">
                                                    <h3
                                                        className="mb-4"
                                                        dangerouslySetInnerHTML={{
                                                            __html:
                                                                item?.leader
                                                                    ?.name
                                                        }}
                                                    />
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html:
                                                                item.leader
                                                                    .content
                                                        }}
                                                        className="pb-10 lead opacity-70 editor"
                                                    />
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    ))}
                            </Swiper>
                            <div className="absolute left-6 swiper-custom-prev text-32 lg:top-1/2 z-[4] cursor-pointer -translate-y-1/2 transform top-52">
                                <i className="inline-block text-white transform rotate-180 en-long-arrow"></i>
                            </div>
                            <div className="absolute right-7 swiper-custom-next text-32 lg:top-1/2 top-52 z-[4] cursor-pointer lg:text-purple-midnight text-white -translate-y-1/2 transform">
                                <i className="inline-block en-long-arrow"></i>
                            </div>
                        </div>
                    </div>
                </Transition>
            )}
        </>
    );
};
export default OurLeaders;
