import { Transition } from '@headlessui/react';
import React, { useState } from 'react';
import { getCookie, setCookie } from '../../../util/auth';
import { getAbsoluteUrl } from '../../../lib/helpers'
export const Brochure2 = ({
    className = '',
    anchor = '',
    top_heading,
    main_heading,
    description,
    image,
    ctaText,
    link,
    pardot,
    cta,
    isFullScreen = '0',
    background = {}
}) => {
    const [modalState, setModalState] = useState(false);
    
    let iframeURL;
    try {
        if(link !== null && link)
            iframeURL = new URL(link);
    } catch (er) {
        console.error(er)
    }

    if (typeof window !== 'undefined' ) {
        const uparams = new URLSearchParams(window.location.search);
        if (uparams.has('gclid')) {
            setCookie('gclid', uparams.get('gclid'));
            if(iframeURL)
                iframeURL.searchParams.set('gclid', uparams.get('gclid'));
        } else {
            if (getCookie('gclid')) {
                if(iframeURL)
                    iframeURL.searchParams.set('gclid', getCookie('gclid'));
            }
        }
        ['source', 'content', 'medium', 'campaign'].forEach((type) => {
            if (uparams.has(`utm_${type}`)) {
                setCookie(`utm_${type}`, uparams.get(`utm_${type}`));
                if(iframeURL)
                    iframeURL.searchParams.set(
                        `utm_${type}`,
                        uparams.get(`utm_${type}`)
                );
            } else if (getCookie(`utm_${type}`)) {
                if(iframeURL)
                    iframeURL.searchParams.set(
                        `utm_${type}`,
                        getCookie(`utm_${type}`)
                );
            }
        });
    }
    return (
        <>
            <div
                className={`container py-16  wow fadeInUp  ${className}`}
                id={anchor}
            >
                <div
                    className={`bg-cover flex justify-between items-center lg:flex-row flex-col lg:py-14 py-10 ${
                        !image?.medium_url ? '' : 'pt-0'
                    }  }  lg:px-24 px-10 lg:space-x-20 space-x-0 lg:space-y-0 space-y-6 ${
                        !!background?.class ? background.class : 'bg-gradient-12'
                    } text-white lg:mt-0 mt-32 lg:text-left text-center`}
                >
                    <div>
                        {image?.medium_url && (
                            <img
                                className="block mx-auto -mt-20 lg:mt-0"
                                src={image.medium_url}
                                alt="Brochure"
                            />
                        )}
                        {pardot ? (
                            link ? (
                                <a
                                    href=""
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setModalState(true);
                                    }}
                                    target="_blank"
                                    className="mt-6 btn btn--white lg:btn--lg"
                                >
                                    {ctaText}
                                </a>
                            ) : (
                                ''
                            )
                        ) : link ? (
                            <a
                                href={getAbsoluteUrl(link)}
                                target="_blank"
                                className="mt-6 btn btn--white lg:btn--lg"
                            >
                                {ctaText}
                            </a>
                            ) : cta ? (
                            <a
                            href={getAbsoluteUrl(cta?.url)}
                            target={cta?.target}
                            className="mt-6 btn btn--white lg:btn--lg"
                        >
                            {cta?.title}
                        </a>
                        ) : (
                            ''
                        )}
                    </div>
                    {description && (
                        <div className="flex flex-col flex-1">
                            {main_heading && (
                                <h5
                                    className="mb-4 md:h4 h5"
                                    dangerouslySetInnerHTML={{
                                        __html: main_heading
                                    }}
                                />
                            )}
                            <h6
                                className="md:h6 lead"
                                dangerouslySetInnerHTML={{
                                    __html: description
                                }}
                            />
                        </div>
                    )}
                </div>
            </div>

            {pardot && (
                <Transition
                    className={`relative z-[100] ${
                        isFullScreen === '0'
                            ? 'lg:p-10 p-6 rounded-10 overflow-hidden'
                            : ''
                    }`}
                    show={modalState}
                    enter="duration-200 ease-out"
                    enterFrom="opacity-0"
                    enterTo="opacity-100 scale-100"
                    leave="duration-100 ease-in"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <div
                        className={` z-[99] ${
                            isFullScreen === '0'
                                ? 'fixed md:max-w-xl md:max-h-[650px] w-full h-screen top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 md:p-10 p-4 bg-white'
                                : 'fixed top-0 left-0 w-full h-full min-h-screen'
                        }`}
                    >
                        <div className="absolute top-0 right-0 z-[1] p-4">
                            <a
                                href="#"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setModalState(false);
                                }}
                                className="flex items-center font-medium lead text-purple-midnight"
                            >
                                <span className="min-w-[42px]">Close</span>
                                <span
                                    className={`icon-expaded relative h-6 w-6 flex-col justify-between items-center flex ml-3`}
                                >
                                    <span
                                        className={`absolute inline-block w-1 h-1 rounded-10 bg-purple-midnight`}
                                    ></span>
                                    <span
                                        className={`relative top-0 inline-block w-1 h-1 rounded-10 bg-purple-midnight`}
                                    ></span>
                                    <span
                                        className={`inline-block w-1 h-1 rounded-10 bg-purple-midnight`}
                                    ></span>
                                    <span
                                        className={`relative bottom-0 inline-block w-1 h-1 rounded-10 bg-purple-midnight`}
                                    ></span>
                                    <span
                                        className={`absolute bottom-0 inline-block w-1 h-1 rounded-10 bg-purple-midnight`}
                                    ></span>
                                </span>
                            </a>
                        </div>
                        {iframeURL && 
                            <iframe
                                id="pardot-iframe"
                                src={iframeURL.toString()}
                                className="w-full h-full bg-white"
                            ></iframe>
                        }
                            
                    </div>
                    {isFullScreen === '0' ? (
                        <div className="fixed top-0 left-0 w-full h-screen bg-purple-midnight bg-opacity-60 z-[1]"></div>
                    ) : (
                        ''
                    )}
                </Transition>
            )}
        </>
    );
};
export default Brochure2;
