import React, { useState } from 'react';

import Category from './category';

export const PartnersByCategory = ({
    className = '',
    anchor = '',
    top_heading,
    main_heading,
    description,
    lists
}) => {
    return (
        <>
            <div
                className={`wow fadeInUp md:py-32 py-16  ${className}`}
                id={anchor}
            >
                <div className="container">
                    {top_heading && (
                        <p
                            className="mb-4 font-medium md:mb-6 label text-purple wow fadeInUp"
                            dangerouslySetInnerHTML={{ __html: top_heading }}
                        />
                    )}
                    {main_heading && (
                        <h3
                            className="md:h3 h2 wow fadeInUp"
                            dangerouslySetInnerHTML={{ __html: main_heading }}
                        />
                    )}
                    <p
                        className="text-18 font-normal lg:font-medium leading-32 mb-9 mt-4"
                        dangerouslySetInnerHTML={{
                            __html: description
                        }}
                    />
                    <div className="lg:grid lg:gap-8 lg:grid-cols-2">
                        {lists &&
                            lists.map((item, index) => (
                                <Category
                                    item={item}
                                    key={index}
                                    index={index}
                                    last={index === lists.length - 1}
                                />
                            ))}
                    </div>
                </div>
            </div>
        </>
    );
};
export default PartnersByCategory;