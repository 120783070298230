import React from 'react';

import Play from './Play';
import Pause from './Pause';
import Bar from './Bar';

import useAudioPlayer from './useAudioPlayer';

import { gtag } from '../../../../util/googleTagManager';

function Audio({ source }) {
    const {
        curTime,
        duration,
        playing,
        setPlaying,
        setClickedTime
    } = useAudioPlayer();

    return (
        <div className="max-w-[640px] player">
            <audio id="audio">
                <source src={source} />
                Your browser does not support the <code>audio</code> element.
            </audio>

            <div className="flex controls">
                {playing ? (
                    <Pause
                        handleClick={() => {
                            gtag('event', 'audio_podcast', {
                                 operation: 'pause',
                                 file: source
                            });
                            setPlaying(false);
                        }}
                    />
                ) : (
                    <Play
                        handleClick={() => {
                            gtag('event', 'audio_podcast', {
                                 operation: 'play',
                                 file: source
                            });
                            setPlaying(true);
                        }}
                    />
                )}
                <Bar
                    curTime={curTime}
                    duration={duration}
                    onTimeUpdate={(time) => setClickedTime(time)}
                    source={source}
                />
            </div>
        </div>
    );
}

export default Audio;
