import React from 'react';
import { extractRepeaterField } from '../../util/blocks';

import { TwoColContact } from '../../components/enfusion/TwoColContact';

function ACFTwoColContact({ meta, data = [], className, anchor, pageProps }) {
    const { themeAssets } = pageProps;

    const { main_heading_, top_heading, icon, description } = data;
    let icons = [];
    icon.forEach((element, index) => {
        icons[index] = themeAssets[element];
    });

    let lists = extractRepeaterField(data, 'contacts');

    return (
        <TwoColContact
            className={className}
            anchor={anchor}
            lists={lists}
            main_heading={main_heading_}
            top_heading={top_heading}
            description={description}
            icons={icons}
        />
    );
}

export default ACFTwoColContact;
