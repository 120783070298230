import React from 'react';
import NextNavLink from '../../components/util/NextNavLink';

import { Image } from '../../util/image';

function CoreImage({
    width,
    height,
    className = '',
    url,
    anchor = '',
    caption,
    alt,
    linkTarget = '',
    href = ''
}) {
    if (!width || !height) {
        return (
            <div className={`${className}`}>
                {href ? (
                    <NextNavLink target={linkTarget} href={href}>
                        <a>
                            <Image
                                src={url}
                                className={`my-6  wow fadeInUp inline-block`}
                                id={anchor}
                            />
                            {caption && (
                                <div
                                    className="text-12"
                                    dangerouslySetInnerHTML={{
                                        __html: caption
                                    }}
                                />
                            )}
                        </a>
                    </NextNavLink>
                ) : (
                    <>
                        <Image
                            src={url}
                            width={width}
                            height={height}
                            id={anchor}
                            className={` my-6 wow fadeInUp inline-block`}
                        />
                        {caption && (
                            <div
                                className="text-12"
                                dangerouslySetInnerHTML={{ __html: caption }}
                            />
                        )}
                    </>
                )}
            </div>
        );
    }

    return (
        <div className={`${className}`}>
            <Image
                src={url}
                width={width}
                height={height}
                id={anchor}
                className={` my-6 wow fadeInUp inline-block`}
            />
            {caption && (
                <div
                    className="text-12"
                    dangerouslySetInnerHTML={{ __html: caption }}
                />
            )}
        </div>
    );
}

export default CoreImage;
