import React from 'react';
import { extractRepeaterField } from '../../util/blocks';

import { LegalText } from '../../components/enfusion/LegalText';

function ACFLegalText({ meta, data = [], className, anchor, pageProps }) {
    let content = extractRepeaterField(data, 'two_column_legal_text');

    return (
        <LegalText className={className} anchor={anchor} content={content} />
    );
}

export default ACFLegalText;
