import React from 'react';
import ClientStoriesCarousel from '@components/enfusion/ClientStoriesCarousel';

function ACFClientStoriesCarousel({ meta, data, className, anchor }) {
    const { heading, hide_filters } = data;
    const posts = meta?.posts;
    let categoriesMap = {
        cat: [],
        subcategories: []
    };
    let clientStory = posts.map((post) => {
        const categories = post.categories;
        const title = post.title;
        const permalink = post.permalink;
        const featuredImage = post.featured_image;
        const featuredVideo = post.featured_video;
        const logoImage = post.logo_image;
        let featuredImageSrc = '';
        if (featuredImage && featuredImage.id) {
            featuredImageSrc = featuredImage.id.url;
        }
        const logoImageSrc = logoImage?.id?.url;

        /* This code is iterating over an array of categories and checking if each category has a
        parent of 0 (meaning it is a top-level category) and if its name is not already included in
        the `categoriesMap.cat` array. If both conditions are true, it adds the category name to the
        `categoriesMap.cat` array. */
        categories.forEach((category) => {
            if (
                category.parent === 0 &&
                !categoriesMap.cat.includes(category.name)
            ) {
                categoriesMap.cat.push(category.name);
            }
            if (
                category.parent !== 0 &&
                !categoriesMap.subcategories.includes(category.name)
            ) {
                categoriesMap.subcategories.push(category.name);
            }
        });

        return {
            category: categories.find((category) => category.parent === 0)
                ?.name,
            subcategories: categories
                .filter((cat) => cat.parent !== 0)
                .map((cat) => cat.name),
            title,
            permalink,
            featuredImage: {
                featuredImageSrc: featuredImageSrc
            },
            featuredVideo,
            logoImage: {
                logoImageSrc: logoImageSrc
            }
        };
    });
    return (
        <ClientStoriesCarousel
            groups={categoriesMap}
            heading={heading}
            className={className}
            hideFilters={hide_filters}
            anchor={anchor}
            clientStory={clientStory}
        />
    );
}

export default ACFClientStoriesCarousel;