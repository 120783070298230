import React from 'react';
import { extractRepeaterField } from '../../util/blocks';

import { TwoColContactForm } from '../../components/enfusion/TwoColContactForm';

function ACFTwoColContactForm({ meta, data, className, anchor }) {
    const { main_heading, top_heading, description, form_embed } = data;
    return (
        <TwoColContactForm
            className={className}
            anchor={anchor}
            top_heading={top_heading}
            main_heading={main_heading}
            description={description}
            embed={form_embed}
            testimonials_carousel={data?.testimonials_carousel}
            testimonials={meta?.testimonials}
        />
    );
}

export default ACFTwoColContactForm;
