import React, { useState } from 'react';
import Breadcrumb from '../Header/Breadcrumb';

import { useAppContext } from '../../../util/context';

export const PageHero2Compact = ({
    className = '',
    anchor = '',
    darkLight,
    content,
    breadcrumbs,
    main_header, 
    background,
    backgroundImage
}) => {
    const context = useAppContext();
    context.toggleDarkMode(darkLight !== '1' ? false : true);
    context.toggleHeaderTheme(darkLight !== '1' ? 'bg-white bg-opacity-0' : '');
 

    return (
        <div
            className={`md:pt-36 md:pb-36 pb-16 pt-28 overflow-hidden relative ${className} ${darkLight === '1' ? 'text-white' : ''}`}
            id={anchor}
        >
            <div className="container relative z-[1]">
                <div className="flex items-center mb-5 md:mb-8 wow fadeInUp">
                    <i className="inline-block w-2.5 min-w-[0.66rem] h-2.5 mr-3 border-2 rounded-10 border-purple"></i>
                      <h2
                        className="font-medium label"
                        dangerouslySetInnerHTML={{ __html: breadcrumbs }}
                    />
                </div>
                <div className="wow fadeInUp">
                    <div className="">
                        {main_header && (
                            <h1 className="md:h2 h1" dangerouslySetInnerHTML={{ __html:main_header }} />
                        )}
                        {content && (
                            <div
                                className="flex-1 mt-5 md:flex lead lead--large opacity-70 md:mt-8"
                                dangerouslySetInnerHTML={{
                                    __html: content
                                }}
                            />
                        )}
                       
                    </div>
                </div>
            </div>
            <div style={{ transform: `matrix(-1, 0, 0, 1, 0, 0)` }} className={`absolute w-full h-full top-0 left-0 z-0  bg-bottom ${background?.class} ${backgroundImage?.src ? '' : 'opacity-50'}`} style={backgroundImage?.src && { backgroundImage: `url(${backgroundImage?.src})` }}></div>
            {!backgroundImage?.src && 
                <div className={ `absolute w-full h-3/4 top-0 left-0 z-0 opacity-50 bg-pattern-2-periwinkle bg-no-repeat bg-left transform lg:translate-x-1/2 translate-x-0 translate-y-1/3`}></div>
            }
            <div className={ `absolute w-full h-80 top-0 left-0 bg-gradient-to-b ${darkLight !== '1' ? 'from-white' : 'from-purple-midnight'} `}></div>
        </div>
    );
};
export default PageHero2Compact;
