import React from 'react';
import NextNavLink from '../../components/util/NextNavLink';

function CoreButton({
    pageProps,
    url = '',
    text,
    anchor = '',
    className = '',
    linkTarget = '_self'
}) {
    return (
        <>
            {text && (
                <NextNavLink target={linkTarget} href={url}>
                    <a className={`btn btn--primary ${className}`} id={anchor}>
                        {text}
                    </a>
                </NextNavLink>
            )}
        </>
    );
}

export default CoreButton;
