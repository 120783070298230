import React from 'react';
import { extractRepeaterField } from '../../util/blocks';

import { OurLeaders } from '../../components/enfusion/OurLeaders';

function ACFOurLeaders({ meta, data, className, anchor }) {
    const { leaders } = meta;
    const {
        main_heading,
        top_heading,
        has_linkedin_links,
        leader_card_popup
    } = data;
    let lists = extractRepeaterField(data, 'leader_list');

    lists = lists.map((repeaterItem) => ({
        ...repeaterItem,
        leader: leaders[repeaterItem.leader[0]] || {}
    }));

    return (
        <OurLeaders
            className={className}
            anchor={anchor}
            top_heading={top_heading}
            main_heading={main_heading}
            has_linkedin_links={has_linkedin_links}
            popup={leader_card_popup}
            lists={lists}
        />
    );
}

export default ACFOurLeaders;
