import { Transition } from '@headlessui/react';
import React, { useState } from 'react';

import NextNavLink from '../../util/NextNavLink';
import { getCookie, setCookie } from '../../../util/auth';
import { getAbsoluteUrl } from '../../../lib/helpers';
export const ButtonPopup = ({
    className = '',
    anchor = '',
    button_style = 'btn--primary',
    link,
    isFullScreen = '0',
    simple_link
}) => {
    const [modalState, setModalState] = useState(false);
    let iframeURL = new URL(link.url);

    if (typeof window !== 'undefined') {
        const uparams = new URLSearchParams(window.location.search);
        if (uparams.has('gclid')) {
            setCookie('gclid', uparams.get('gclid'));
            iframeURL.searchParams.set('gclid', uparams.get('gclid'));
        } else {
            if (getCookie('gclid')) {
                iframeURL.searchParams.set('gclid', getCookie('gclid'));
            }
        }
        ['source', 'content', 'medium', 'campaign'].forEach((type) => {
            if (uparams.has(`utm_${type}`)) {
                setCookie(`utm_${type}`, uparams.get(`utm_${type}`));
                iframeURL.searchParams.set(
                    `utm_${type}`,
                    uparams.get(`utm_${type}`)
                );
            } else if (getCookie(`utm_${type}`)) {
                iframeURL.searchParams.set(
                    `utm_${type}`,
                    getCookie(`utm_${type}`)
                );
            }
        });
    }
    return (
        <>
            <div
                className={`container py-2  wow fadeInUp  ${className}`}
                id={anchor}
            >
                {link && (
                    <NextNavLink
                        href={`${getAbsoluteUrl(
                            simple_link === '1' ? link?.url : '#'
                        )}`}
                        target={link?.target}
                    >
                        <a
                            target={link?.target}
                            onClick={(e) => {
                                if (simple_link !== '1') {
                                    e.preventDefault();
                                    setModalState(true);
                                }
                            }}
                            className={`btn ${button_style}`}
                            dangerouslySetInnerHTML={{
                                __html: link?.title
                            }}
                        ></a>
                    </NextNavLink>
                )}
            </div>

            <Transition
                className={`relative z-[100] ${
                    isFullScreen === '0'
                        ? 'lg:p-10 p-6 rounded-10 overflow-hidden'
                        : ''
                }`}
                show={modalState}
                enter="duration-200 ease-out"
                enterFrom="opacity-0"
                enterTo="opacity-100 scale-100"
                leave="duration-100 ease-in"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
            >
                <div
                    className={` z-[99] ${
                        isFullScreen === '0'
                            ? 'fixed md:max-w-xl md:max-h-[650px] w-full h-screen top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 md:p-10 p-4 bg-white'
                            : 'fixed top-0 left-0 w-full h-full min-h-screen'
                    }`}
                >
                    <div className="absolute top-0 right-0 z-[1] p-4">
                        <a
                            href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                setModalState(false);
                            }}
                            className="flex items-center font-medium lead text-purple-midnight"
                        >
                            <span className="min-w-[42px]">Close</span>
                            <span
                                className={`icon-expaded relative h-6 w-6 flex-col justify-between items-center flex ml-3`}
                            >
                                <span
                                    className={`absolute inline-block w-1 h-1 rounded-10 bg-purple-midnight`}
                                ></span>
                                <span
                                    className={`relative top-0 inline-block w-1 h-1 rounded-10 bg-purple-midnight`}
                                ></span>
                                <span
                                    className={`inline-block w-1 h-1 rounded-10 bg-purple-midnight`}
                                ></span>
                                <span
                                    className={`relative bottom-0 inline-block w-1 h-1 rounded-10 bg-purple-midnight`}
                                ></span>
                                <span
                                    className={`absolute bottom-0 inline-block w-1 h-1 rounded-10 bg-purple-midnight`}
                                ></span>
                            </span>
                        </a>
                    </div>
                    <iframe
                        id="pardot-iframe"
                        src={iframeURL.toString()}
                        className="w-full h-full bg-white"
                    ></iframe>
                </div>
                {isFullScreen === '0' ? (
                    <div className="fixed top-0 left-0 w-full h-screen bg-purple-midnight bg-opacity-60 z-[1]"></div>
                ) : (
                    ''
                )}
            </Transition>
        </>
    );
};
export default ButtonPopup;
