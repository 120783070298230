import React from 'react';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';

export default function Bar(props) {
    const { duration, curTime, onTimeUpdate, source } = props;

    const curPercentage = (curTime / duration) * 100;

    if (curPercentage === 100) {
        // gtag('event', 'audio_podcast', {
        //     operation: 'completed',
        //     file: source
        // });
    }

    function formatDuration(duration) {
        return moment
            .duration(duration, 'seconds')
            .format('mm:ss', { trim: false });
    }

    function calcClickedTime(e) {
        const clickPositionInPage = e.pageX;
        const bar = document.querySelector('.bar__progress');
        const barStart = bar.getBoundingClientRect().left + window.scrollX;
        const barWidth = bar.offsetWidth;
        const clickPositionInBar = clickPositionInPage - barStart;
        const timePerPixel = duration / barWidth;
        return timePerPixel * clickPositionInBar;
    }

    function handleTimeDrag(e) {
        onTimeUpdate(calcClickedTime(e));

        const updateTimeOnMove = (eMove) => {
            onTimeUpdate(calcClickedTime(eMove));
        };

        document.addEventListener('mousemove', updateTimeOnMove);

        document.addEventListener('mouseup', () => {
            document.removeEventListener('mousemove', updateTimeOnMove);
        });
    }

    return (
        <>
            <div className="flex-1 pl-5">
                <div className="h-0.5 bg-purple-midnight bg-opacity-20 bar mt-4">
                    <div
                        className="h-0.5 bg-purple-midnight bg-opacity-20 relative cursor-pointer bar__progress"
                        style={{
                            background: `linear-gradient(to right, #1E1A34 ${curPercentage}%, transparent 0)`
                        }}
                        onMouseDown={(e) => handleTimeDrag(e)}
                    >
                        <span
                            className="absolute w-2 h-2 cursor-pointer rounded-10 bg-purple-midnight"
                            style={{ left: `${curPercentage}%`, top: '-3px' }}
                        />
                    </div>
                </div>
                <div className="flex justify-between mt-5 lead opacity-70 text-purple-midnight">
                    <span className="bar__time">{formatDuration(curTime)}</span>

                    <span className="bar__time">
                        {formatDuration(duration)}
                    </span>
                </div>
            </div>
        </>
    );
}
