import React, { useState } from 'react';
import { extractRepeaterField } from '../../util/blocks';

import { RecentAwards } from '../../components/enfusion/RecentAwards';

function ACFRecentAwards({ meta, data, className, anchor, pageProps }) {
    const { themeAssets, defaults } = pageProps;

    const [awards, setAwards] = useState(meta?.awards.slice(0, 8));

    const {
        top_heading,
        main_heading,
        cta,
        style_options_background,
        style_options_light_dark,
        use_block_default_data,
        select_custom_awards
    } = data;

    let customAwards;
    let result = [];

    let defaultContent = use_block_default_data;

    if (select_custom_awards?.length > 0) {
        customAwards = meta?.awards.filter((item, index) => {
            if (select_custom_awards.includes(String(item.id))) {
                return true;
            }
            return false;
        });

        select_custom_awards.forEach((item, index) => {
            customAwards.forEach((award, i) => {
                if (item === String(award.id)) {
                    result[index] = award;
                }
            });
        });
    }

    if (defaultContent === '1') {
        if (
            defaults?.recentAwards?.acf_recent_awards?.selectCustomAwards
                ?.length > 0
        ) {
            var selectCustomAwards = defaults?.recentAwards?.acf_recent_awards?.selectCustomAwards.map(
                function (item) {
                    return item.awardId;
                }
            );

            customAwards = meta?.awards.filter((item, index) => {
                if (selectCustomAwards.includes(item.id)) {
                    return true;
                }
                return false;
            });

            selectCustomAwards.forEach((item, index) => {
                customAwards.forEach((award, i) => {
                    if (item === award.id) {
                        result[index] = award;
                    }
                });
            });
        }
    }

    customAwards = result;

    let background =
        themeAssets[
            defaultContent !== '1'
                ? style_options_background
                : defaults?.recentAwards?.acf_recent_awards?.styleOptions
                      ?.background[0]?.themeassetId
        ];

    return (
        <RecentAwards
            className={className}
            anchor={anchor}
            top_heading={
                defaultContent !== '1'
                    ? top_heading
                    : defaults?.recentAwards?.acf_recent_awards?.topHeading
            }
            main_heading={
                defaultContent !== '1'
                    ? main_heading
                    : defaults?.recentAwards?.acf_recent_awards?.mainHeading
            }
            cta={
                defaultContent !== '1'
                    ? cta
                    : defaults?.recentAwards?.acf_recent_awards?.cta
            }
            background={background}
            light_dark={
                defaultContent !== '1'
                    ? style_options_light_dark
                    : defaults?.recentAwards?.acf_recent_awards?.styleOptions
                          ?.lightDark
            }
            awards={
                select_custom_awards?.length && defaultContent !== '1'
                    ? customAwards
                    : defaultContent === '1' &&
                      defaults?.recentAwards?.acf_recent_awards
                          ?.selectCustomAwards?.length > 0
                    ? customAwards
                    : awards
            }
        />
    );
}

export default ACFRecentAwards;
