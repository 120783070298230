import React from 'react';
import { extractRepeaterField } from '../../util/blocks';

import { ClientLinksArrow } from '../../components/enfusion/ClientLinksArrow';

function ACFClientLinksArrow({
    meta,
    data = [],
    className,
    anchor,
    pageProps
}) {
    const { themeAssets } = pageProps;
    let lists = extractRepeaterField(data, 'card');

    lists = lists.map((repeaterItem) => ({
        ...repeaterItem,
        icon: themeAssets[repeaterItem.icon] || {},
        cta_links: extractRepeaterField(repeaterItem, 'cta_links').map(
            (item) => ({
                ...item,
                icon: themeAssets[item.icon] || {}
            })
        )
    }));

    return (
        <ClientLinksArrow className={className} anchor={anchor} lists={lists} />
    );
}

export default ACFClientLinksArrow;