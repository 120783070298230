import React from 'react';
import PropTypes from 'prop-types';

export const Separator = ({ size, color, anchor = '', className = '' }) => {
    return (
        <div id={anchor} className={className}>
            <div className={`${size === 'Full' ? '' : 'container'} `}>
                <div className="border-t" style={{ borderColor: color }}></div>
            </div>
        </div>
    );
};
