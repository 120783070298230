import React from 'react';
import { extractRepeaterField } from '../../util/blocks';

import { TwoColCarousel } from '../../components/enfusion/TwoColCarousel';

function ACFTwoColCarousel({ meta, data = [], className, anchor, pageProps }) {
    const { themeAssets } = pageProps;
    const { style_options_background_pattern = [] } = data;

    let grid = extractRepeaterField(data, 'carousel');

    grid = grid.map((repeaterItem) => ({
        ...repeaterItem,
        image: meta?.images[repeaterItem.image] || {}
    }));
    let pattern = themeAssets[style_options_background_pattern];
    return (
        <TwoColCarousel
            className={className}
            anchor={anchor}
            grid={grid}
            pattern={pattern}
        />
    );
}

export default ACFTwoColCarousel;
