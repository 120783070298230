import React from 'react';
import { extractRepeaterField } from '../../util/blocks';

import { SingleColAccordianList } from '../../components/enfusion/SingleColAccordianList';

function ACFSingleColAccordianList({ meta, data, className, anchor, pageProps }) {
    const { themeAssets } = pageProps;

    const { top_heading, description } = data;
    let lists = extractRepeaterField(data, 'accordion_list');
    // let newList = [ ...lists ];
    // lists.map((item, i) => {
    //     newList[i].list = extractRepeaterField(item, 'list')
    // })

    // lists = lists.map((repeaterItem) => ({
    //     ...repeaterItem,
    //     image_icon: themeAssets[repeaterItem.image_icon] || {}
    // }));
    // console.log(data,lists)
    return (
        <SingleColAccordianList
            className={className}
            anchor={anchor}
            lists={lists}
            top_heading={top_heading}
            description={description}
        />
    );
}

export default ACFSingleColAccordianList;
