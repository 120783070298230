import React, { useState } from 'react';

import NextNavLink from '../../util/NextNavLink';
export const Speakers = ({ className = '', anchor = '', title, lists }) => {
    return (
        <div
            className={`container mb-6  wow fadeInUp ${className}`}
            id={anchor}
        >
            {title && <h3 className="mb-8">{title}</h3>}
            <div>
                {lists &&
                    lists.map((item, index) => (
                        <div
                            key={index}
                            className="py-6 border-b md:py-8 first:border-t border-purple-midnight border-opacity-20"
                        >
                            <div className="flex items-center space-x-6 md:space-x-8">
                                {item?.speaker_image?.src && (
                                    <div className="md:pr-8 md:border-r border-purple-midnight border-opacity-20">
                                        <div
                                            className="md:w-40 md:h-40 w-28 h-28 rounded-[200px] bg-cover bg-center "
                                            style={{
                                                backgroundImage: `url(${
                                                    item?.speaker_image
                                                        ?.medium_url ||
                                                    item?.speaker_image?.src
                                                })`
                                            }}
                                        ></div>
                                    </div>
                                )}
                                <div className="">
                                    {item?.speaker_name && (
                                        <h5 className="mb-2 md:h5 lead lead--large">
                                            {item?.speaker_name}
                                        </h5>
                                    )}
                                    {item?.speaker_position && (
                                        <p className="mb-2 md:lead lead--small text-purple">
                                            {item?.speaker_position}
                                        </p>
                                    )}
                                    {item?.speaker_company && (
                                        <p className="mb-2 label md:label--small label--xs">
                                            {item?.speaker_company}
                                        </p>
                                    )}
                                    {item?.speaker_additional_info && (
                                        <div
                                            className="hidden mb-2 lead opacity-70 md:block"
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    item?.speaker_additional_info
                                            }}
                                        />
                                    )}
                                </div>
                            </div>
                            {item?.speaker_additional_info && (
                                <div
                                    className="mt-6 lead opacity-70 md:hidden"
                                    dangerouslySetInnerHTML={{
                                        __html: item?.speaker_additional_info
                                    }}
                                />
                            )}
                        </div>
                    ))}
            </div>
        </div>
    );
};
export default Speakers;
