import React, { useState, useEffect } from 'react';

import { Transition } from '@headlessui/react';
import { useWindowDimensions } from '../../../util/hooks';
export const CarrersQuote = ({
    className = '',
    anchor = '',
    top_heading,
    bottom_heading,
    background = '',
    bgDesktop,
    bgMobile,
    light_dark
}) => {
   const [isMobile, setIsMobile] = useState();
    useEffect(() => {
         
        function handleBackground() {
            setIsMobile(useWindowDimensions().width < 769 ? true : false);
        }

        window.addEventListener('resize', handleBackground);
        window.addEventListener('load', handleBackground);

        return () => {
            window.removeEventListener('resize', handleBackground);
            window.removeEventListener('load', handleBackground);
        };
    }, []);
    return (
        <div
            className={`relative md:py-32 py-16 bg-cover wow fadeInUp bg-center ${light_dark !== '1' ? 'text-purple-midnight' : 'text-white'} ${className} ${background?.class}`}
            id={anchor}
            style={bgDesktop && {backgroundImage:`url(${isMobile ? bgMobile?.src :  bgDesktop?.src})`}}
        >
            
            <div className="container my-1">
                <div className="lg:w-1/2">
                    {top_heading && 
                        <div className="h4" dangerouslySetInnerHTML={{ __html: top_heading }} />
                    }
                  
                    {bottom_heading && (
                        <>
                            <div className={`w-16 my-6 border-t ${light_dark !== '1' ? 'border-purple-midnight' : 'border-white'}`}></div>
                            <div className="h4" dangerouslySetInnerHTML={{ __html: bottom_heading }} />
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};
export default CarrersQuote;
