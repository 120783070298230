import React from 'react';
import { extractRepeaterField } from '../../util/blocks';

import { PartnersByCategory } from '../../components/enfusion/PartnersByCategory';

function ACFPartnersByCategory({ meta, data, className, anchor, pageProps }) {
    const {
        main_header,
        top_heading,
        description = 'Your unique workflows. Your best practices. One single platform. Services and technologies available include:'
    } = data;
    let lists = extractRepeaterField(data, 'partner_card');

    lists = lists.map((repeaterItem) => ({
        ...repeaterItem,
        links: extractRepeaterField(repeaterItem, 'links') || {},
        icon: pageProps.themeAssets[repeaterItem.icon?.[0]] ?? {}
    }));
    return (
        <PartnersByCategory
            className={className}
            anchor={anchor}
            top_heading={top_heading}
            main_heading={main_header}
            lists={lists}
            description={description}
        />
    );
}

export default ACFPartnersByCategory;
