import React, { useState } from 'react';

import NextNavLink from '../../util/NextNavLink';

import ReactPlayer from 'react-player';

import { Transition } from '@headlessui/react';

export const OurTeamGrid = ({
    className = '',
    anchor = '',
    top_heading,
    main_heading,
    description,
    lists
}) => {
    const [modalState, setModalState] = useState({
        open: false,
        index: ''
    });
    return (
        <>
            <div
                className={`overflow-hidden  wow fadeInUp md:py-32  py-16 ${className}`}
                id={anchor}
            >
                <div className="container">
                    <div className="grid gap-4 mb-12 md:gap-32 md:mb-32 md:grid-cols-2">
                        <div>
                            {top_heading && (
                                <p className="mb-4 font-medium md:mb-6 label text-purple">
                                    {top_heading}
                                </p>
                            )}
                            {main_heading && (
                                <h3 className="md:h3 h2 ">{main_heading}</h3>
                            )}
                        </div>
                        <div className="flex items-center">
                            {description && (
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: description
                                    }}
                                    className="lead lead--large opacity-70"
                                />
                            )}
                        </div>
                    </div>
                </div>
                <div className="relative bg-center bg-no-repeat bg-pattern-1 bg-100%">
                    <div className="max-w-lg mx-auto lg:container px-[27px]">
                        <div className="flex flex-col pb-2 space-x-12 lg:space-x-24 lg:pl-16 lg:flex-row">
                            <div className="flex flex-1 mb-12 lg:justify-end lg:mb-0">
                                {lists[0] && (
                                    <div>
                                        <div
                                            onClick={(e) => {
                                                e.preventDefault();
                                                lists[0].video
                                                    ? setModalState({
                                                          open: true,
                                                          details: lists[0]
                                                      })
                                                    : {};
                                            }}
                                            className="w-full md:w-[448px] w-[256px] md:h-[336px] h-[192px] bg-cover wow fadeInLeft cursor-pointer relative"
                                            style={{
                                                backgroundImage: `url(${lists[0].card_image?.src})`
                                            }}
                                        >
                                            {lists[0].video ? (
                                                <img
                                                    src="/images/play-icon-trans.svg"
                                                    alt="Play"
                                                    className="absolute w-16 bottom-6 right-6"
                                                />
                                            ) : (
                                                ' '
                                            )}
                                        </div>
                                        {lists[0]?.caption && (
                                            <p
                                                className="lead p-4 bg-white lg:w-[448px]"
                                                dangerouslySetInnerHTML={{
                                                    __html: lists[0]?.caption
                                                }}
                                            />
                                        )}
                                    </div>
                                )}
                            </div>
                            <div className="flex flex-col justify-end flex-1 lg:justify-start">
                                {lists[1] && (
                                    <div>
                                        <div
                                            onClick={(e) => {
                                                e.preventDefault();
                                                lists[1].video
                                                    ? setModalState({
                                                          open: true,
                                                          details: lists[1]
                                                      })
                                                    : {};
                                            }}
                                            className="lg:w-[288px] lg:h-[288px] w-[144px] h-[144px] bg-cover lg:mt-[150px] wow fadeInRight cursor-pointer relative"
                                            style={{
                                                backgroundImage: `url(${lists[1].card_image?.src})`
                                            }}
                                        >
                                            {lists[1].video ? (
                                                <img
                                                    src="/images/play-icon-trans.svg"
                                                    alt="Play"
                                                    className="absolute w-16 bottom-6 right-6"
                                                />
                                            ) : (
                                                ' '
                                            )}
                                        </div>
                                        {lists[1]?.caption && (
                                            <p
                                                className="lead p-4 bg-white lg:w-[288px]"
                                                dangerouslySetInnerHTML={{
                                                    __html: lists[1]?.caption
                                                }}
                                            />
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="flex flex-col space-y-12 lg:mt-20 lg:flex-row lg:space-y-0">
                            <div className="relative flex mt-6 lg:-mt-20 lg:-top-4 flex-0">
                                {lists[2] && (
                                    <div>
                                        <div
                                            onClick={(e) => {
                                                e.preventDefault();
                                                lists[2].video
                                                    ? setModalState({
                                                          open: true,
                                                          details: lists[2]
                                                      })
                                                    : {};
                                            }}
                                            className="lg:w-[224px] lg:h-[224px] w-[120px] h-[120px] bg-cover wow fadeInLeft cursor-pointer relative"
                                            style={{
                                                backgroundImage: `url(${lists[2].card_image?.src})`
                                            }}
                                        >
                                            {lists[2].video ? (
                                                <img
                                                    src="/images/play-icon-trans.svg"
                                                    alt="Play"
                                                    className="absolute w-16 bottom-6 right-6"
                                                />
                                            ) : (
                                                ' '
                                            )}
                                        </div>
                                        {lists[2]?.caption && (
                                            <p
                                                className="lead p-4 bg-white max-w-[250px]"
                                                dangerouslySetInnerHTML={{
                                                    __html: lists[2]?.caption
                                                }}
                                            />
                                        )}
                                    </div>
                                )}
                            </div>
                            <div className="flex justify-end flex-1 lg:justify-center">
                                {lists[3] && (
                                    <div className="w-full lg:max-w-[416px] max-w-[256px]">
                                        <div
                                            onClick={(e) => {
                                                e.preventDefault();
                                                lists[3].video
                                                    ? setModalState({
                                                          open: true,
                                                          details: lists[3]
                                                      })
                                                    : {};
                                            }}
                                            className="w-full lg:max-w-[416px] max-w-[256px] lg:h-[312px] h-[192px] bg-cover wow fadeInUp cursor-pointer relative"
                                            style={{
                                                backgroundImage: `url(${lists[3].card_image?.src})`
                                            }}
                                        >
                                            {lists[3].video ? (
                                                <img
                                                    src="/images/play-icon-trans.svg"
                                                    alt="Play"
                                                    className="absolute w-16 bottom-6 right-6"
                                                />
                                            ) : (
                                                ' '
                                            )}
                                        </div>
                                        {lists[3]?.caption && (
                                            <p
                                                className="lead p-4 bg-white lg:w-[448px]"
                                                dangerouslySetInnerHTML={{
                                                    __html: lists[3]?.caption
                                                }}
                                            />
                                        )}
                                    </div>
                                )}
                            </div>
                            <div className="flex flex-0">
                                {lists[4] && (
                                    <div>
                                        <div
                                            onClick={(e) => {
                                                e.preventDefault();
                                                lists[4].video
                                                    ? setModalState({
                                                          open: true,
                                                          details: lists[4]
                                                      })
                                                    : {};
                                            }}
                                            className="lg:w-[288px] lg:h-[216px]  w-[200px] h-[150px] bg-cover wow fadeInRight cursor-pointer relative"
                                            style={{
                                                backgroundImage: `url(${lists[4].card_image?.src})`
                                            }}
                                        >
                                            {lists[4].video ? (
                                                <img
                                                    src="/images/play-icon-trans.svg"
                                                    alt="Play"
                                                    className="absolute w-16 bottom-6 right-6"
                                                />
                                            ) : (
                                                ' '
                                            )}
                                        </div>
                                        {lists[4]?.caption && (
                                            <p
                                                className="lead p-4 bg-white lg:w-[288px]"
                                                dangerouslySetInnerHTML={{
                                                    __html: lists[4]?.caption
                                                }}
                                            />
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Transition
                className="relative z-[100]"
                show={modalState.open}
                enter="duration-200 ease-out"
                enterFrom="opacity-0"
                enterTo="opacity-100 scale-100"
                leave="duration-100 ease-in"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
            >
                <div className="fixed top-0 left-0 flex flex-col w-full h-full min-h-screen px-4 pt-4 bg-gradient-11">
                    <div className="flex items-center justify-end px-6 pt-4 flex-0">
                        <a
                            href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                setModalState({
                                    open: false,
                                    details: {}
                                });
                            }}
                            className={`flex items-center font-medium  lead opacity-80 hover:opacity-100 ${
                                !modalState.open
                                    ? 'text-purple-midnight'
                                    : 'text-white'
                            }`}
                        >
                            <span className="min-w-[42px]">
                                {!modalState.open ? `Menu` : 'Close'}
                            </span>
                            <span
                                className={`${
                                    modalState.open
                                        ? 'icon-expaded'
                                        : 'icon-collapse'
                                } relative h-6 w-6 flex-col justify-between items-center flex ml-3`}
                            >
                                <span
                                    className={`absolute inline-block w-1 h-1 rounded-10 ${
                                        !modalState.open
                                            ? 'bg-purple-midnight'
                                            : 'bg-white'
                                    }`}
                                ></span>
                                <span
                                    className={`relative top-0 inline-block w-1 h-1 rounded-10 ${
                                        !modalState.open
                                            ? 'bg-purple-midnight'
                                            : 'bg-white'
                                    }`}
                                ></span>
                                <span
                                    className={`inline-block w-1 h-1 rounded-10 ${
                                        !modalState.open
                                            ? 'bg-purple-midnight'
                                            : 'bg-white'
                                    }`}
                                ></span>
                                <span
                                    className={`relative bottom-0 inline-block w-1 h-1 rounded-10 ${
                                        !modalState.open
                                            ? 'bg-purple-midnight'
                                            : 'bg-white'
                                    }`}
                                ></span>
                                <span
                                    className={`absolute bottom-0 inline-block w-1 h-1 rounded-10 ${
                                        !modalState.open
                                            ? 'bg-purple-midnight'
                                            : 'bg-white'
                                    }`}
                                ></span>
                            </span>
                        </a>
                    </div>
                    <div className="flex-1 px-6 lg:px-40 ">
                        <ReactPlayer
                            width="100%"
                            height="100%"
                            className=""
                            url={modalState?.details?.video}
                            controls={true}
                        />
                    </div>
                </div>
            </Transition>
        </>
    );
};
export default OurTeamGrid;
