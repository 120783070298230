import React from 'react';
import { extractRepeaterField } from '../../util/blocks';

import { TestimonialCarousel } from '../../components/enfusion/TestimonialCarousel';

function ACFTestimonialCarousel({ meta, data = [], className, anchor }) {
    const { heading, testimonials_carousel } = data;
    let testimonials;
    let result = [];

    if (testimonials_carousel?.length > 0) {
        testimonials = meta?.testimonials.filter((item, index) => {
            if (testimonials_carousel.includes(String(item.id))) {
                return true;
            }
            return false;
        });

        testimonials_carousel.forEach((item, index) => {
            testimonials.forEach((testimonial, i) => {
                if (item === String(testimonial.id)) {
                    result[index] = testimonial;
                }
            });
        });
    }
    testimonials = result;
    return (
        <TestimonialCarousel
            className={className}
            anchor={anchor}
            heading={heading}
            testimonials={
                testimonials_carousel?.length === 0 ||
                testimonials_carousel?.length === undefined
                    ? meta?.testimonials
                    : testimonials
            }
        />
    );
}

export default ACFTestimonialCarousel;
