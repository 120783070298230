import React from 'react';
import { extractRepeaterField } from '../../util/blocks';

import { InsightCarousel } from '../../components/enfusion/InsightCarousel';

function ACFInsightCarousel({ meta, data, className, anchor, pageProps }) {
    const { posts } = meta;

    const { defaults } = pageProps;
    const { top_heading, main_heading, cta, use_block_default_data } = data;
    let defaultContent = use_block_default_data;
    let sortedPosts = [];
    // Remove duplicate posts
    sortedPosts = posts.filter((value, index) => posts.findIndex(post => (post.id === value.id)) === index);
    
    sortedPosts = sortedPosts.sort(function (a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(b.date) - new Date(a.date);
    });
    
    
    
    
    return (
        <InsightCarousel
            className={className}
            anchor={anchor}
            top_heading={
                defaultContent !== '1'
                    ? top_heading
                    : defaults?.insightsCarousel?.acf_insights_carousel
                          ?.topHeading
            }
            main_heading={
                defaultContent !== '1'
                    ? main_heading
                    : defaults?.insightsCarousel?.acf_insights_carousel
                          ?.mainHeading
            }
            cta={
                defaultContent !== '1'
                    ? cta
                    : defaults?.insightsCarousel?.acf_insights_carousel?.cta
            }
            defaultContent={use_block_default_data}
            items={sortedPosts}
        />
    );
}

export default ACFInsightCarousel;
