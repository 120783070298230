import { CareerValues } from '../../components/enfusion/CareerValues';

function ACFCareerValues({ meta, data, className, anchor }) {
  const { heading, sub_heading, description } = data || {};
  
    return (
        <CareerValues
            className={className}
            anchor={anchor}
            heading={heading}
            sub_heading={sub_heading}
            description={description}
            data={data}
        />
    );
}

export default ACFCareerValues;