import React from 'react';
import { extractRepeaterField } from '../../util/blocks';

import { OurTeamGrid } from '../../components/enfusion/OurTeamGrid';

function ACFOurTeamGrid({ meta, data, className, anchor }) {
    const { top_header, main_header, description } = data;

    let lists = extractRepeaterField(data, 'media');
    lists = lists.map((repeaterItem) => ({
        ...repeaterItem,
        card_image: meta?.images[repeaterItem.card_image] || {}
    }));

    return (
        <OurTeamGrid
            className={className}
            anchor={anchor}
            top_heading={top_header}
            main_heading={main_header}
            description={description}
            lists={lists}
        />
    );
}

export default ACFOurTeamGrid;
