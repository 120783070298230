import React from 'react';
import FeaturedClientStory from '@components/enfusion/FeaturedClientStory';

function ACFFeaturedClientStory({ meta, data, className, anchor }) {
    const heading = data?.heading;
    const posts = meta?.posts ?? [];

    if (Array.isArray(data.carousel)) {
        const featuredCarouselIds = data.carousel.map((str) => Number(str));
        const featuredStories = posts.filter((post) =>
            featuredCarouselIds.includes(post?.id)
        );

        const featuredClientStory = featuredStories.map((post) => {
            const categories = post?.categories;
            const permalink = post?.permalink;
            const clientName = post?.client_name;
            const clientExcerpt = post?.client_exerpt;
            const featuredImage = post?.featured_image;
            const featuredVideo = post?.featured_video;
            const logoImage = post?.logo_image;
            let featuredImageSrc = '';
            if (featuredImage && featuredImage.id) {
                featuredImageSrc = featuredImage.id.url;
            }

            const logoImageSrc = logoImage?.id.url;

            return {
                categories,
                permalink,
                clientName,
                clientExcerpt,
                featuredImage: {
                    featuredImageSrc
                },
                featuredVideo,
                logoImage: {
                    logoImageSrc
                }
            };
        });
        return (
            <FeaturedClientStory
                heading={heading}
                clientStories={featuredClientStory}
                className={className}
                anchor={anchor}
            />
        );
    }
    return (
        <FeaturedClientStory
            heading={heading}
            clientStories={[]}
            className={className}
            anchor={anchor}
        />
    );
}

export default ACFFeaturedClientStory;