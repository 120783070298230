import React, { useState } from 'react';
import { getAbsoluteUrl } from '../../../lib/helpers'
import NextNavLink from '../../util/NextNavLink';



export const RecentAwards = ({
    className = '',
    anchor = '',
    top_heading,
    main_heading,
    cta,
    background,
    light_dark = 'light',
    awards
}) => {
    return (
        <div
            className={`md:py-32 py-16 overflow-hidden bg-cover ${light_dark !== 'light' ? 'text-white' : 'text-purple-midnight'} ${background?.class} ${className}`}
            id={anchor}
        >
            <div className="container">
                <div className="items-end mb-12 md:mb-24 md:flex">
                    <div className="flex-1 wow fadeInUp">
                        {top_heading && <p className="mb-4 font-medium md:mb-6 label text-purple">{top_heading}</p>}
                        {main_heading && <h4 className="md:h3 h2">{main_heading}</h4>}
                    </div>
                    <div className="hidden flex-0 md:block">
                        {cta && (
                            <a
                                target={cta.target}
                                href={getAbsoluteUrl(cta.url)}
                                className={ `inline-flex items-center mb-1 font-medium ${light_dark !== 'light' ? 'text-white' : 'text-purple-midnight'} lead icon-circle`}
                             
                            >
                                {cta.title}
                                <span className="relative flex items-center h-4 ml-4">
                                    <i className="inline-block en-long-arrow text-32 leading-18 relative z-[1]"></i>

                                    <svg
                                        width="32"
                                        height="32"
                                        className="absolute -right-3 -top-2 icon-circle__circle"
                                    >
                                        <circle
                                            cx="16"
                                            cy="16"
                                            r="15"
                                            className="icon-circle__base"
                                        ></circle>
                                        <circle
                                            cx="16"
                                            cy="16"
                                            r="15"
                                            className="icon-circle__fill icon-circle__fill--dark"
                                        ></circle>
                                    </svg>
                                </span>
                            </a>
                        )}
                    </div>
                </div>
              
                <div className="grid gap-8 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2">
               
                    {awards.map((award, i) =>
                        <NextNavLink key={i} target={award?.acf_awards_lnk ? '_blank' : ''}
                            href={award?.acf_awards_lnk
                                        ? award?.acf_awards_lnk
                                : `/awards/${award?.slug}`}
                        >
                            <a onClick={(e) =>{ e.preventDefault(); e.stopPropagation()}} data-wow-delay={`${i / 8}s`}  className={ `pt-3 mb-4 border-t sm:mb-8  ${light_dark !== 'light' ? 'text-white border-white' : 'text-purple-midnight border-purple-midnight'} border-opacity-20 last:mb-0 wow fadeInUp`} >
                           
                            <div className="flex items-center font-medium">
                                <i className="en-ribbon text-purple text-30"></i>
                                    <p className="ml-2 lead lead--small text-purple">{award?.categories && award?.categories[0]?.name}</p>
                            </div>
                            <h5 className="mt-3 mb-3 lead lead--large" dangerouslySetInnerHTML={{ __html: award?.title }}/>
                            <p className="lead lead--small">{award?.organization_title}
                                    <span className="opacity-50">
                                    {award?.organization_title && 
                                        <span className="inline-block px-2">
                                                    /
                                        </span>
                                    }
                                    {getYear(award?.date)}
                                </span>
                            </p>
                            </a>
                        </NextNavLink>
                    )}
                </div>
                <div className="mt-12 md:hidden">
                     {cta && (
                            <a
                                target={cta.target}
                                href={getAbsoluteUrl(cta.url)}
                                className="inline-flex items-center font-medium text-purple-midnight lead icon-circle"
                            >
                                {cta.title}
                                <span className="relative flex items-center h-4 ml-4">
                                    <i className="inline-block en-long-arrow text-32 leading-18 relative z-[1]"></i>

                                    <svg
                                        width="32"
                                        height="32"
                                        className="absolute -right-3 -top-2 icon-circle__circle"
                                    >
                                        <circle
                                            cx="16"
                                            cy="16"
                                            r="15"
                                            className="icon-circle__base"
                                        ></circle>
                                        <circle
                                            cx="16"
                                            cy="16"
                                            r="15"
                                            className="icon-circle__fill icon-circle__fill--dark"
                                        ></circle>
                                    </svg>
                                </span>
                            </a>
                        )}
                </div>
            </div>
            
        </div>
    );
};

function getYear(date){
    let d = new Date(date);
    let getYear = d.getFullYear();
    return getYear
}
export default RecentAwards;
