import React, { useState } from 'react';

import { Transition } from '@headlessui/react';
import NextNavLink from '../../util/NextNavLink';
import { getAbsoluteUrl } from '../../../lib/helpers'

export const TwoColAccordianList2 = ({
    className = '',
    anchor = '',
    lists,
    leftHeading,
    rightHeading,
    leftIcon,
    rightIcon,
    icon,
    top_heading,
    cta
}) => {
    const [disclosureState, setDisclosureState] = useState(false);
    const [modalState, setModalState] = useState({
        open: false,
        link: '',
        isFullScreen: false
    });

    const toggle = (index) => {
        if (disclosureState === index) {
            return setDisclosureState(null);
        }
        setDisclosureState(index);
    };
    return (
        <div
            className={`lg:py-28 py-16 overflow-hidden relative wow fadeInUp ${className}`}
            id={anchor}
        >
            <div className="container relative z-[1]">
                {icon &&
                    (<i className={`${icon?.class} text-40 text-purple block mb-7`} />)
                }
                {top_heading && (
                    <h3
                        className="mb-10 lg:mb-24 lg:max-w-2xl lg:pr-10 md:mb-10 h2 md:h3 wow fadeInUp"
                        dangerouslySetInnerHTML={{
                            __html: top_heading
                        }}
                    />
                )}
                <div className="hidden gap-8 mb-8 md:grid md:grid-cols-2">
                    <div className="flex items-center">
                        {leftIcon && <i className={`${leftIcon?.class} text-purple inline-block mr-4 text-24`}></i>}
                        {leftHeading && (
                            <p className="font-medium label">{leftHeading}</p>
                        )}
                    </div>
                    <div className="flex items-center">
                        {rightIcon && <i className={`${rightIcon?.class} text-purple inline-block mr-4 text-24`}></i>}
                        {rightHeading && (
                            <p className="font-medium label">{rightHeading}</p>
                        )}
                    </div>
                </div>
                <div>
                    {lists &&
                        lists.map((item, index) => (
                            <div
                                className="border-purple-midnight last:border-b border-opacity-10 wow fadeInUp"
                                key={index}
                            >
                                
                                <div
                                    className="grid gap-3 py-8 border-t cursor-pointer md:gap-8 md:grid-cols-2 border-purple-midnight border-opacity-10"
                                    onClick={() => {
                                        toggle('col1' + index);
                                    }}
                                >
                                     <div className="flex items-center sm:hidden">
                                        {leftIcon && <i className={`${leftIcon?.class} text-purple inline-block mr-4 text-24`}></i>}
                                        {leftHeading && (
                                            <p className="font-medium label">{leftHeading}</p>
                                        )}
                                    </div>
                                    <div className="justify-between pl-10 sm:flex sm:pl-0">
                                        <h5
                                            className="flex flex-1 pr-12 mb-3 opacity-60 md:h5 lead lead--large sm:mb-0"
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    item?.left_column_heading
                                            }}
                                        />
                                        <i className="inline-block -ml-2 transform rotate-90 sm:rotate-0 opacity-20 en-long-arrow text-32 sm:m-0"></i>
                                    </div>
                                    <div className="flex items-start justify-between">
                                        <div className="sm:pr-20">
                                             <div className="flex items-center sm:hidden">
                                                {rightIcon && <i className={`${rightIcon?.class} text-purple inline-block mr-4 text-24`}></i>}
                                                {rightHeading && (
                                                    <p className="font-medium label">{rightHeading}</p>
                                                )}
                                            </div>
                                            {item?.right_column_heading && (
                                                <h5
                                                    className="hidden sm:block"
                                                    dangerouslySetInnerHTML={{
                                                        __html:
                                                            item?.right_column_heading
                                                    }}
                                                />
                                            )}
                                          
                                            <Transition
                                                show={disclosureState === 'col1' + index}
                                                className="pl-10 sm:pl-0"
                                            >
                                                {item?.right_column_heading && (
                                                <h5
                                                    className="mt-4 sm:hidden"
                                                    dangerouslySetInnerHTML={{
                                                        __html:
                                                            item?.right_column_heading
                                                    }}
                                                />
                                            )}
                                                {item?.right_column_sub_heading && (
                                                    <div
                                                        className="pt-4 lead text-purple"
                                                        dangerouslySetInnerHTML={{
                                                            __html:
                                                                item?.right_column_sub_heading
                                                        }}
                                                    />
                                                )}
                                                <div
                                                    className="mt-4 lead opacity-70 bullet-list"
                                                    dangerouslySetInnerHTML={{
                                                        __html: item?.right_column_list
                                                    }}
                                                /> 
                                                {item?.cta?.url && 
                                                    <NextNavLink   href={`${item?.style === 'default' ? item?.cta?.url : '#'}`}  >
                                                        <a target={item?.cta?.target}
                                                            onClick={(e) => {
                                                                if (item?.style === 'default') {
                                                                    e.preventDefault();
                                                                   setModalState({
                                                                        open: true,
                                                                        link: item?.cta?.url,
                                                                        isFullScreen: true
                                                                   }); 
                                                                } else {
                                                                    e.preventDefault();
                                                                    setModalState({
                                                                        open: true,
                                                                        link: item?.cta?.url,
                                                                        isFullScreen: item?.style === 'large'
                                                                    });
                                                                }
                                                            }}
                                                            className="inline-flex items-center lead text-purple-midnight hover:text-purple">
                                                            <span dangerouslySetInnerHTML={{ __html: item?.cta?.title }} />
                                                            <i className="ml-4 en-long-arrow text-32"/>
                                                        </a>
                                                    </NextNavLink>
                                                }
                                            </Transition>
                                        </div>
                                         <i
                                            className={`en-caret-down opacity-30 inline-block transform text-14 mt-2 ${
                                                disclosureState ===
                                                'col1' + index
                                                    ? 'rotate-180'
                                                    : ''
                                            }`}
                                        />
                                    </div>
                                </div>
                               
                            </div>
                        ))}
                </div>
                {cta && (
                    <a
                        target={cta.target}
                        href={getAbsoluteUrl(cta.url)}
                        className="inline-flex items-center mt-16 font-medium text-purple-midnight lead icon-circle "
                    >
                        {cta.title}
                        <span className="relative flex items-center h-4 ml-4">
                            <i className="inline-block en-long-arrow text-32 leading-18 relative z-[1]"></i>

                            <svg
                                width="32"
                                height="32"
                                className="absolute -right-3 -top-2 icon-circle__circle"
                            >
                                <circle
                                    cx="16"
                                    cy="16"
                                    r="15"
                                    className="icon-circle__base"
                                ></circle>
                                <circle
                                    cx="16"
                                    cy="16"
                                    r="15"
                                    className="icon-circle__fill icon-circle__fill--dark"
                                ></circle>
                            </svg>
                        </span>
                    </a>
                )}
            </div>
             <Transition
                className={`relative z-[100] ${
                    !modalState?.isFullScreen
                        ? 'lg:p-10 p-6 rounded-10 overflow-hidden'
                        : ''
                }`}
                show={modalState?.open}
                enter="duration-200 ease-out"
                enterFrom="opacity-0"
                enterTo="opacity-100 scale-100"
                leave="duration-100 ease-in"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
            >
                <div
                    className={` z-[99] ${
                         !modalState?.isFullScreen
                            ? 'fixed md:max-w-xl md:max-h-[650px] w-full h-screen top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 md:p-10 p-4 bg-white'
                            : 'fixed top-0 left-0 w-full h-full min-h-screen'
                    }`}
                >
                    <div className="absolute top-0 right-0 z-[1] p-4">
                        <a
                            href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                setModalState({
                                    open: false,
                                    link: '',
                                    isFullScreen: false
                                }); 
                            }}
                            className="flex items-center font-medium lead text-purple-midnight"
                        >
                            <span className="min-w-[42px]">Close</span>
                            <span
                                className={`icon-expaded relative h-6 w-6 flex-col justify-between items-center flex ml-3`}
                            >
                                <span
                                    className={`absolute inline-block w-1 h-1 rounded-10 bg-purple-midnight`}
                                ></span>
                                <span
                                    className={`relative top-0 inline-block w-1 h-1 rounded-10 bg-purple-midnight`}
                                ></span>
                                <span
                                    className={`inline-block w-1 h-1 rounded-10 bg-purple-midnight`}
                                ></span>
                                <span
                                    className={`relative bottom-0 inline-block w-1 h-1 rounded-10 bg-purple-midnight`}
                                ></span>
                                <span
                                    className={`absolute bottom-0 inline-block w-1 h-1 rounded-10 bg-purple-midnight`}
                                ></span>
                            </span>
                        </a>
                    </div>
                    <iframe 
                        src={modalState?.link}
                        className="w-full h-full bg-white"
                    ></iframe>
                </div>
                {!modalState?.isFullScreen ? (
                    <div className="fixed top-0 left-0 w-full h-screen bg-purple-midnight bg-opacity-60 z-[1]"></div>
                ) : (
                    ''
                )}
            </Transition>
        </div>
    );
};
export default TwoColAccordianList2;
