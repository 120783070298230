import React from 'react';
import { extractRepeaterField } from '../../util/blocks';

import { AudioProdcast } from '../../components/enfusion/AudioProdcast';

function ACFAudioProdcast({ meta, data, className, anchor }) {
    let podcasts = meta?.audio_podcasts;
    const { audio_podcast } = data;

    return (
        <AudioProdcast
            className={className}
            anchor={anchor}
            data={podcasts}
            audio_podcasts={audio_podcast}
        />
    );
}

export default ACFAudioProdcast;
