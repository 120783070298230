import React from 'react';
import FeaturedAudioPodcast from '@components/enfusion/FeaturedAudioPodcast';

function ACFFeaturedAudioPodcast({ meta, data, className, anchor }) {
    const heading = data?.heading;
    const posts = meta?.posts ?? [];

    if (Array.isArray(data.carousel)) {
        const featuredCarouselIds = data.carousel.map((str) => Number(str));
        const featuredStories = posts.filter((post) =>
            featuredCarouselIds.includes(post?.id)
        );

        const featuredAudioPodcast = featuredStories.map((post) => {
            // console.log(post)
            const categories = post?.categories;
            const permalink = post?.permalink;
            const podcast_name = post?.podcast_name;
            const podcast_number = post?.podcast_number;
            const featuredImage = post?.featured_image;
            const podcast_exerpt = post?.podcast_exerpt;
            const featuredVideo = post?.featured_video;
            const logoImage = post?.logo_image;
            let featuredImageSrc = '';
            if (featuredImage && featuredImage.id) {
                featuredImageSrc = featuredImage.id.url;
            }

            const logoImageSrc = logoImage?.id.url;

            return {
                categories,
                permalink,
                podcast_name,
                podcast_number,
                podcast_exerpt,
                featuredImage: {
                    featuredImageSrc
                },
                featuredVideo,
                logoImage: {
                    logoImageSrc
                }
            };
        });
        return (
            <FeaturedAudioPodcast
                heading={heading}
                audioPodcast={featuredAudioPodcast}
                className={className}
                anchor={anchor}
            />
        );
    }
    return (
        <FeaturedAudioPodcast
            heading={heading}
            audioPodcast={[]}
            className={className}
            anchor={anchor}
        />
    );
}

export default ACFFeaturedAudioPodcast;