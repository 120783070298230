import React from 'react';
import { extractRepeaterField } from '../../util/blocks';

import { IconContentGrid } from '../../components/enfusion/IconContentGrid';

function ACFIconContentGrid({ meta, data, className, anchor, pageProps }) {
    const { themeAssets, defaults } = pageProps;

    const {
        top_heading,
        main_heading,
        left_content,
        section_content,
        style_options_background,
        style_options_light_dark,
        use_block_default_data
    } = data;
    let defaultData = defaults?.iconContentGrid?.acf_icon_content_grid;

    let background = themeAssets[style_options_background];

    let lists = extractRepeaterField(data, 'grid');

    lists = lists.map((repeaterItem) => ({
        ...repeaterItem,
        icon: themeAssets[repeaterItem.icon] || {}
    }));

    return (
        <IconContentGrid
            className={className}
            anchor={anchor}
            defaultData={use_block_default_data === '1'}
            top_heading={
                use_block_default_data !== '1'
                    ? top_heading
                    : defaultData?.topHeading
            }
            main_heading={
                use_block_default_data !== '1'
                    ? main_heading
                    : defaultData?.mainHeading
            }
            left_content={
                use_block_default_data !== '1'
                    ? left_content
                    : defaultData?.leftContent
            }
            content={
                use_block_default_data !== '1'
                    ? section_content
                    : defaultData?.sectionContent
            }
            background={use_block_default_data !== '1'
                    ? background?.class
                    : defaultData?.styleOptions?.background[0]?.acf_post_type_theme_assets?.class}
            light_dark={use_block_default_data !== '1'
                    ? style_options_light_dark
                    : defaultData?.styleOptions?.lightDark}
            lists={use_block_default_data !== '1'
                    ? lists
                    : defaultData?.grid}
        />
    );
}

export default ACFIconContentGrid;