import React, { useState } from 'react';
import NextNavLink from '../../util/NextNavLink';
import { Transition } from '@headlessui/react';

export const TwoColCTA = ({
    className = '',
    anchor = '',
    headerText,
    secondHeader,
    content,
    cta,
    pardot_link,
    desktopImage,
    mobileImage,
    flip,
    wide
}) => {
    const noPadding = className.search('no-padding-on-text');
    const [modalState, setModalState] = useState(false);
    
    return (
        <>
            <div
                className={`lg:py-24 py-16 overflow-hidden ${className}`}
                id={anchor}
            >
                <div className={`${wide && flip ? '' : 'container'} relative`}>
                    <div
                        className={`${
                            flip
                                ? 'lg:flex-row-reverse flex-col-reverse'
                                : 'lg:flex-row flex-col'
                        } items-center flex`}
                    >
                        <div
                            className={`lg:w-1/2  ${
                                flip
                                    ? `${
                                          noPadding === -1
                                              ? `lg:pl-28 lg:pr-0 px-4`
                                              : ''
                                      } mt-16 lg:mt-0`
                                    : 'lg:pr-28 mb-16 lg:mb-0'
                            }`}
                        >
                            <div
                                className={` ${
                                    wide && flip ? 'max-w-[560px]' : ''
                                }`}
                            >
                                {headerText && (
                                    <h5
                                        className="mb-4 font-medium lg:mb-6 label text-purple wow fadeInUp"
                                        dangerouslySetInnerHTML={{
                                            __html: headerText
                                        }}
                                    />
                                )}
                                {secondHeader && (
                                    <h3
                                        className="mb-4 lg:mb-6 text-purple-midnight h2 md:h3 wow fadeInUp"
                                        dangerouslySetInnerHTML={{
                                            __html: secondHeader
                                        }}
                                    />
                                )}
                                {content && (
                                    <div className="wow fadeInUp">
                                        <div
                                            className="lead lead--large text-purple-midnight opacity-70"
                                            dangerouslySetInnerHTML={{
                                                __html: content
                                            }}
                                        />
                                    </div>
                                )}
                                {cta?.url && (
                                    <NextNavLink
                                        href={cta?.url}
                                        target={cta?.target}
                                    >
                                        <a
                                            onClick={(e) => {
                                                if (
                                                    pardot_link === 'fs' ||
                                                    pardot_link === 'np'
                                                ) {
                                                    e.preventDefault();
                                                    setModalState(true);
                                                } else {
                                                    return;
                                                }
                                            }}
                                            className="inline-block mt-4 btn btn--primary"
                                            dangerouslySetInnerHTML={{
                                                __html: cta?.title
                                            }}
                                        ></a>
                                    </NextNavLink>
                                )}
                            </div>
                        </div>

                        <div className="lg:w-1/2 wow fadeInRight lg:pt-24 pt-0">
                            {wide && (
                                <>
                                    {desktopImage?.src && (
                                        <NextNavLink
                                            href={cta?.url ?? "#"}
                                            target={cta.target}
                                        >
                                            <div
                                                className={`relative w-screen transform lg:max-w-[50vw] top-0 lg:-left-2 cursor-pointer  ${
                                                    flip
                                                        ? '-left-7 md:left-auto'
                                                        : '-right-7 md:right-auto'
                                                } ${
                                                    mobileImage?.src
                                                        ? 'md:block hidden'
                                                        : ''
                                                }`}
                                            >
                                                <img
                                                    src={desktopImage?.src}
                                                    alt={
                                                        desktopImage?.title ||
                                                        'enfusion'
                                                    }
                                                    className="w-full"
                                                    onClick={(e) => {
                                                        if (
                                                            pardot_link ===
                                                                'fs' ||
                                                            pardot_link === 'np'
                                                        ) {
                                                            e.preventDefault();
                                                            setModalState(true);
                                                        } else {
                                                            return;
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </NextNavLink>
                                    )}
                                    {mobileImage?.src && (
                                        <NextNavLink
                                            href={cta?.url ?? "#"}
                                            target={cta.target}
                                        >
                                            <div
                                                className={`relative transform lg:max-w-[50vw] lg:-left-2 lg:pl-0 -right-7 top-0 cursor-pointer${
                                                    mobileImage?.src
                                                        ? 'md:hidden'
                                                        : ''
                                                }`}
                                            >
                                                <img
                                                    src={mobileImage?.src}
                                                    alt={
                                                        mobileImage?.title ||
                                                        'enfusion'
                                                    }
                                                    className="w-full"
                                                    onClick={(e) => {
                                                        if (
                                                            pardot_link ===
                                                                'fs' ||
                                                            pardot_link === 'np'
                                                        ) {
                                                            e.preventDefault();
                                                            setModalState(true);
                                                        } else {
                                                            return;
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </NextNavLink>
                                    )}
                                </>
                            )}
                            {!wide && (
                                <>
                                    {desktopImage?.src && (
                                        <NextNavLink
                                            href={cta?.url ?? "#"}
                                            target={cta.target}
                                        >
                                            <div
                                                className={`relative text-center cursor-pointer ${
                                                    mobileImage?.src
                                                        ? 'md:block hidden'
                                                        : ''
                                                }`}
                                            >
                                                <img
                                                    src={desktopImage?.src}
                                                    alt={
                                                        desktopImage?.title ||
                                                        'enfusion'
                                                    }
                                                    className={`w-full max-w-max mx-auto`}
                                                    onClick={(e) => {
                                                        if (
                                                            pardot_link ===
                                                                'fs' ||
                                                            pardot_link === 'np'
                                                        ) {
                                                            e.preventDefault();
                                                            setModalState(true);
                                                        } else {
                                                            return;
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </NextNavLink>
                                    )}
                                    {mobileImage?.src && (
                                        <NextNavLink
                                            href={cta?.url ?? "#"}
                                            target={cta.target}
                                        >
                                            <div
                                                className={`relative cursor-pointer ${
                                                    mobileImage?.src
                                                        ? 'md:hidden'
                                                        : ''
                                                }`}
                                            >
                                                <img
                                                    src={mobileImage?.src}
                                                    alt={
                                                        mobileImage?.title ||
                                                        'enfusion'
                                                    }
                                                    className={`w-full `}
                                                    onClick={(e) => {
                                                        if (
                                                            pardot_link ===
                                                                'fs' ||
                                                            pardot_link === 'np'
                                                        ) {
                                                            e.preventDefault();
                                                            setModalState(true);
                                                        } else {
                                                            return;
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </NextNavLink>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {pardot_link && (
                <Transition
                    className={`relative z-[100] ${
                        pardot_link === 'np'
                            ? 'lg:p-10 p-6 rounded-10 overflow-hidden'
                            : ''
                    }`}
                    show={modalState}
                    enter="duration-200 ease-out"
                    enterFrom="opacity-0"
                    enterTo="opacity-100 scale-100"
                    leave="duration-100 ease-in"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <div
                        className={` z-[99] ${
                            pardot_link === 'np'
                                ? 'fixed md:max-w-xl md:max-h-[650px] w-full h-screen top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 md:p-10 p-4 bg-white'
                                : 'fixed top-0 left-0 w-full h-full min-h-screen'
                        }`}
                    >
                        <div className="absolute top-0 right-0 z-[1] p-4">
                            <a
                                href="#"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setModalState(false);
                                }}
                                className="flex items-center font-medium lead text-purple-midnight"
                            >
                                <span className="min-w-[42px]">Close</span>
                                <span
                                    className={`icon-expaded relative h-6 w-6 flex-col justify-between items-center flex ml-3`}
                                >
                                    <span
                                        className={`absolute inline-block w-1 h-1 rounded-10 bg-purple-midnight`}
                                    ></span>
                                    <span
                                        className={`relative top-0 inline-block w-1 h-1 rounded-10 bg-purple-midnight`}
                                    ></span>
                                    <span
                                        className={`inline-block w-1 h-1 rounded-10 bg-purple-midnight`}
                                    ></span>
                                    <span
                                        className={`relative bottom-0 inline-block w-1 h-1 rounded-10 bg-purple-midnight`}
                                    ></span>
                                    <span
                                        className={`absolute bottom-0 inline-block w-1 h-1 rounded-10 bg-purple-midnight`}
                                    ></span>
                                </span>
                            </a>
                        </div>
                        <iframe
                            id="pardot-iframe"
                            src={cta?.url}
                            className="w-full h-full bg-white"
                        ></iframe>
                    </div>
                    {pardot_link === 'np' ? (
                        <div className="fixed top-0 left-0 w-full h-screen bg-purple-midnight bg-opacity-60 z-[1]"></div>
                    ) : (
                        ''
                    )}
                </Transition>
            )}
        </>
    );
};
export default TwoColCTA;
