import NextNavLink from '../../util/NextNavLink';

export default function Category({ item, index, last }) {
    return (
        <>
            <CategoryMobile item={item} index={index} isLast={last} />
            <div
                className={`hidden invisible lg:visible  lg:flex flex-col border-t border-purple-midnight border-opacity-20`}
            >
                <i
                    className={`${item.icon.class} text-purple text-40  mb-4 inline-block pt-6`}
                ></i>
                <div
                    className="grid gap-6  mb-4  md:mb-8 md:gap-8 sm:grid-cols-2  wow fadeInUp"
                    key={index}
                    data-wow-delay={`${index / 7}s`}
                >
                    <div>
                        {item?.main_heading && (
                            <h5
                                className="mb-4 md:h5 h4"
                                dangerouslySetInnerHTML={{
                                    __html: item?.main_heading
                                }}
                            />
                        )}
                        {item?.description && (
                            <div
                                className="lead opacity-70"
                                dangerouslySetInnerHTML={{
                                    __html: item?.description
                                }}
                            />
                        )}
                    </div>
                    <ScrollItems item={item} />
                </div>
            </div>
        </>
    );
}

function CategoryMobile({ item, index, isLast }) {
    let borderClasses = '';
    if (!isLast) {
        borderClasses = ' border-b border-purple-midnight border-opacity-20';
    }
    return (
        <div
            className={`lg:hidden flex flex-col  py-6 cursor-pointer ${borderClasses}`}
        >
            <details className="partners__category-mobile">
                <summary className=" flex flex-row items-center">
                    <i
                        className={`${item.icon.class} text-purple text-24  inline `}
                    />
                    {item?.main_heading && (
                        <span
                            className="text-18 px-3"
                            dangerouslySetInnerHTML={{
                                __html: item?.main_heading
                            }}
                        />
                    )}
                </summary>
                {item?.description && (
                    <div
                        className="lead opacity-70 py-6"
                        dangerouslySetInnerHTML={{
                            __html: item?.description
                        }}
                    />
                )}
                <ScrollItems item={item} />
            </details>
        </div>
    );
}

function ScrollItems({ item }) {
    return (
        <div className="flex flex-col overflow-hidden shadow-partners border border-solid border-gray-19 rounded-10 pl-8 max-h-[18em]">
            <div
                className="overflow-y-auto scrollbar-thin "
                style={{
                    scrollbarWidth: 'thin'
                }}
            >
                {item?.links &&
                    item?.links.map((link, i) => (
                        <>
                            {link?.title && (
                                <p
                                    className="pt-0.5 my-2 font-medium  first:mt-2"
                                    dangerouslySetInnerHTML={{
                                        __html: link?.title
                                    }}
                                ></p>
                            )}
                            {link?.link?.url && link?.link?.title && (
                                <NextNavLink href={link?.link?.url || ''}>
                                    <a
                                        className="inline-block mb-2.5 text-purple-midnight opacity-70 hover:opacity-100"
                                        dangerouslySetInnerHTML={{
                                            __html: link?.link?.title
                                        }}
                                    />
                                </NextNavLink>
                            )}
                        </>
                    ))}
            </div>
        </div>
    );
}
