import React from 'react';

export default function Play(props) {
    const { handleClick } = props;

    return (
        <button
            className="inline-flex items-center justify-center w-10 h-10 pl-1.5 text-center bg-purple rounded-30 hover:bg-purple-medium transition focus:outline-none"
            onClick={() => handleClick()}
        >
            <i className="text-white en-play text-20"></i>
        </button>
    );
}
