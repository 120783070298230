import React from 'react';
import { extractRepeaterField } from '../../util/blocks';

import { PageHero2Compact } from '../../components/enfusion/PageHero2Compact';

function ACFPageHero2Compact({
    meta,
    data = [],
    className,
    anchor,
    pageProps
}) {
    const { themeAssets } = pageProps;

    const {
        style_options_dark__light,
        description,
        main_header,
        style_options_background,
        top_header,
        background_image
    } = data;
    let background = themeAssets[style_options_background];
    let backgroundImage = meta?.images[background_image];

    return (
        <PageHero2Compact
            className={className}
            anchor={anchor}
            darkLight={style_options_dark__light}
            content={description}
            main_header={main_header}
            breadcrumbs={top_header || pageProps?.title}
            background={background}
            backgroundImage={backgroundImage}
        />
    );
}

export default ACFPageHero2Compact;
