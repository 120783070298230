import React from 'react';

function CoreColumns({ children, className }) {
    const extractContent = (text) => {
        const regex = /class_prefix\(([^)]+)\)/;
        const match = regex.exec(text);
        return match ? match[1] : undefined;
    };
    
    const classPrefix = extractContent(className);
    if(className){
        className = className.replace(`class_prefix(${classPrefix})`,'').trim();
    }
    return (
        <div className={`${className} grid ${classPrefix}grid-cols-${children.length}`}>
            {children}
        </div>
    );
}
export default CoreColumns;