import React, { useState } from 'react';
import { Transition } from '@headlessui/react';
import NextNavLink from '../../util/NextNavLink';
import ButtonPopup from '../ButtonPopup';
import { getAbsoluteUrl } from '../../../lib/helpers';

export const CTAContentList = ({
    className = '',
    anchor = '',
    mainHeading = '',
    secondHeading = '',
    lists,
    cta
}) => {
    const [modalState, setModalState] = useState(false);
    const [modalUrl, setModalUrl] = useState('');

    return (
        <div
            className={`md:pb-32 pb-16 overflow-hidden ${className}`}
            id={anchor}
        >
            <div className="container">
                <div className="pb-8 mb-6">
                    <h4 className="text-purple">{mainHeading}</h4>
                    <h4>{secondHeading}</h4>
                </div>
                <div>
                    {lists &&
                        lists.map((item, i) => (
                            <div
                                key={i}
                                data-wow-delay={`${i / 7}s`}
                                className="mb-12 last:mb-0 wow fadeInUp md:pb-8 md:mb-8 md:border-b md:grid md:grid-cols-12 gap-14 border-opacity-20 border-purple-midnight first:md:border-t first:pt-8 lg:pl-2"
                            >
                                <div className="col-span-4 mb-5 text-center md:border-r border-opacity-20  border-purple-midnight  md:mb-0">
                                    <div className="flex items-center text-left h-full">
                                        <i class="en-circle-check text-purple mr-8 text-24"></i>
                                        <div>
                                            {item.heading && (
                                                <h6
                                                    dangerouslySetInnerHTML={{
                                                        __html: item.heading
                                                    }}
                                                />
                                            )}
                                            {item.heading2 && (
                                                <h6
                                                    className="text-purple"
                                                    dangerouslySetInnerHTML={{
                                                        __html: item.heading2
                                                    }}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-span-8">
                                    <div className="grid-cols-12 md:grid">
                                        <div className="col-span-8 mb-4 md:pr-16 md:mb-0">
                                            <div className="flex items-center h-full">
                                                {item.text_area && (
                                                    <div
                                                        className="lead"
                                                        dangerouslySetInnerHTML={{
                                                            __html:
                                                                item.text_area
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-span-2 border-opacity-20 border-purple-midnight md:pl-14 ">
                                            {item.cta.url && (
                                                <a
                                                    className="inline-block whitespace-nowrap btn btn--o-primary"
                                                    target={item.cta.target}
                                                    onClick={(e) => {
                                                        if (
                                                            !document.body.querySelector(
                                                                '#translations-page'
                                                            )
                                                        ) {
                                                            e.preventDefault();
                                                            setModalState(true);
                                                            setModalUrl(
                                                                item.cta.url
                                                            );
                                                        } else {
                                                            setModalState(
                                                                false
                                                            );
                                                        }
                                                    }}
                                                    href={item.cta.url}
                                                    pardot={true}
                                                >
                                                    {item?.cta.title}
                                                </a>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                </div>
                <div className="flex flex-col mt-6 md:space-x-10 md:mt-16 md:flex-row">
                    {cta.map((cta, index) => (
                        <NextNavLink
                            target={cta.link.target}
                            rel="noopener noreferrer"
                            href={cta.link.url}
                        >
                            <a
                                key={index}
                                className="inline-flex items-center mt-10 font-medium md:mt-0 text-purple-midnight lead icon-circle "
                            >
                                {cta?.link.title}
                                <span className="relative flex items-center h-4 ml-4">
                                    <i className="inline-block en-long-arrow text-32 leading-18 relative z-[1]"></i>

                                    <svg
                                        width="32"
                                        height="32"
                                        className="absolute -right-3 -top-2 icon-circle__circle"
                                    >
                                        <circle
                                            cx="16"
                                            cy="16"
                                            r="15"
                                            className="icon-circle__base"
                                        ></circle>
                                        <circle
                                            cx="16"
                                            cy="16"
                                            r="15"
                                            className="icon-circle__fill icon-circle__fill--dark"
                                        ></circle>
                                    </svg>
                                </span>
                            </a>
                        </NextNavLink>
                    ))}

                    <Transition
                        className={
                            'relative z-[100] lg:p-10 p-6 rounded-10 overflow-hidden'
                        }
                        show={modalState}
                        enter="duration-200 ease-out"
                        enterFrom="opacity-0"
                        enterTo="opacity-100 scale-100"
                        leave="duration-100 ease-in"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div className="fixed top-0 left-0 w-full h-full bg-purple-midnight bg-opacity-60 z-[1]"></div>
                        <div
                            className={
                                ' z-[100] fixed md:max-w-xl md:max-h-[650px] w-full md:h-1/2 h-full top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 md:p-10 p-4 bg-white'
                            }
                        >
                            <div className="absolute top-0 right-0 z-[1] p-4">
                                <a
                                    href="#"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setModalState(false);
                                    }}
                                    className="flex items-center font-medium lead text-purple-midnight"
                                >
                                    <span className="min-w-[42px]">Close</span>
                                    <span
                                        className={`icon-expaded relative h-6 w-6 flex-col justify-between items-center flex ml-3`}
                                    >
                                        <span
                                            className={`absolute inline-block w-1 h-1 rounded-10 bg-purple-midnight`}
                                        ></span>
                                        <span
                                            className={`relative top-0 inline-block w-1 h-1 rounded-10 bg-purple-midnight`}
                                        ></span>
                                        <span
                                            className={`inline-block w-1 h-1 rounded-10 bg-purple-midnight`}
                                        ></span>
                                        <span
                                            className={`relative bottom-0 inline-block w-1 h-1 rounded-10 bg-purple-midnight`}
                                        ></span>
                                        <span
                                            className={`absolute bottom-0 inline-block w-1 h-1 rounded-10 bg-purple-midnight`}
                                        ></span>
                                    </span>
                                </a>
                            </div>
                            <iframe
                                src={modalUrl}
                                className="w-full h-full bg-white"
                            ></iframe>
                        </div>
                    </Transition>
                </div>
            </div>
        </div>
    );
};
export default CTAContentList;
