import React from 'react';
import NewAudioPodcastsCarousel from '@components/enfusion/NewAudioPodcastsCarousel';

function ACFNewAudioPodcastsCarousel({ meta, data, className, anchor }) {
    const { heading, hide_filters } = data;
    const posts = meta?.posts;
    let categoriesMap = {
        cat: [],
        subcategories: []
    };
    let newAudioPodcast = posts.map((post) => {
        const categories = post.categories;
        const title = post.title;
        const permalink = post.permalink;
        const podcast_number = post.podcast_number;
        const featuredImage = post.featured_image;
        const featuredVideo = post.featured_video;
        const logoImage = post.logo_image;
        let featuredImageSrc = '';
        if (featuredImage && featuredImage.id) {
            featuredImageSrc = featuredImage.id.url;
        }
        const logoImageSrc = logoImage?.id?.url;
         /* This code is iterating over an array of categories and checking if each category has a
        parent of 0 (meaning it is a top-level category) and if its name is not already included in
        the `categoriesMap.cat` array. If both conditions are true, it adds the category name to the
        `categoriesMap.cat` array. */
        
        // if(categories){
        //     categories.forEach((category) => {
        //         if (
        //             category.parent === 0 &&
        //             !categoriesMap.cat.find(x => x.id === category.term_id)
        //         ) {
        //             categoriesMap.cat.push({category: category.name, id: category.term_id});
        //         }
        //         if (
        //             category.parent !== 0 &&
        //             !categoriesMap.subcategories.find(x => x.id === category.term_id)
        //         ) {
        //             categoriesMap.subcategories.push({category: category.name, id: category.term_id});
        //         }
        //     });
        // }
        
        // const parentCategory = categoriesMap.subcategories.find(x => x.term_id === categories[0].parent)
        // console.log(categories[0],categoriesMap.subcategories,parentCategory)
        return {
            category: categories?.find((category) => category.parent === 0)
            ?.name,
        // subcategories: categories
        //     ?.filter((cat) => cat.parent !== 0)
        //     .map((cat) => cat.name),
            title,
            podcast_number,
            permalink,
            featuredImage: {
                featuredImageSrc: featuredImageSrc
            },
            featuredVideo,
            logoImage: {
                logoImageSrc: logoImageSrc
            }
        };
    });
    return (
        <NewAudioPodcastsCarousel
            heading={heading}
            className={className}
            hideFilters={hide_filters}
            anchor={anchor}
            newAudioPodcasts={newAudioPodcast}
        />
    );
}

export default ACFNewAudioPodcastsCarousel;