import React, { useState, useEffect } from 'react';

import SwiperCore, { Pagination, Navigation, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
SwiperCore.use([Pagination, Navigation, Autoplay]);

export const EmployeesCarousel = ({
    className = '',
    anchor = '',
    top_heading,
    main_heading,
    background = '',
    light_dark,
    items = []
}) => {
    useEffect(() => {
        var maxHeight = -1;
        document.querySelectorAll('.carousel-content').forEach((item) => {
            maxHeight = maxHeight > item.clientHeight ? maxHeight : item.clientHeight;
        })
        document.querySelectorAll('.carousel-content').forEach((item) => {
           item.style.minHeight =  maxHeight+'px'
        })
   
  }, [])

    return (
        <div className={`relative md:pt-32 md:pb-16 py-16 wow fadeInUp  overflow-hidden ${light_dark !== '1' ? 'text-purple-midnight' : 'text-white'} ${className} `}
            id={anchor}
        >
            
            <div className="container relative z-[1]">
                 {top_heading && (
                        <p
                            className="mb-4 font-medium md:mb-6 label text-purple wow fadeInUp"
                            dangerouslySetInnerHTML={{
                                __html: top_heading
                            }}
                        />
                    )}
                    {main_heading && (
                        <h3
                            className="mb-12 lg:mb-16 md:h3 h2 wow fadeInUp"
                            dangerouslySetInnerHTML={{
                                __html: main_heading
                            }}
                        />
                )}
                
                 <div className="swiper-auto-width">
                    <div className="full-screen-right">
                        <Swiper
                            autoplay={{
                                delay: 5000
                            }}
                            spaceBetween={48}
                            slidesPerView="auto"
                            loop={true}
                            navigation={{
                                prevEl: '.employees-prev',
                                nextEl: '.employees-next'
                            }}
                            pagination={{
                                clickable: true,
                                el: '.employees-custom-pagination'
                            }}
                            breakpoints={{
                                768: {
                                    spaceBetween:128
                                }
                            }}
                        >
                            {items && items.map((item, index) => (
                                <SwiperSlide key={index}>
                                    <div className="md:w-full md:max-w-md w-[240px] pt-4 border-t border-purple-midnight border-opacity-20">
                                        <div className="pt-6 mb-2 h1 text-[72px] text-purple leading-28">“</div>
                                        {item?.content && <div className="lead md:lead--large carousel-content" dangerouslySetInnerHTML={{ __html: item?.content }}></div>}
                                        <div className="pt-5 mt-5 border-t md:pt-8 md:mt-8 border-purple-midnight border-opacity-20">
                                            <div className="flex items-center space-x-8">
                                                <div className="md:w-32 md:h-32 h-[88px] w-[88px] rounded-[100px] flex items-center justify-center p-8 text-center bg-cover bg-center bg-gray-17" style={item?.image?.medium_url && { backgroundImage: `url(${item?.image?.medium_url || item?.image?.src})` }}>
                                                    {!item?.image?.medium_url || !item?.image?.src ? <p className="text-12">Image not found</p> : ''}
                                                </div>
                                                <div>
                                                    {item?.name && <h5 className="mb-1 md:mb-2 md:h5 lead">{item?.name}</h5>}
                                                    {item?.position && <p className="mb-2 lead md:lead-normal lead--small text-purple" dangerouslySetInnerHTML={{__html:item?.position}}></p>}
                                                    {item?.location && <p className="opacity-50 label md:label--small label--xs">{item?.location}</p>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                    <div className="flex items-center justify-between mt-10 md:mt-24">
                        <div className="cursor-pointer employees-prev">
                            <i className="inline-block transform rotate-180 en-long-arrow text-32 text-purple-midnight"></i>
                        </div>
                        <div className="employees-custom-pagination"></div>
                        <div className="cursor-pointer employees-next">
                            <i className="en-long-arrow text-32 text-purple-midnight"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${background?.class ? background?.class : 'bg-gradient-13'} ${light_dark !== '1' ? 'opacity-20' : 'opacity-100'} absolute top-0 lef-0 w-full h-full bg-center `}></div>
        </div>
    );
};
export default EmployeesCarousel;
