import React, { useState, useEffect } from 'react';

export const Pagination = ({ className = '', anchor = '', title }) => {
    const order = [
        'Portfolio Management',
        'Order and Execution Management',
        'Analytics',
        'Accounting',
        'Connectivity, Data, and APIs',
        'Account Management and Client Support'
    ];
    let curr = order.indexOf(title);
    return (
        <div
            className={`relative overflow-hidden py-10 ${className} `}
            id={anchor}
        >
            <div className="container relative z-[1]">
                <div className="flex justify-between ">
                    <div>
                        <a
                            href=""
                            className="flex items-center font-medium text-purple-midnight lead icon-circle"
                        >
                            <span className="relative flex items-center h-4 mr-4 transform rotate-180">
                                <i className="inline-block en-long-arrow text-32 leading-18 relative z-[1]"></i>

                                <svg
                                    width="32"
                                    height="32"
                                    className="absolute -right-3 -top-2 icon-circle__circle"
                                >
                                    <circle
                                        cx="16"
                                        cy="16"
                                        r="15"
                                        className="icon-circle__base"
                                    ></circle>
                                    <circle
                                        cx="16"
                                        cy="16"
                                        r="15"
                                        className="icon-circle__fill icon-circle__fill--dark"
                                    ></circle>
                                </svg>
                            </span>
                            Previous Feature
                        </a>
                    </div>
                    <div>
                        <a
                            href=""
                            className="flex items-center font-medium text-purple-midnight lead icon-circle"
                        >
                            Next Feature
                            <span className="relative flex items-center h-4 ml-4">
                                <i className="inline-block en-long-arrow text-32 leading-18 relative z-[1]"></i>

                                <svg
                                    width="32"
                                    height="32"
                                    className="absolute -right-3 -top-2 icon-circle__circle"
                                >
                                    <circle
                                        cx="16"
                                        cy="16"
                                        r="15"
                                        className="icon-circle__base"
                                    ></circle>
                                    <circle
                                        cx="16"
                                        cy="16"
                                        r="15"
                                        className="icon-circle__fill icon-circle__fill--dark"
                                    ></circle>
                                </svg>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Pagination;
