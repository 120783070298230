import React from 'react';
import { CareersCarousel } from '../../components/enfusion/CareersCarousel';
import { extractRepeaterField } from '@util/blocks';

function ACFCareersCarousel({ meta, data, className, anchor, pageProps }) {
    const { posts } = meta;

    const { defaults } = pageProps;
    const { top_heading, main_heading, content, gallery } = data;
    let slides = extractRepeaterField(data, 'slides');
    const nSlider = slides.map((row,i) => {
        const sliderImages = extractRepeaterField(row, 'slider_images');
        return sliderImages.map((m,g) => meta?.images[m.image]);
    });
    const items = gallery.map((repeaterItem,i) => {
        const newItem = {... meta?.images[repeaterItem] || {},sliderImages: nSlider.length === 0 ? undefined : nSlider[i]}
        return newItem;
    });
    // console.log(items);
    return (
        <CareersCarousel
            className={className}
            anchor={anchor}
            top_heading={
                top_heading
            }
            main_heading={
                     main_heading
            }
            content={
                content
            }
            items={items}
        />
    );
}

export default ACFCareersCarousel;
