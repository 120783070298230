import React, { useState, useEffect, useRef } from 'react';
import SwiperCore, { Pagination, Autoplay, Navigation } from 'swiper';
import NextNavLink from '../../util/NextNavLink';
import { useAppContext } from '../../../util/context';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useCallback } from 'react';
SwiperCore.use([Pagination, Autoplay, Navigation]);

export const TextSlider = ({ className = '', anchor = '', slides = [], autoplay = true }) => {
    const context = useAppContext();
    const [showMobile, setShowMobile] = useState(false);
    const swiperRef = useRef(null);
    const sliderRef = useRef(null);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            function handleResize() {
                setShowMobile(window.innerWidth <= 1024);
            }
            handleResize();
            window.addEventListener('resize', handleResize);
            return () => {
                window.removeEventListener('resize', handleResize);
            };
        }
    }, []);



    const handlePrev = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slidePrev();
    }, []);

    const handleNext = useCallback(() => {
        console.log('No of slides',slides.length);
        console.log('No of slides',sliderRef.current.swiper.clickedIndex);
        
        if (!sliderRef.current) return;
        // if(sliderRef.current.swiper.clickedIndex === slides.length){
        //     sliderRef.current.swiper.swipeTo(0);
        // }
        sliderRef.current.swiper.slideNext();
    }, []);

    return (
        <div
            className={`${className} md:h-2/5 md:min-h-[400px] relative mb-2 bg-slate-200`}
            id={anchor}
        >
            <Swiper ref={sliderRef}
                autoplay={{
                    delay: 5000,
                }}
                loop={true}
                modules={[Autoplay, Pagination, Navigation]}
                // navigationNext={(e) => console.log(e)}
                // spaceBetween={0}
                // slidesPerView={1}
                // pagination={{
                //     el: '.swiper-custom-pagination',
                //     clickable: true,
                // }}
                // navigation={{
                //     nextEl: '.swiper-button-next',
                //     prevEl: '.swiper-button-prev',
                // }}
                // onInit={(swiper) => handleSwiperInit(swiper)}
                // onSlideChange={(swiper) => handleSlideChange(swiper)}
                // className={`${context?.dark ? '' : 'swiper-light'} h-full`}
                // breakpoints={{
                //     300: {
                //         slidesPerView: 1,
                //     },
                //     480: {
                //         slidesPerView: 1,
                //     },
                //     640: {
                //         slidesPerView: 1,
                //     },
                //     1024: {
                //         slidesPerView: 1,
                //     },
                // }}
            >
                {slides.map((slide, index) => (
                    <SwiperSlide
                        className=" md:px-8 md:py-8 py-4 px-4 w-full"
                        key={index}
                        id={`text_slide_${index + 1}`}
                    >
                        <h6 className="text-sm flex w-full px-8 text-purple uppercase text-slider-heading">
                            {slide.heading}
                        </h6>
                        <div className="flex items-center mb-3 h-full bg-cover overflow-hidden relative bg-center">
                            <div className="container relative z-[1] md:h-auto h-full">
                            <div className="grid grid-cols-1 md:grid-cols-1 md:gap-0 md:py-0 py-2 px-4 md:px-8 md:py-8 text-slider-desc">
                                    {slide?.content && (
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: slide?.content,
                                            }}
                                        />
                                    )}
                                    <div className="py-6">
                                        <div
                                            className="grid grid-cols-2 md:grid-cols-2"
                                            style={{ gridTemplateColumns: '30% 70%' }}
                                        >
                                            <div className="">
                                                {slide?.avatar && (
                                                    <img
                                                        className="w-28 rounded-full testimonial-img"
                                                        src={slide?.avatar?.medium_url}
                                                        alt="Avatar"
                                                    />
                                                )}
                                            </div>

                                            <div className="testimonial-bio">
                                                {slide?.name ? (
                                                    <p
                                                        dangerouslySetInnerHTML={{
                                                            __html: slide?.name,
                                                        }}
                                                        className="testimonial-name"
                                                    />
                                                ) : (
                                                    <p className="testimonial-name">
                                                        Default Name
                                                    </p>
                                                )}
                                                {slide?.designation ? (
                                                    <p
                                                        dangerouslySetInnerHTML={{
                                                            __html: slide?.designation,
                                                        }}
                                                        className="testimonial-designation"
                                                    />
                                                ) : (
                                                    <p className="testimonial-designation">
                                                        Default Designation
                                                    </p>
                                                )}
                                                {slide?.department ? (
                                                    <p
                                                        dangerouslySetInnerHTML={{
                                                            __html: slide?.department,
                                                        }}
                                                        className="testimonial-department"
                                                    />
                                                ) : (
                                                    <p className="testimonial-department">
                                                        Default Department
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="swiper-button-next flex items-end justify-end py-4 leading-20 wow fadeInUp text-purple-midnight group hover:text-purple cursor-pointer" onClick={handleNext}>
                                        <i className="transition en-long-arrow text-20 group-hover:text-purple group-hover:opacity-100"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>

        </div>
    );
};

export default TextSlider;
