import React, { useState } from 'react';

import NextNavLink from '../../util/NextNavLink';
export const BulletList = ({ className = '', anchor = '', lists }) => {
    return (
        <div className={`my-6 wow fadeInUp ${className}`} id={anchor}>
            <ul>
                {lists &&
                    lists.map((item, i) => (
                        <li
                            key={i}
                            className="flex items-start mb-5 lead lead--large text-purple-midnight "
                        >
                            <i class="inline-block w-2.5 min-w-[0.66rem] h-2.5 mr-5 border-2 rounded-10 border-purple mt-2"></i>
                            <div className="opacity-70">
                                {item.list_item_text}
                            </div>
                        </li>
                    ))}
            </ul>
        </div>
    );
};
export default BulletList;
