import React from 'react';
import { extractRepeaterField } from '../../util/blocks';

import { TwoColTextSlider } from '../../components/enfusion/TwoColTextSlider';

function ACFTwoColTextSlider({ meta, data, className, anchor }) {
    const {
        header_text,
        second_header,
        content
    } = data;
    let slides = extractRepeaterField(data, 'slides');
    slides = slides.map((item,i) => {
        let image = meta?.images[item.avatar];
        return {...item, avatar: image};
    })
    // console.log(items)
    return (
        <TwoColTextSlider
            className={className}
            anchor={anchor}
            headerText={header_text}
            secondHeader={second_header}
            content={content}
            slides={slides}
        />
    );
}

export default ACFTwoColTextSlider;
