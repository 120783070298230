import React from 'react';
import { EyebrowBlock } from '../../components/enfusion/EyebrowBlock';

function ACFEyebrowBlock({ data, className, anchor }) {
    const { heading } = data;

    return (
        <EyebrowBlock
            className={className}
            anchor={anchor}
            heading={heading}
        />
    );
}

export default ACFEyebrowBlock;
