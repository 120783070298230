import React, { useState } from 'react';

import Audio from './Audio/Audio';

export const AudioProdcast = ({
    className = '',
    anchor = '',
    audio_podcasts = [],
    data = []
}) => {
    return (
        <div className={`pb-16 ${className}`} id={anchor}>
            <div className="container">
                {audio_podcasts.map((item, index) => (
                    <div key={index}>
                        {/* {data[item]?.title && (
                            <h3
                                className="mb-10 md:mb-14 md:h3 h2"
                                dangerouslySetInnerHTML={{
                                    __html: data[item]?.title
                                }}
                            />
                        )} */}
                        {/*                        
                        {data[item]?.content && (
                            <div
                                className="lead lead--large"
                                dangerouslySetInnerHTML={{
                                    __html: data[item]?.content
                                }}
                            />
                        )} */}
                        {data[item]?.audio_file && (
                            <Audio source={data[item]?.audio_file} />
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};
export default AudioProdcast;
