import { useEffect, useContext } from 'react';
import UTMContext from './utm-context';

export const sendUTMsToIframe = () => {

  const { utmStorageSet } = useContext(UTMContext);

  function replacer(match, p1) {
    return p1 + '_first' + '=';
  }

  useEffect(() => {

    // Check if UTM parameters are set in storage
    if (utmStorageSet) {
      // Retrieve first touch data
      const firstTouchObj = JSON.parse(localStorage.getItem('first_touch'));
      const firstTouchQuery = firstTouchObj.query;
      const firstTouchReferrer = firstTouchObj.referrer;
      const firstTouchLanding = firstTouchObj.url;

      // Replace instances of utm_xx with utm_xx_first
      const regex = /(\w+)\=/g
      const firstTouchQueryUpdated = firstTouchQuery.replace(regex, replacer);

      // Retrieve last touch data
      const lastTouchObj = JSON.parse(localStorage.getItem('last_touch'));
      const lastTouchQuery = lastTouchObj.query;
      const lastTouchReferrer = lastTouchObj.referrer;
      const lastTouchLanding = lastTouchObj.url;

      // Create a string with all the data
      const newQuery =
        (firstTouchQueryUpdated || '') +
        (firstTouchReferrer ? '&referrer_first=' + encodeURIComponent(firstTouchReferrer) : '') +
        (firstTouchLanding ? '&landing_url_first=' + encodeURIComponent(firstTouchLanding) : '') +
        (lastTouchQuery ? '&' + lastTouchQuery : '') +
        (lastTouchReferrer ? '&referrer=' + encodeURIComponent(lastTouchReferrer) : '') +
        (lastTouchLanding ? '&landing_url=' + encodeURIComponent(lastTouchLanding) : '');

      // Send data to iframe
      const iframe = document.getElementById('pardot-iframe');
      if (iframe) {
        iframe.src = iframe.src.split('?')[0] + '?' + newQuery;
      }

    }

  }, [utmStorageSet])

}