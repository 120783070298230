import React, { useEffect, useState } from 'react'
import ReactPlayer from 'react-player';

const PlayPausePlayer = ({ url }) => {
    const [controlsVisible, setControlsVisible] = useState(true);
    const [playing, setPlaying] = useState(false);
    let timer = 0;
    useEffect(() => {
        if (playing) {
            timer = setTimeout(() => {
                setControlsVisible(false);
            }, 5000);
        } else {
            clearTimeout(timer);
            setControlsVisible(true);
        }
    }, [controlsVisible, playing]);

    return (
        <div className='flex relative items-center justify-center w-full aspect-h-9 aspect-w-16' onClick={() => setControlsVisible(true)}>
            <ReactPlayer
                url={url}
                playing={playing}
                loop
                muted
                playsinline
                controls={true}
                width="100%"
                height="100%"
                className="object-cover"
            />
            {/* <div className={`absolute bottom-0 left-0 w-full h-full transition-opacity duration-500 ${controlsVisible ? 'opacity-100' : 'opacity-0'}`}>
                <button style={{ borderRadius: '50%' }} className='flex items-center justify-center absolute top-1/2 left-1/2 focus:outline-none transform -translate-x-1/2 -translate-y-1/2 w-[75px] h-[75px] bg-black bg-opacity-70' onClick={() => setPlaying(!playing)}>
                    <svg className='w-12 focus:outline-none h-12 text-white fill-current' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d={playing ? "M3 22v-20h4v20h-4zM15 2v20h4v-20h-4z" : "M3 22v-20l18 10-18 10z"} /></svg>
                </button>
            </div> */}
        </div>
    )
}

export default PlayPausePlayer