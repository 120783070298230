import React, { useState, useEffect } from 'react';
import NextNavLink from '../../util/NextNavLink';
import SwiperCore, { Pagination, Navigation, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

SwiperCore.use([Pagination, Navigation, Autoplay]);
import ReactPlayer from 'react-player';
import 'swiper/swiper.min.css';
import 'swiper/css/navigation';

export const FeaturedAudioPodcast = ({
    heading,
    audioPodcast,
    className = '',
    anchor = ''
}) => {
    const [currentSlide, setCurrentSlide] = useState(1);
    const [isClient, setIsClient] = useState(false);

    useEffect(() => {
        setIsClient(true);
    }, []);

    const pagination = {
        clickable: true,
        el: '.featured-pagination',
        renderBullet: function (index, className) {
            return `<span class="${className}"></span>`;
        }
    };
    const noPagination = {
        renderBullet: function (index, className) {
            return ``;
        }
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentSlide(currentSlide === audioPodcast.length - 1 ? 0 : currentSlide + 1);
        }, 30000); // 30 seconds

        return () => clearInterval(interval);
    }, [currentSlide, audioPodcast.length]);


    return audioPodcast?.length > 0 ? (
        <div
            className={`relative wow fadeInUp overflow-hidden pt-0 pb-12 md:pt-20 md:pb-20 ${className} `}
            id={anchor}
        >
            <div className="container relative z-[1]">
                <h2 className="text-2xl mb-7 flex w-full text-purple podcastheading uppercase">
                    {heading}
                </h2>
                <Swiper
                    spaceBetween={0}
                    slidesPerView="auto"
                    loop={true}
                    navigation={{ prevEl: '.swiper-button-prev', nextEl: '.swiper-button-next' }}
                    autoPlay={{ delay: 30000 }}
                    className="podcast-carousel-bullets"
                    onSlideChange={(swiper) => setCurrentSlide(swiper.realIndex)}
                >
                    {audioPodcast &&
                        audioPodcast.map((item, index) => (
                            <SwiperSlide key={index}>
                                <div className="flex md:flex-row flex-col ">
                                    <div className="md:w-1/2 w-full flex justify-start ml-[2px]">
                                        {isClient && item.featuredVideo && (
                                            <ReactPlayer
                                                playIcon={
                                                    <img
                                                        className="mx-auto cursor-pointer"
                                                        src="/images/play-alternate.svg"
                                                        alt="Play"
                                                        style={{
                                                            position: 'static',
                                                            height: '59px',
                                                            width: '59px'
                                                        }}
                                                    ></img>
                                                }
                                                light={
                                                    item?.featuredImage
                                                        ?.featuredImageSrc
                                                }
                                                width="640px"
                                                height="320px"
                                                className="podcast-featured-video"
                                                autoPlay={false}
                                                playing={false}
                                                playsinline={true}
                                                loop={true}
                                                muted={true}
                                                controls={true}
                                                url={item.featuredVideo}
                                                config={{
                                                    vimeo: {
                                                        playerOptions: {
                                                            playsinline: 1,
                                                            muted: true
                                                        }
                                                    }
                                                }}
                                            />
                                        )}
                                        {!item.featuredVideo &&
                                        item.featuredImage.featuredImageSrc ? (
                                            <div className="relative w-full md:h-[360px] h-[300px]">
                                                <img
                                                    className="absolute w-full h-full inset-0 object-cover"
                                                    src={
                                                        item?.featuredImage
                                                            ?.featuredImageSrc
                                                    }
                                                    alt="Featured image"
                                                />
                                            </div>
                                        ) : !item.featuredVideo &&
                                          !item.featuredImage
                                              .featuredImageSrc ? (
                                            <img
                                                src="/images/enfusion-placeholder.png"
                                                alt="Enfusion"
                                            />
                                        ) : null}
                                    </div>

                                    <div className="md:w-1/2 w-full md:pl-16 custom:pl-0 px-0">
                                        <NextNavLink href={item.permalink}>
                                            <a className="text-black hover:text-purple h-full flex">
                                                <div className="lg:pr-4 pr-0 md:pt-0 pt-8 flex flex-col">
                                                    <div>
                                                        {item?.logoImage
                                                            .logoImageSrc && (
                                                            <img
                                                                style={{
                                                                    maxHeight:
                                                                        '53px',
                                                                    width: 'auto'
                                                                }}
                                                                src={
                                                                    item
                                                                        ?.logoImage
                                                                        .logoImageSrc
                                                                }
                                                                alt="Featured image"
                                                            />
                                                        )}
                                                        {item?.podcast_name && (
                                                            <div
                                                                className=" podcastname"
                                                                dangerouslySetInnerHTML={{
                                                                    __html: item?.podcast_name
                                                                }}
                                                            />
                                                        )}
                                                    </div>
                                                    <div className="mt-auto">
                                                        <div
                                                            className="podcastexcerpt"
                                                            dangerouslySetInnerHTML={{
                                                                __html: item?.podcast_exerpt
                                                            }}
                                                        />
                                                        <i className="inline-block en-long-arrow text-32 leading-18 relative z-[1] text-purple mt-[25px]"></i>
                                                    </div>
                                                </div>
                                            </a>
                                        </NextNavLink>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                </Swiper>
                <div className='podcast-arrows'>
                    <div className="swiper-button-prev"></div>
                    <div className="swiper-button-next"></div>
                </div>
                {/* <div>
                    {audioPodcast?.length <= 1 ? null : (
                        <div className="hidden md:block md:pt-8">
                            <p className="swiper-tracker bottom-[-6px] custom-2xl xl:right-1/2 md:right-1/2 z-10">
                                {' '}
                                {currentSlide} / {audioPodcast?.length}
                            </p>
                        </div>
                    )}
                    <div className="featured-pagination"></div>{' '}
                </div> */}
                <div>
    {audioPodcast?.length <= 1 ? null : (
        <div className="hidden md:block md:pt-8">
            <p className="swiper-tracker bottom-[-6px] custom-2xl xl:right-1/2 md:right-1/2 z-10">
                {' '}
                {currentSlide + 1} / {audioPodcast?.length}
            </p>
        </div>
    )}
    <div className="featured-pagination"></div>
</div>

            </div>
        </div>
    ) : null;
};

export default FeaturedAudioPodcast;
