import React from 'react';

import { SocialFollow } from '../../components/enfusion/SocialFollow';

function ACSocialFollow({ className, anchor, pageProps }) {
    const { link } = pageProps;
    return <SocialFollow className={className} anchor={anchor} link={link} />;
}

export default ACSocialFollow;
