import React, { useState } from 'react';
import { getAbsoluteUrl } from '../../../lib/helpers';

export const ContactCards = ({
    className = '',
    anchor = '',
    cards,
    main_heading,
    top_heading
}) => {
    return (
        <>
            <div
                className={`wow fadeInUp md:py-20 py-16 ${className}`}
                id={anchor}
            >
                <div className="container">
                    {top_heading && (
                        <p
                            className="mb-4 font-medium md:mb-6 label text-purple wow fadeInUp"
                            dangerouslySetInnerHTML={{
                                __html: top_heading
                            }}
                        />
                    )}
                    {main_heading && (
                        <h3
                            className="mb-12 lg:mb-24 md:h3 h2 wow fadeInUp"
                            dangerouslySetInnerHTML={{
                                __html: main_heading
                            }}
                        />
                    )}
                    <div className="grid grid-cols-1 gap-12 md:gap-8 lg:grid-cols-3 md:grid-cols-2">
                        {cards &&
                            cards.map((card, index) => (
                                <div
                                    className="pt-4 mb-0 border-t md:mb-8 border-opacity-20 border-purple-midnight"
                                    key={index}
                                >
                                    <h5 className="mb-4">{card.location}</h5>
                                    <div
                                        className="mb-4 opacity-70"
                                        dangerouslySetInnerHTML={{
                                            __html: card.address
                                        }}
                                    />

                                    {card?.phoneNumber1 && (
                                        <p className="mb-2">
                                            <a
                                                className="text-purple-midnight opacity-70"
                                                href={`tel:${card.phoneNumber1}`}
                                            >
                                                {card?.phoneNumber1}
                                            </a>
                                        </p>
                                    )}

                                    {card?.phoneNumber2 && (
                                        <p className="mb-4">
                                            <a
                                                className=" text-purple-midnight opacity-70"
                                                href={`tel:${card?.phoneNumber2}`}
                                            >
                                                {card?.phoneNumber2}
                                            </a>{' '}
                                        </p>
                                    )}
                                    {card?.directionsLink?.url && (
                                        <a
                                            target="_blank"
                                            className="inline-flex items-center"
                                            href={getAbsoluteUrl(
                                                card?.directionsLink?.url
                                            )}
                                        >
                                            {card?.directionsLink?.title}{' '}
                                            <i className="ml-4 en-long-arrow text-32"></i>
                                        </a>
                                    )}
                                </div>
                            ))}
                    </div>
                </div>
            </div>
        </>
    );
};
export default ContactCards;
