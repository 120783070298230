import React from 'react';
import { extractRepeaterField } from '../../util/blocks';

import { EmployeesCarousel } from '../../components/enfusion/EmployeesCarousel';
import { StackLayout } from '../../components/enfusion/EmployeesCarousel/StackLayout';

function ACFEmployeesCarousel({
    meta,
    data = [],
    className,
    anchor,
    pageProps
}) {
    const { themeAssets } = pageProps;
    const { posts } = meta;

    let quotes;
    let result = [];

    const {
        top_heading,
        main_heading,
        style_options_background,
        style_options_light_dark,
        style_options_layout,
        select_quotes
    } = data;

    if (select_quotes?.length > 0) {
        quotes = posts.filter((item, index) => {
            if (select_quotes.includes(String(item.id))) {
                return true;
            }
            return false;
        });

        select_quotes.forEach((item, index) => {
            quotes.forEach((post, i) => {
                if (item === String(post.id)) {
                    result[index] = post;
                }
            });
        });
    }
    quotes = result;
    let background = themeAssets[style_options_background];
    if (style_options_layout == 'stack') {
        return (
            <StackLayout
                className={className}
                anchor={anchor}
                top_heading={top_heading}
                main_heading={main_heading}
                background={background}
                light_dark={style_options_light_dark}
                items={quotes}
            />
        );
    } else {
        return (
            <EmployeesCarousel
                className={className}
                anchor={anchor}
                top_heading={top_heading}
                main_heading={main_heading}
                background={background}
                light_dark={style_options_light_dark}
                items={quotes}
            />
        );
    }
}

export default ACFEmployeesCarousel;
