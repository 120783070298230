import React from 'react';
import { extractRepeaterField } from '../../util/blocks';

import { ContactCards } from '../../components/enfusion/ContactCards';

function ACFContactCards({ meta, data, className, anchor, pageProps }) {
    const { defaults } = pageProps;
    const { main_heading, top_heading } = pageProps;
    const { use_block_default_data } = data;
    let cards = extractRepeaterField(data, 'contact_cards');
    cards = cards.map((repeaterItem) => ({
        ...repeaterItem,
        phoneNumber1: repeaterItem.phone_number_1 || '',
        phoneNumber2: repeaterItem.phone_number_2 || '',
        directionsLink: repeaterItem.directions_link || ''
    }));

    return (
        <ContactCards
            className={className}
            anchor={anchor}
            cards={
                use_block_default_data === '1'
                    ? defaults.contactCard.acf_contact_cards.contactCards
                    : cards
            }
            main_heading={
                use_block_default_data !== '1'
                    ? main_heading
                    : defaults.contactCard.acf_contact_cards.mainHeading
            }
            top_heading={
                use_block_default_data !== '1'
                    ? top_heading
                    : defaults.contactCard.acf_contact_cards.topHeading
            }
        />
    );
}

export default ACFContactCards;
