import React, { useState } from 'react';

import NextNavLink from '../../util/NextNavLink';
export const Quote = ({
    className = '',
    anchor = '',
    content,
    citation,
    author
}) => {
    return (
        <div
            className={`container mb-6 editor wow fadeInUp ${className}`}
            id={anchor}
        >
            <div className="pt-4 mb-4 h1 text-purple leading-28">“</div>
            {content && (
                <h4
                    className="mb-6 md:h4 h3"
                    dangerouslySetInnerHTML={{ __html: content }}
                />
            )}
            {author && (
                <div
                    className="lead opacity-70"
                    dangerouslySetInnerHTML={{ __html: author }}
                />
            )}
        </div>
    );
};
export default Quote;
