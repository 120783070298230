import React from 'react';

function CoreButtons({ children, anchor = '', className = '' }) {
    return (
        <div
            className={`container mb-6 editor wow fadeInUp ${className}`}
            id={anchor}
        >
            {children}
        </div>
    );
}

export default CoreButtons;
