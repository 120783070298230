import React from 'react';

export const CareerValues = ({ className, anchor, heading, data, sub_heading, description }) => {
    const values = Array.from(Array(data.values), Math.random);
    return (
        <div className={`${className} py-24 container`} id={anchor}>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                <div className='flex-col flex-start flex justify-center mb-16 md:mb-0'>
                    {sub_heading && (
                        <h5
                            className="mb-4 font-medium lg:mb-6 label text-purple wow fadeInUp"
                            dangerouslySetInnerHTML={{
                                __html: sub_heading
                            }}
                        />
                    )}
                    {heading && (
                        <h3
                            className="mb-4 lg:mb-6 text-purple-midnight h2 md:h3 wow fadeInUp"
                            dangerouslySetInnerHTML={{
                                __html: heading
                            }}
                        />
                    )}
                    {description && (
                        <div className="wow fadeInUp">
                            <div
                                className="text-16 lead lead--large text-purple-midnight opacity-70"
                                dangerouslySetInnerHTML={{
                                    __html: description
                                }}
                                style={{ width: '80%'}}
                            />
                        </div>
                    )}
                </div>
                {values ? (
                    <div className="flex flex-wrap justify-evenly gap-y-4 container mx-auto">
                        <div className="flex items-center justify-center w-full">
                            <div className="border-t border-[#4578E5] flex-grow"></div>
                            <span className="mx-2 text-[#7D5FFF] font-semibold">Core Values</span>
                            <div className="border-t border-[#4578E5] flex-grow"></div>
                        </div>
                        <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                            {values.map(
                                (value, i) =>
                                    data[`values_${i}_name`] && (

                                        <div className='wow fadeInUp' key={i} id={`values_${i + 1}`}>
                                            <p className={`text-10 md:text-[14px] text-purple leading-[30px] values-head`}>
                                                {data[`values_${i}_name`]}
                                            </p>
                                            <p className="text-10 md:text-[14px] text-purple-midnight career-values values-desc">
                                                {
                                                    data[`values_${i}_description`]
                                                }
                                            </p>
                                        </div>

                                    )
                            )}
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default CareerValues;
