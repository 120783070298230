import React, { useState } from 'react';
import { getAbsoluteUrl } from '../../../lib/helpers';
import TextSlider from '../TextSlider';

export const TwoColTextSlider = ({
    className = '',
    anchor = '',
    headerText,
    secondHeader,
    content,
    slides
}) => {
    const noPadding = className.search('no-padding-on-text');

    return (
        <div
            className={`lg:py-24 py-16 overflow-hidden container ${className}`}
            id={anchor}
        >
            <div className={`grid grid-cols-1 md:grid-cols-2 flex-col items-center flex`}>
                <div className={`lg:mt-0 lg:pr-28 mb-16 lg:mb-0`}>
                    <div
                        // className={` ${
                        //     wide && flip ? 'max-w-[560px]' : ''
                        // }`}
                    >
                        {headerText && (
                            <h5
                                className="mb-4 font-medium lg:mb-6 label text-purple wow fadeInUp"
                                dangerouslySetInnerHTML={{
                                    __html: headerText
                                }}
                            />
                        )}
                        {secondHeader && (
                            <h3
                                className="mb-4 lg:mb-6 text-purple-midnight h2 md:h3 wow fadeInUp"
                                dangerouslySetInnerHTML={{
                                    __html: secondHeader
                                }}
                            />
                        )}
                        {content && (
                            <div className="wow fadeInUp">
                                <div
                                    className="lead lead--large text-purple-midnight opacity-70"
                                    dangerouslySetInnerHTML={{
                                        __html: content
                                    }}
                                />
                            </div>
                        )}
                        {/* {!button && cta && (
                            <div className="mt-8 lg:mt-12 wow fadeInUp">
                                <a
                                    href={getAbsoluteUrl(cta.url)}
                                    className="cursor-pointer link link--primary"
                                    target={cta.target}
                                >
                                    {cta.title}
                                </a>
                            </div>
                        )} */}
                    </div>
                </div>

                <div className="wow fadeInRight">
                    <TextSlider slides={slides} autoplay={true}/>
                </div>
            </div>
        </div>
    );
};
export default TwoColTextSlider;
