import React from 'react';
import { extractRepeaterField } from '../../util/blocks';

import { Brochure2 } from '../../components/enfusion/Brochure2';

function ACFBrochure({ meta, data = [], className, anchor, pageProps }) {
    const { themeAssets } = pageProps;

    const {
        brochure_image,
        brochure_link,
        brochure_link_text,
        brochure_top_heading,
        brochure_main_heading,
        brochure_description,
        brochure_full_screen_popup,
        brochure_background,
        brochure_cta
    } = data;

    let image = meta?.images[brochure_image];

    return (
        <Brochure2
            className={className}
            anchor={anchor}
            image={image}
            top_heading={brochure_top_heading}
            main_heading={brochure_main_heading}
            description={brochure_description}
            ctaText={brochure_link_text}
            link={meta?.download?.enter_url || meta?.download?.attach_file}
            pardot={meta?.download?.pardot && meta?.download?.enter_url !== ''}
            background={themeAssets[brochure_background]}
            isFullScreen={brochure_full_screen_popup}
            cta={brochure_cta}
        />
    );
}

export default ACFBrochure;
