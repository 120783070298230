import React, { useState, useEffect } from 'react';

export const LegalText = ({ className = '', anchor = '', content }) => {
    return (
        <div
            className={`relative wow fadeInUp overflow-hidden ${className} `}
            id={anchor}
        >
            <div className="container relative z-[1]">
                {content &&
                    content.map((item, index) => (
                        <div key={index} className="pb-10 mb-10">
                            <div
                                className={` lead lead opacity-100 mb-10  text-justify legal-text-content `}
                                dangerouslySetInnerHTML={{
                                    __html: item?.content
                                }}
                            />
                            <p className="mb-4 text-center lead">
                                {item?.page_number || index + 1}
                            </p>
                            <div className="max-w-3xl mx-auto border-t border-purple-midnight border-opacity-20"></div>
                        </div>
                    ))}
            </div>
        </div>
    );
};
export default LegalText;
