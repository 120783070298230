import { Menu, Transition } from '@headlessui/react';
import { Fragment, useEffect, useRef, useState } from 'react';

export default function Dropdown({ options, onChange, selected }) {
    const boxTitle = !!selected ? selected : 'All';
    return (
        <div className=" top-16 w-56 text-right z-10">
            <Menu as="div" className="relative inline-block text-left">
                <div
                    style={{
                        filter: 'drop-shadow(0px 1px 8px rgba(0, 0, 0, 0.12))'
                    }}
                >
                    <Menu.Button className="inline-flex w-56 justify-between rounded-md bg-white  px-4 py-2 text-sm font-medium text-purple-midnight focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                        {boxTitle}
                        <svg
                            width="12"
                            height="8"
                            viewBox="0 0 12 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="ml-2 -mr-1 h-5 w-5 text-violet-200 hover:text-violet-100"
                        >
                            <path
                                d="M1.41 0.295044L6 4.87504L10.59 0.295044L12 1.70504L6 7.70504L1.68141e-08 1.70504L1.41 0.295044Z"
                                fill="#1E1A34"
                            />
                        </svg>
                    </Menu.Button>
                </div>
                <Menu.Items
                    style={{
                        filter: 'drop-shadow(0px 1px 8px rgba(0, 0, 0, 0.12))'
                    }}
                    className="absolute left-0 mt-2 w-[610px] origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                    <div className="px-1 py-1 flex flex-row flex-wrap ">
                        <Menu.Item>
                            {({ active }) => (
                                <button
                                    className={`${
                                        active || !selected
                                            ? 'bg-purple-midnight text-white'
                                            : 'bg-gray-18 text-purple-midnight/50'
                                    } group flex  items-center rounded-20 m-2 py-2 px-4 text-sm`}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        onChange('all');
                                    }}
                                >
                                    {'All'}
                                </button>
                            )}
                        </Menu.Item>
                        {options.map((option) => (
                            <Menu.Item>
                                {({ active }) => (
                                    <button
                                        className={`${
                                            active || selected === option
                                                ? 'bg-purple-midnight text-white'
                                                : 'bg-gray-18 text-purple-midnight/50'
                                        } group flex  items-center rounded-20 m-2 py-2 px-8 text-sm`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            onChange(option);
                                        }}
                                    >
                                        {option}
                                    </button>
                                )}
                            </Menu.Item>
                        ))}
                    </div>
                </Menu.Items>
            </Menu>
        </div>
    );
}
