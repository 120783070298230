import { Transition } from '@headlessui/react';
import React from 'react';
import { useState, useRef, useEffect } from 'react';

export const SimpleCTA = ({ top_heading, title, content, cta, className, anchor }) => {
    const [modalState, setModalState] = useState(false);
    const [modalUrl, setModalUrl] = useState('');
    const contentRef = useRef(null);
    const isFullScreen = '';

    useEffect(() => {
        const enlargeImage = (imgElement) => {
            setModalUrl(imgElement.src);
            setModalState(true);
        };

        const contentDiv = contentRef.current;
        const imageNodes = contentDiv.getElementsByTagName('img');
        for (let i = 0; i < imageNodes.length; i++) {
            imageNodes[i].addEventListener('click', function () {
                enlargeImage(this);
            });
        }

        // Cleanup event listeners on component unmount
        return () => {
            for (let i = 0; i < imageNodes.length; i++) {
                imageNodes[i].removeEventListener('click', function () {
                    enlargeImage(this);
                });
            }
        };
    }, []);

    return (
        <div
            id={anchor}
            className={`${className} ${!!cta.url ? 'bg-gray-19' : ''}`}
        >
            <div className="container py-[62px] pb-[62px]">
                {top_heading && (
                    <div className="text-center">
                        <h4
                            className="text-center label text-purple wow fadeInUp"
                            dangerouslySetInnerHTML={{
                                __html: top_heading
                            }}
                        />
                    </div>
                )}
                {title ? (
                    <h2 className="text-center font-semibold text-20 md:text-24 leading-32 py-[15px]">
                        {title}
                    </h2>
                ) : (
                    <></>
                )}

                <div
                    ref={contentRef}
                    className="p text-center font-normal text-20 md:text-24 mx-auto leading-[35px] py-[15px]"
                    dangerouslySetInnerHTML={{
                        __html: content
                    }}
                ></div>
                {!!cta?.url && (
                    <div className="text-center pt-[15px]">
                        <a
                            href={cta.url}
                            className="cursor-pointer btn btn--primary"
                        >
                            {cta.title}
                        </a>
                    </div>
                )}
            </div>
            <Transition
                className={`relative z-[100] ${
                    isFullScreen === '0'
                        ? 'lg:p-10 p-6 rounded-10 overflow-hidden'
                        : ''
                }`}
                show={modalState}
                enter="duration-200 ease-out"
                enterFrom="opacity-0"
                enterTo="opacity-100 scale-100"
                leave="duration-100 ease-in"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
            >
                <div
                    className={`fixed inset-0 flex items-center justify-center bg-white bg-opacity-75 z-[99]`}
                >
                    <div className="absolute top-0 right-0 z-[1] p-4">
                        <a
                            href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                setModalState(false);
                            }}
                            className="flex items-center font-medium text-purple-midnight"
                        >
                            <span className="min-w-[42px]">Close</span>
                            <span
                                className={`icon-expanded relative h-6 w-6 flex-col justify-between items-center flex ml-3`}
                            >
                                <span
                                    className={`absolute inline-block w-1 h-1 rounded-10 bg-purple-midnight`}
                                ></span>
                                <span
                                    className={`relative top-0 inline-block w-1 h-1 rounded-10 bg-purple-midnight`}
                                ></span>
                                <span
                                    className={`inline-block w-1 h-1 rounded-10 bg-purple-midnight`}
                                ></span>
                                <span
                                    className={`relative bottom-0 inline-block w-1 h-1 rounded-10 bg-purple-midnight`}
                                ></span>
                                <span
                                    className={`absolute bottom-0 inline-block w-1 h-1 rounded-10 bg-purple-midnight`}
                                ></span>
                            </span>
                        </a>
                    </div>
                    <img src={modalUrl} className="max-w-full max-h-full object-contain"/>
                </div>
                {isFullScreen === '0' ? (
                    <div className="fixed top-0 left-0 w-full h-screen bg-purple-midnight bg-opacity-60 z-[1]"></div>
                ) : (
                    ''
                )}
            </Transition>
        </div>
    );
};

export default SimpleCTA;
