import React, { useState } from 'react';

export const NumberModule = ({
    className = '',
    anchor = '',
    top_heading,
    main_heading,
    grids,
    light_dark,
    background
}) => {
    return (
        <div
            className={`overflow-hidden bg-cover wow fadeInUp bg-left-bottom ${
                light_dark === 'light' ? 'text-white' : 'text-purple-midnight'
            } ${background ? background : 'md:py-16'} ${className}`}
            id={anchor}
        >
            <div className="flex">
                <div className="flex-1"></div>
                <div className="flex">
                    <div className="container py-16 md:py-16 ">
                        {top_heading && (
                            <p
                                className={`mb-4 md:mb-6 label font-medium ${
                                    light_dark === 'light'
                                        ? 'text-purple-periwinkle'
                                        : 'text-purple'
                                }`}
                                dangerouslySetInnerHTML={{
                                    __html: top_heading
                                }}
                            />
                        )}
                        {main_heading && (
                            <h4
                                className="md:h3 mb-11 h2"
                                dangerouslySetInnerHTML={{
                                    __html: main_heading
                                }}
                            />
                        )}
                        <div className="grid gap-12 md:gap-16 md:grid-cols-3 lg:gap-32 lg:pr-24">
                            {grids &&
                                grids.map((item, i) => (
                                    <div className="wow fadeInUp" key={i}>
                                        {item?.number && (
                                            <p className="md:h1 text-[72px] leading-[72px] wow fadeInUp">
                                                {item?.number}
                                                {item?.suffix !== 'None'
                                                    ? item?.suffix
                                                    : ''}
                                            </p>
                                        )}
                                        <div
                                            className={`my-4 border-t ${
                                                light_dark === 'light'
                                                    ? 'border-white'
                                                    : 'border-purple-midnight'
                                            } md:my-5 border-opacity-30 wow fadeInUp`}
                                        ></div>
                                        {item?.heading && (
                                            <p
                                                className={`mb-2 label label--small ${
                                                    light_dark === 'light'
                                                        ? 'text-purple-periwinkle'
                                                        : 'text-purple'
                                                }  wow fadeInUp`}
                                                dangerouslySetInnerHTML={{
                                                    __html: item?.heading
                                                }}
                                            />
                                        )}
                                        {item?.content && (
                                            <div
                                                className="lead opacity-70 wow fadeInUp"
                                                dangerouslySetInnerHTML={{
                                                    __html: item.content
                                                }}
                                            />
                                        )}
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
                <div className="flex-1 bg-white"></div>
            </div>
        </div>
    );
};
export default NumberModule;
