import React, { useState } from 'react';
import SwiperCore, { Pagination, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
SwiperCore.use([Pagination, Navigation]);
import StorySlide from './slide';
import { useWindowDimensions } from '../../../util/hooks';
import Dropdown from './dropdown';

function groupArray(array, groupSize) {
    const groups = [];
    for (let i = 0; i < array.length; i += groupSize) {
        groups.push(array.slice(i, i + groupSize));
    }
    return groups;
}

export const SwiperHandler = ({ groupSlides, category }) => {
    const [currentSlide, setCurrentSlide] = useState(1);
    const { width } = useWindowDimensions();

    const pagination = {
        clickable: true,
        renderBullet: function (index, className) {
            return `<span class="${className}"></span>`;
        }
    };
    const noPagination = {
        renderBullet: function (index, className) {
            return ``;
        }
    };

    const slides = groupSlides(category, width > 768 ? 6 : 1);

    return (
        <Swiper
            loop={slides.length > 1}
            loopAdditionalSlides={1}
            centeredSlides
            className="client-carousel-bullets client-stories-carousel"
            pagination={slides.length <= 1 ? noPagination : pagination}
            allowTouchMove={slides.length <= 1 ? false : true}
            onSlideChange={(swiper) =>
                console.log(setCurrentSlide(swiper.realIndex))
            }
        >
            {slides.map((items, slideIndex) => (
                <SwiperSlide key={slideIndex}>
                    <div className="flex flex-wrap flex-col md:flex-row gap-[35px] w-full">
                        {items.map((stories, idx) => (
                            <StorySlide item={stories} index={idx} key={idx} />
                        ))}
                    </div>
                </SwiperSlide>
            ))}
            {slides.length <= 1 ? null : (
                <p className="swiper-tracker swiper-tracker-position left-auto md:left-[700px] xl:left-[1080px]">{`${
                    (currentSlide % slides.length) + 1
                } / ${slides.length}`}</p>
            )}
        </Swiper>
    );
};

export const ClientStoriesCarousel = ({
    clientStory,
    heading,
    hideFilters,
    className = '',
    anchor = '',
    groups
}) => {
    const [selectedCategory, setSelectedCategory] = useState('');
    // const [currentSlide, setCurrentSlide] = useState(1);

    const clearSelection = () => {
        setSelectedCategory('');
    };

    const groupSlides = (category, itemsPerSlide = 6) => {
        const matchingStories = clientStory.filter((post) => {
            if (post.category === category) {
                if (selectedCategory)
                    return post.subcategories.includes(selectedCategory);
                return true;
            }
            return false;
        });
        const groups = groupArray(matchingStories, itemsPerSlide);
        return groups;
    };

    // Sort categories in ascending order
    const sortedCategories = Array.isArray(groups.cat)
        ? [...groups.cat].sort((a, b) => a.localeCompare(b))
        : [];

    return (
        <div
            className={`relative wow fadeInUp overflow-hidden ${className} `}
            id={anchor}
        >
            {hideFilters == 0 ? (
                <div className="bg-gray-stories shadow-stories">
                    <div className="container flex flex-row py-[18px]">
                        <Dropdown
                            options={groups.subcategories}
                            selected={selectedCategory}
                            onChange={(value) => {
                                if (value === 'all') {
                                    return setSelectedCategory(null);
                                }
                                return setSelectedCategory(value);
                            }}
                        />

                        <button
                            onClick={clearSelection}
                            className="ml-8 text-18 font-normal text-purple"
                        >
                            Clear All
                        </button>
                    </div>
                </div>
            ) : null}
            {sortedCategories.map((category, groupIndex) => {
                if (groupSlides(category).length > 0) {
                    return (
                        <div
                            className="container relative mt-10 mb-16"
                            key={groupIndex}
                        >
                            <h2 className="text-2xl mb-4 flex w-1/2 carouselheading">
                                {category}
                            </h2>
                            <SwiperHandler
                                key={groupIndex}
                                groupSlides={groupSlides}
                                category={category}
                            />
                        </div>
                    );
                } else return null;
            })}
        </div>
    );
};

export default ClientStoriesCarousel;
