import React from 'react';
import { extractRepeaterField } from '../../util/blocks';

import { CareersHero } from '../../components/enfusion/CareersHero';

function ACFCareersHero({ meta, data = [], className, anchor, pageProps }) {
    const { themeAssets } = pageProps;
    const {
        dark__light,
        description,
        main_header,
        second_main_header,
        style_options_background
    } = data;
    let background = themeAssets[style_options_background];
    const links = extractRepeaterField(data, 'cta_links');
    return (
        <CareersHero
            className={className}
            anchor={anchor}
            darkLight={dark__light}
            content={description}
            main_header={main_header}
            second_main_header={second_main_header}
            breadcrumbs={pageProps?.title}
            background={background}
            links={links}
        />
    );
}

export default ACFCareersHero;
