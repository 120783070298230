import React from 'react';
import { extractRepeaterField } from '../../util/blocks';

import { TwoColTextList } from '../../components/enfusion/TwoColTextList';

function ACFTwoColTextList({ meta, data, className, anchor }) {
    const {
        top_header,
        main_heading,
        main_content,
        left_cta,
        left_list_title,
        list_heading,
        list_content,
        cta,
        right_list_title
    } = data;

    let lists = extractRepeaterField(data, 'list');
    let leftlists = extractRepeaterField(data, 'left_list');

    return (
        <TwoColTextList
            className={className}
            anchor={anchor}
            top_header={top_header}
            main_heading={main_heading}
            main_content={main_content}
            left_cta={left_cta}
            left_list_title={left_list_title}
            leftlists={leftlists}
            list_heading={list_heading}
            list_content={list_content}
            lists={lists}
            cta={cta}
            right_list_title={right_list_title}
        />
    );
}

export default ACFTwoColTextList;
