import React from 'react';
import { extractRepeaterField } from '../../util/blocks';

import { PageHero } from '../../components/enfusion/PageHero';

function ACFPageHero({ meta, bid, data = [], className, anchor, pageProps }) {
    const {
        dark__light,
        description,
        title,
        top_heading,
        noPadding,
        inBottom
    } = data;
    return (
        <PageHero
            className={className}
            anchor={anchor}
            darkLight={dark__light}
            content={description}
            title={title}
            breadcrumbs={top_heading || pageProps?.title}
            isBottom={inBottom === '1'}
            noPadding={noPadding === '1'}
            bid={bid}
        />
    );
}

export default ACFPageHero;
