import React from 'react';
import { extractRepeaterField } from '../../util/blocks';

import { CarrersQuote } from '../../components/enfusion/CarrersQuote';

function ACFCarrersQuote({ meta, data = [], className, anchor, pageProps }) {
    const { themeAssets } = pageProps;

    const {
        top_heading,
        bottom_heading,
        style_options_background,
        style_options_background_desktop,
        style_options_background_mobile,
        style_options_light_dark
    } = data;

    let background = themeAssets[style_options_background];
    let bgDesktop = meta?.images[style_options_background_desktop];
    let bgMobile = meta?.images[style_options_background_mobile];

    return (
        <CarrersQuote
            className={className}
            anchor={anchor}
            top_heading={top_heading}
            bottom_heading={bottom_heading}
            background={background}
            bgDesktop={bgDesktop}
            bgMobile={bgMobile}
            light_dark={style_options_light_dark}
        />
    );
}

export default ACFCarrersQuote;
