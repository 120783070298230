import React from 'react';
import { extractRepeaterField } from '../../util/blocks';

import { Quote } from '../../components/enfusion/Quote';

function ACFQuote({ meta, data, className, anchor }) {
    const { content, footer_content, quote_author } = data;

    return (
        <Quote
            className={className}
            content={content}
            citation={footer_content}
            author={quote_author}
        />
    );
}

export default ACFQuote;
