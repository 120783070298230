import React, { useState, useEffect, useRef } from 'react';
import { Transition } from '@headlessui/react';
import { useClickOutside } from '../../../util/hooks';

let departments = [];
let locations = [];
let activeDepartmentFilter, activeLocationFilter;
export const OpenPositions = ({ className = '', anchor = '', data }) => {
    const [jobs, setJobs] = useState([]);
    const [filterJobs, setFilterJobs] = useState([]);
    const [selectedDepartments, setSelectedDepartments] = useState(null);
    const [selectedLocations, setSelectedLocations] = useState(null);
    const [jobSearch, setJobSearch] = useState('');

    /*
     * open state for dropdowns
     */
    const [openDepartments, setOpenDepartments] = useState(false);
    const [openLocations, setOpenLocations] = useState(false);
    const ref1 = useRef();
    const ref2 = useRef();
    useClickOutside(ref1, () => setTimeout(() => setOpenDepartments(false)));
    useClickOutside(ref2, () => setTimeout(() => setOpenLocations(false)));

    useEffect(() => {
        /*
         * Fetch Job data
         */
        async function fetchData() {
            const response = await fetch(
                'https://boards-api.greenhouse.io/v1/boards/enfusion/departments'
            );
            const data = await response.json();
            setJobs(data.departments);
            setFilterJobs(data.departments);
        }
        
        fetchData();
        clearAll();
    }, []);

    let fixChars = (value) => {
        if (value.includes('u0026', 0)) {
            value = value.replace('u0026', '&amp;');
        }

        return value;
    };

    jobs.forEach((department) => {
        if (
            !departments.includes(department?.name) &&
            department.jobs.length > 0
        ) {
            departments.push(department?.name);
        }
        department.jobs.forEach((item) => {
            if (!locations.includes(item?.location?.name)) {
                locations.push(item.location.name);
            }
        });
    });

    const filterJobsFn = () => {
        let filterJobs = jobs.filter((job) => {
            if (
                selectedDepartments
                    ? job.name.includes(selectedDepartments)
                    : true
            ) {
                const matchingJobs = job.jobs.filter((item) => {
                    if (item?.location?.name.includes(selectedLocations)) {
                        return true;
                    }

                    return false;
                });

                return matchingJobs.length > 0;
            }

            return false;
        });

        setFilterJobs(filterJobs);
    };

    const clearAll = () => {
        setJobSearch('');
        setSelectedLocations('');
        setSelectedDepartments('');
        setFilterJobs(jobs);
    };

    useEffect(() => {
        filterJobsFn();
    }, [selectedDepartments, selectedLocations, jobSearch]);
    useEffect(() => {
        filterJobsFn();
    }, []);

    const jobsLength = jobs
        .filter((job) => {
            if (
                selectedDepartments
                    ? job.name.includes(selectedDepartments)
                    : true
            ) {
                const matchingJobs = job.jobs.filter((item) => {
                    if (item?.location?.name.includes(selectedLocations)) {
                        return true;
                    }

                    return false;
                });

                return matchingJobs.length > 0;
            }

            return false;
        })
        .map((item, i) => {
            const matchingJobs = item?.jobs
                ?.filter((job, index) => {
                    if (!selectedLocations) {
                        return true;
                    }

                    return job.location?.name.includes(selectedLocations);
                })
                .filter((job, index) => {
                    return job.title
                        .toLowerCase()
                        .includes(jobSearch?.toLowerCase());
                });

            return matchingJobs.length;
        })
        .reduce((prev, current) => prev + current, 0);

    return (
        <>
            <div className="container">
                <div className="grid gap-8 md:grid-cols-3 ">
                    <div className="col-span-1">
                        <div className="relative">
                            <input
                                className="w-full px-0 py-2 pb-5 border-0 border-b border-opacity-20 border-purple-midnight focus:border-opacity-20 focus:border-purple-midnight lead lead--large focus:outline-none search-filter placeholder-purple-midnight"
                                type="text"
                                placeholder="Search Jobs"
                                value={jobSearch}
                                onChange={(e) => {
                                    setJobSearch(e.target.value);
                                }}
                            />
                            <a
                                href=""
                                className="absolute top-0 right-0 inline-flex items-center justify-center w-12 h-12 text-purple-midnight rounded-30 bg-gray-18 hover:bg-purple-midnight hover:text-white"
                            >
                                <i className="en-search"></i>
                            </a>
                        </div>
                    </div>
                    <div className="relative col-span-1">
                        <button
                            onClick={() => setOpenDepartments(!openDepartments)}
                            className="flex items-center justify-between w-full px-0 py-2 pb-5 border-b border-opacity-20 border-purple-midnight lead lead--large group focus:outline-none"
                        >
                            {activeDepartmentFilter
                                ? activeDepartmentFilter
                                : 'All departments'}
                            <i className="transition-all en-caret-down opacity-20 group-hover:opacity-100 text-14"></i>
                        </button>
                        <Transition
                            show={openDepartments}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                            className="absolute top-0 left-0 w-full h-auto  py-6 shadow z-[2] bg-white max-h-96 overflow-auto"
                        >
                            <div className="flex flex-col" ref={ref1}>
                                {departments &&
                                    departments.map((department, index) => (
                                        <a
                                            href=""
                                            key={index}
                                            className="block px-8 lead text-purple-midnight opacity-70 hover:bg-gray-18 py-1.5"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setSelectedDepartments(
                                                    department
                                                );
                                                setOpenDepartments(false);
                                            }}
                                        >
                                            {department}
                                        </a>
                                    ))}
                            </div>
                        </Transition>
                    </div>
                    <div className="relative col-span-1">
                        <button
                            onClick={() => setOpenLocations(!openLocations)}
                            className="flex items-center justify-between w-full px-0 py-2 pb-5 border-b border-opacity-20 border-purple-midnight lead lead--large group focus:outline-none"
                        >
                            {activeLocationFilter
                                ? activeLocationFilter
                                : 'All Locations'}

                            <i className="transition-all en-caret-down opacity-20 group-hover:opacity-100 text-14"></i>
                        </button>
                        <Transition
                            show={openLocations}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                            className="absolute top-0 left-0 w-full h-auto  py-6 shadow z-[2] bg-white max-h-96 overflow-auto"
                        >
                            <div className="flex flex-col" ref={ref2}>
                                {locations &&
                                    locations.map((location, index) => (
                                        <a
                                            href=""
                                            key={index}
                                            className="block px-8 lead text-purple-midnight opacity-70 hover:bg-gray-18 py-1.5"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setSelectedLocations(location);
                                                setOpenLocations(false);
                                            }}
                                        >
                                            {location}
                                        </a>
                                    ))}
                            </div>
                        </Transition>
                    </div>
                </div>
                <div className="my-12">
                    {jobsLength} Jobs Available
                    <a
                        href=""
                        onClick={(e) => {
                            e.preventDefault();

                            clearAll();
                        }}
                        className="inline-block ml-10 border-b border-purple-midnight border-opacity-20 text-purple-midnight opacity-70 hover:opacity-100 hover:border-opacity-100"
                    >
                        Clear all
                    </a>
                </div>
            </div>
            <div
                className={`relative md:pb-32 py-16 wow fadeInUp ${className}`}
                id={anchor}
            >
                <div className="container relative z-[1]">
                    <div className="mb-8 md:hidden">
                        <p className="font-medium label label--small text-purple">
                            Available Jobs
                        </p>
                    </div>
                    <div className="hidden grid-cols-3 gap-8 mb-8 md:grid">
                        <div className="col-span-1">
                            <p className="font-medium label label--small text-purple">
                                Jobs
                            </p>
                        </div>
                        <div className="col-span-1">
                            <p className="font-medium label label--small text-purple">
                                Department
                            </p>
                        </div>
                        <div className="col-span-1">
                            <p className="font-medium label label--small text-purple">
                                Location
                            </p>
                        </div>
                    </div>
                    <div>
                        {filterJobs &&
                            filterJobs.map((item, i) =>
                                item?.jobs
                                    ?.filter((job, index) => {
                                        if (!selectedLocations) {
                                            return true;
                                        }

                                        return job.location?.name.includes(
                                            selectedLocations
                                        );
                                    })
                                    .filter((job, index) => {
                                        return job.title
                                            .toLowerCase()
                                            .includes(jobSearch?.toLowerCase());
                                    })
                                    .map((job, index) => (
                                        <a
                                            target="_blank"
                                            href={job?.absolute_url}
                                            key={index}
                                            className="grid gap-2 py-6 border-b md:gap-8 md:grid-cols-3 first:border-t border-opacity-10 border-purple-midnight text-purple-midnight group hover:text-purple"
                                        >
                                            <div className="col-span-1">
                                                <h6
                                                    className="pr-8 font-medium md:pr-4 lead md:font-normal"
                                                    dangerouslySetInnerHTML={{
                                                        __html: fixChars(
                                                            job?.title
                                                        )
                                                    }}
                                                />
                                            </div>
                                            <div className="hidden col-span-1 md:block">
                                                <p
                                                    className="lead"
                                                    dangerouslySetInnerHTML={{
                                                        __html: fixChars(
                                                            item?.name
                                                        )
                                                    }}
                                                ></p>
                                            </div>
                                            <div className="col-span-1">
                                                <div className="flex items-center justify-between">
                                                    <p className="pr-2 lead">
                                                        {job?.location &&
                                                            job?.location?.name}
                                                    </p>
                                                    <i className="transform -translate-y-4 en-long-arrow text-20 opacity-20 group-hover:opacity-100 lg:translate-y-0"></i>
                                                </div>
                                            </div>
                                        </a>
                                    ))
                            )}
                    </div>
                </div>
                <div className="absolute top-0 w-full h-full bg-center bg-gradient-13 opacity-20 lef-0"></div>
            </div>
        </>
    );
};
export default OpenPositions;
