import React from 'react';
import { extractRepeaterField } from '../../util/blocks';

import { OurFeatures } from '../../components/enfusion/OurFeatures';

function ACFOurFeatures({ meta, data = [], className, anchor, pageProps }) {
    const { themeAssets } = pageProps;

    let grids = extractRepeaterField(data, 'grids');

    const {
        top_heading,
        main_heading,
        style_options_dark_background,
        style_options_background
    } = data;
    let background = themeAssets[style_options_background];
    return (
        <OurFeatures
            className={className}
            anchor={anchor}
            main_heading={main_heading}
            top_heading={top_heading}
            grids={grids}
            background={background}
            dark={style_options_dark_background}
        />
    );
}

export default ACFOurFeatures;
