import React from 'react';
import { extractRepeaterField } from '../../util/blocks';

import { NumberModule } from '../../components/enfusion/NumberModule';

function ACFNumberModule({ meta, data, className, anchor, pageProps }) {
    const { themeAssets, defaults } = pageProps;

    const {
        top_heading,
        main_heading,
        style_options_background,
        style_options_light_dark,
        use_block_default_data
    } = data;

    let defaultData = defaults?.numberModule?.acf_number_module;

    let background = themeAssets[style_options_background];

    let grids = extractRepeaterField(data, 'number_grid');

    
    return (
        <NumberModule
            className={className}
            anchor={anchor}
            top_heading={
                use_block_default_data !== '1'
                    ? top_heading
                    : defaultData?.topHeading
            }
            main_heading={
                use_block_default_data !== '1'
                    ? main_heading
                    : defaultData?.mainHeading
            }
            background={
                use_block_default_data !== '1'
                    ? background?.class
                    : defaultData?.styleOptions?.background[0]?.acf_post_type_theme_assets?.class
            }
            light_dark={
                use_block_default_data !== '1'
                    ? style_options_light_dark
                    : defaultData?.styleOptions?.lightDark
            }
            grids={
                use_block_default_data !== '1' ? grids : defaultData?.numberGrid
            }
        />
    );
}

export default ACFNumberModule;
