import React from 'react';
import { extractRepeaterField } from '../../util/blocks';

import { CTAContentList } from '../../components/enfusion/CTAContentList';

function ACFCTAContentList({ meta, data, className, anchor, pageProps }) {
    const { themeAssets } = pageProps;

    let lists = extractRepeaterField(data, 'list');
    let cta = extractRepeaterField(data, 'cta');

    lists = lists.map((repeaterItem) => ({
        ...repeaterItem,
        image_icon: themeAssets[repeaterItem.image_icon] || {}
    }));
    return (
        <CTAContentList
            className={className}
            anchor={anchor}
            mainHeading={data.main_heading}
            secondHeading={data.second_heading}
            lists={lists}
            cta={cta}
        />
    );
}

export default ACFCTAContentList;
