import React, { useState } from 'react';
import NextNavLink from '../../util/NextNavLink';
import TestimonialCarousel from '../TestimonialCarousel';
import { sendUTMsToIframe } from '../../../util/send-utms-to-iframe';

export const TwoColContactForm = ({
    className = '',
    anchor = '',
    top_heading,
    main_heading,
    description,
    testimonials_carousel,
    embed,
    testimonials = []
}) => {
    let testimonials_carousel_data = [];
    let results = [];
    if (testimonials_carousel?.length > 0) {
        testimonials_carousel_data = testimonials.filter((item, index) => {
            if (testimonials_carousel.includes(String(item.id))) {
                return true;
            }
            return false;
        });

        testimonials_carousel.forEach((item, index) => {
            testimonials_carousel_data.forEach((testimonial, i) => {
                if (item === String(testimonial.id)) {
                    results[index] = testimonial;
                }
            });
        });
    }
    sendUTMsToIframe();
    return (
        <>
            <div
                className={`wow fadeInUp md:py-24 py-16  ${className}`}
                id={anchor}
                data-name="two-col-contact-form"
            >
                <div className="container">
                    <div className="grid gap-8 lg:grid-cols-2">
                        <div className="flex flex-col gap-y-6 ">
                            <div className=" max-w-xs ">
                                {top_heading && (
                                    <p
                                        className="mb-4 font-medium md:mb-6 label text-purple wow fadeInUp"
                                        dangerouslySetInnerHTML={{
                                            __html: top_heading
                                        }}
                                    />
                                )}
                                {main_heading && (
                                    <h3
                                        className="mb-4 md:mb-6 md:h3 h2 wow fadeInUp "
                                        dangerouslySetInnerHTML={{
                                            __html: main_heading
                                        }}
                                    />
                                )}
                                {description && (
                                    <div
                                        className="lead lead--large wow fadeInUp opacity-70 two-col--description"
                                        dangerouslySetInnerHTML={{
                                            __html: description
                                        }}
                                    />
                                )}
                            </div>
                            {results ? (
                                <TestimonialCarousel
                                    testimonials={results}
                                    size="sm"
                                />
                            ) : (
                                <></>
                            )}
                        </div>
                        <div>
                            <div
                                dangerouslySetInnerHTML={{ __html: embed }}
                                className="long-form"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default TwoColContactForm;
