import React, { useState, useEffect } from 'react';

export const OurFeatures = ({
    className = '',
    anchor = '',
    top_heading,
    main_heading,
    grids,
    dark,
    background
}) => { 
    return (
        <div
            className={`relative wow fadeInUp overflow-hidden md:py-32 py-16 bg-right ${className}`}
            id={anchor}
        >
            <div className="container relative z-[1]">
                {top_heading && (
                    <p
                        className={ `mb-4 font-medium label md:mb-6 ${dark === '1' ? 'text-white' : 'text-purple '}`}
                        dangerouslySetInnerHTML={{
                            __html: top_heading
                        }}
                    />
                )}
                {main_heading && (
                    <h3
                        className={ `md:mb-16 mb-12 ${dark === '1' ? 'text-white' : 'text-purple-midnight '}`}
                        dangerouslySetInnerHTML={{
                            __html: main_heading
                        }}
                    />
                )}

                <div className={ `md:grid hidden grid-cols-12 gap-6 ${dark === '1' ? 'text-white' : 'text-purple '}`}>
                    <div className="col-span-4">
                        <p className="mb-4 font-medium label md:mb-8">features</p>
                    </div>
                     <div className="col-span-4">
                        <p className="mb-4 font-medium label md:mb-8">Enfusion</p>
                    </div>
                     <div className="col-span-4">
                        <p className="mb-4 font-medium label md:mb-8">Competitors</p>
                    </div>
                </div>
                <div>
                    {grids && grids.map(((item, index) =>
                        <div key={index} className={`grid md:grid-cols-12 md:gap-6 gap-2 py-6 border-b first:border-t border-purple-midnight border-opacity-10 ${dark === '1' ? 'text-white' : 'text-purple-midnight '}`}>
                           
                            <div className="md:col-span-4">
                                <p className={ `mb-2 font-medium label md:hidden ${dark !== '1' ? 'text-purple' : 'text-purple-periwinkle '}`}>features</p>
                                <h5 dangerouslySetInnerHTML={{__html:item?.heading}} />
                            </div>
                            <div className="md:hidden">
                                <div className="w-8 my-5 border-t border-purple-midnight border-opacity-10 md:hidden"></div>
                                <p className={`mb-0 font-medium label md:hidden ${dark !== '1' ? 'text-purple' : 'text-purple-periwinkle '}`}>Enfusion</p>
                            </div>
                            <div className={`flex items-center md:col-span-4 space-x-3 ${item?.column_1_color === "Purple" ? 'text-purple' : ''} ${item?.column_1_color === "Gray" ? 'text-purple-midnight opacity-50' : ''} `}>
                                <i class={`text-24 ${item?.column_1_color === "Default" || item?.column_1_color === "Purple" ? 'en-circle-check text-purple' : 'en-circle-x text-purple-midnight'}`}></i>
                                <p className="lead" dangerouslySetInnerHTML={{__html:item?.column_1}} />
                            </div>
                            <div className="md:hidden">
                                <div className="w-8 my-5 border-t border-purple-midnight border-opacity-10 md:hidden"></div>
                                <p className={`mb-0 font-medium label md:hidden ${dark !== '1' ? 'text-purple' : 'text-purple-periwinkle '}`}>Competitors</p>
                            </div>
                            <div className={`flex items-center md:col-span-4 space-x-3 ${item?.column_2_color === "Purple" ? 'text-purple' : ''} ${item?.column_2_color === "Gray" ? 'text-purple-midnight opacity-50' : ''}`}>
                                <i class={`text-24 text-purple  ${item?.column_2_color === "Default" || item?.column_2_color === "Purple" ? 'en-circle-check text-purple' : 'en-circle-x text-purple-midnight'}`}></i>
                                <p className="lead" dangerouslySetInnerHTML={{__html:item?.column_2}} />
                            </div>
                        </div>
                        
                    ))}
                </div>
            </div>
            <div className={`absolute top-0 left-0 w-full h-full ${dark === '1' ? '' : 'opacity-10'}  ${background?.class}`} />
        </div>
    );
};
export default OurFeatures;
